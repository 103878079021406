import React from "react";
import { animated } from "react-spring";
import styled from "styled-components";

export const CalendarBox = styled.div`
  border-radius: 3px;
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-bottom: 2px;
  margin-left: ${props => (props.index === 0 ? 0 : 10)};
  background-size: contain;
  background-color: ${props => props.color || "gray"};
`;

export const LegendBox = styled.div`
  border-radius: 3px;
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-bottom: 2px;
  cursor: pointer;
  margin-left: 10px;
  background-size: contain;
  background-color: ${props => props.color};
  background-image: url(${props => props.image});
`;

export const LegendSelectBox = styled.div`
  border: 1.5px solid ${props => props.borderColor || "rgb(255, 115, 0)"};
  height: 35;
  width: 70;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: 600;
  color: ${props => props.color};
  background-color: ${props => props.background};
`;

export const LoadingBox = function Box(props) {
  return (
    <animated.div
      onClick={props.onClick}
      style={{
        boxSizing: "border-box",
        height: 25,
        width: 25,
        border: "1.5px solid rgba(0,0,0,0)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.style
      }}
    >
      {props.children}
    </animated.div>
  );
};
