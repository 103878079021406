import moment from "moment";

const checkIfWeekend = (date, holidays) => {
  let isHoliday = false;
  holidays.forEach(holidayDate => {
    if (!isHoliday) {
      isHoliday = moment(holidayDate).isSame(date, "day");
    }
  });
  return isHoliday || date.getDay() === 0 || date.getDay() === 6 ? true : false;
};

const getFormattedDate = date => {
  return moment(date).format("L");
};

export { checkIfWeekend, getFormattedDate };
