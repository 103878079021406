import { useObserver } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { useStore } from "../../_core/appContext";
import browserDetect from "../../utility/browserDetect";
import {
  getImageX,
  getImageY,
  getUSImageX,
  getUSImageY,
  getX,
  getY,
  getZAImageX,
  getZAImageY
} from "../../utility/mapHelper";
const China2x = require("../../images/China2x.png");
const EU2x = require("../../images/EU2x.png");
const India2x = require("../../images/india2x.png");
const Japan2x = require("../../images/Japan2x.png");
const USFlag = require("../../images/US-flag.svg.png");
const JapanFlag = require("../../images/japan_.png");
const IndiaFlag = require("../../images/india_.png");
const EUFlag = require("../../images/EU_.png");
const ChinaFlag = require("../../images/china_.png");
const SouthAfricaFlag = require("../../images/South_Africa-flag.png");
const ThailandFlag = require("../../images/tailand.png");

const largeCountries = new Set([
  "algeria",
  "mauritania",
  "libya",
  "egypt",
  "chad",
  "sudan",
  "democratic republic of the congo",
  "south africa",
  "angola",
  "nigeria",
  "niger",
  "tanzania",
  "mali",
  "ethiopia",
  "central african republic",
  "ivory coast",
  "cameroon",
  "madagascar",
  "kenya",
  "zambia"
]);

function Region(props) {
  const { Map } = useStore();
  const history = useHistory();
  const isIE = useRef(browserDetect.checkIfInternetExplorer());
  const groupRef = useRef(null);
  const ref = useRef(null);
  const [hover, setHover] = useState(false);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const { x, y } = coordinates;
  useEffect(() => {
    const BoundingBox = ref.current.getBBox();
    setCoordinates({ x: BoundingBox.x, y: BoundingBox.y });
    return () => Map.hideTooltip();
  }, [Map]);

  function onMouseEnter(e) {
    const x = e.pageX + 10;
    const y = e.pageY + 25;
    if (!hover) setHover(true);
    Map.setTooltipProps(x, y, id);
  }

  function onMouseMove(e) {
    const x = e.pageX + 10;
    const y = e.pageY + 25;
    if (!hover) setHover(true);
    Map.setTooltipProps(x, y, id);
  }

  function onMouseOut() {
    if (hover) setHover(false);
    Map.hideTooltip();
  }

  const onClick = id => {
    if (!id) history.push(`/countryDetail/Algeria`);
    else history.push(`/countryDetail/${id}`);
  };

  const getCarImportCountryImagePath = () => {
    const { countryData, id } = props;
    let imagePath = null;
    let imagePathId = null;

    if (countryData.carImportCountry) {
      switch (countryData.carImportCountry) {
        case "United States":
          imagePath = USFlag;
          imagePathId = "usFlag";
          break;
        case "Japan":
          if (largeCountries.has(id)) {
            imagePath = Japan2x;
            imagePathId = "japanFlag2x";
          } else {
            imagePath = JapanFlag;
            imagePathId = "japanFlag";
          }
          break;
        case "South Korea":
          if (largeCountries.has(id)) {
            imagePath = Japan2x;
            imagePathId = "japanFlag2x";
          } else {
            imagePath = JapanFlag;
            imagePathId = "japanFlag";
          }
          break;
        case "India":
          if (largeCountries.has(id)) {
            imagePath = India2x;
            imagePathId = "indiaFlag2x";
          } else {
            imagePath = IndiaFlag;
            imagePathId = "indiaFlag";
          }
          break;
        case "South Africa":
          imagePath = SouthAfricaFlag;
          imagePathId = "southAfricaFlag";
          break;
        case "France":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        case "Germany":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        case "Belgium":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        case "Romania":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        case "Switzerland":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        default:
          break;
      }
    }
    return { imagePath, imagePathId };
  };

  const getTruckImportCountryImagePath = () => {
    const { countryData, id } = props;
    let imagePath = null;
    let imagePathId = null;
    if (countryData.truckImportCountry) {
      switch (countryData.truckImportCountry) {
        case "China":
          if (largeCountries.has(id)) {
            imagePath = China2x;
            imagePathId = "chinaFlag2x";
          } else {
            imagePath = ChinaFlag;
            imagePathId = "chinaFlag";
          }
          break;
        case "United States":
          imagePath = USFlag;
          imagePathId = "usFlag";
          break;
        case "Thailand":
          imagePath = ThailandFlag;
          imagePathId = "thailandFlag";
          break;
        case "Japan":
          if (largeCountries.has(id)) {
            imagePath = Japan2x;
            imagePathId = "japanFlag2x";
          } else {
            imagePath = JapanFlag;
            imagePathId = "japanFlag";
          }
          break;
        case "South Korea":
          if (largeCountries.has(id)) {
            imagePath = Japan2x;
            imagePathId = "japanFlag2x";
          } else {
            imagePath = JapanFlag;
            imagePathId = "japanFlag";
          }
          break;
        case "India":
          if (largeCountries.has(id)) {
            imagePath = India2x;
            imagePathId = "indiaFlag2x";
          } else {
            imagePath = IndiaFlag;
            imagePathId = "indiaFlag";
          }
          break;
        case "South Africa":
          imagePath = SouthAfricaFlag;
          imagePathId = "southAfricaFlag";
          break;
        case "France":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        case "Germany":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        case "Belgium":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        case "Turkey":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        case "Netherlands":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        case "Portugal":
          if (largeCountries.has(id)) {
            imagePath = EU2x;
            imagePathId = "euFlag2x";
          } else {
            imagePath = EUFlag;
            imagePathId = "euFlag";
          }
          break;
        default:
          break;
      }
    }

    return { imagePath, imagePathId };
  };

  const handleRegionImagePath = () => {
    const { mapOption } = Map;
    let _imagePath = null;
    let _imagePathId = null;
    if (mapOption === "carImportCountry") {
      const res = getCarImportCountryImagePath();
      const { imagePath, imagePathId } = res;
      _imagePath = imagePath;
      _imagePathId = imagePathId;
    }
    if (mapOption === "truckImportCountry") {
      const res = getTruckImportCountryImagePath();
      const { imagePath, imagePathId } = res;
      _imagePath = imagePath;
      _imagePathId = imagePathId;
    }
    if (_imagePath === null) return null;
    return _imagePathId;
  };

  function renderCircularTooltip() {
    const {
      id,
      distributorColor,
      isRegionSelected,
      toolTipBackground,
      hideTooltipBackground,
      toolTipTextColor,
      color,
      circleR = 20,
      countryData
    } = props;
    const { mapOption } = Map;
    const fontSize = isRegionSelected || hover ? 15 : 11;
    const customX = getX({ x, y }, id) + 20;
    const customY = getY({ x, y }, id) + 20;
    let displayText = "";
    try {
      switch (mapOption) {
        case "population": {
          if (countryData && countryData.population) {
            displayText = (+countryData.population).toFixed(1);
          }
          break;
        }
        case "gdp": {
          if (countryData && countryData.gdp) {
            displayText = (+countryData.gdp).toFixed(1);
          }
          break;
        }
        case "filterImportValue": {
          if (countryData && countryData.filterImportValue) {
            displayText = countryData.filterImportValue.toFixed(1);
          }
          break;
        }
        case "vehicleImportValue": {
          if (countryData && countryData.vehicleImportValue) {
            displayText = countryData.vehicleImportValue.toFixed(1);
          }
          break;
        }
        case "mhFootprint": {
          if (countryData && countryData.mhFootprint) {
            displayText = "";
          }
          break;
        }
        case "mhMarketShare": {
          if (countryData && countryData.mhMarketShare) {
            displayText = (countryData.mhMarketShare * 100).toFixed(1) + "%";
          }
          break;
        }
        case "motorizationRate": {
          if (countryData && countryData.motorizationRate) {
            displayText = countryData.motorizationRate;
          }
          break;
        }
        default:
          displayText = "";
          break;
      }
    } catch (err) {
      console.log(err);
    }
    if (!displayText || displayText.length === 0) return null;
    return (
      <g id={id + "-tooltip"} style={{ cursor: "pointer" }}>
        {!hideTooltipBackground && (
          <circle
            style={{
              fill:
                isRegionSelected || hover
                  ? distributorColor
                  : toolTipBackground
                  ? toolTipBackground
                  : "rgba(0,0,0,0.1)"
            }}
            strokeWidth="0px"
            stroke={distributorColor}
            cx={customX}
            cy={customY}
            r={circleR}
          />
        )}
        <text
          style={{
            fontSize,
            textShadow: isRegionSelected || hover ? "2px 2px 3px rgba(0,0,0,0.5)" : ""
          }}
          lightingColor="true"
          id="text"
          x={customX}
          y={customY}
          textAnchor="middle"
          fill={
            isRegionSelected || hover
              ? "white"
              : toolTipTextColor
              ? toolTipTextColor
              : color
              ? "white"
              : "rgba(0,0,0,0.5)"
          }
          stroke={
            isRegionSelected || hover
              ? "white"
              : toolTipTextColor
              ? toolTipTextColor
              : "rgba(0, 0, 0, 0.5)"
          }
          strokeWidth={isRegionSelected || hover ? "1px" : "0px"}
          dy=".3em"
        >
          {displayText.toString()}
        </text>
      </g>
    );
  }

  const formatFlagID = flag => {
    const { countryData } = props;
    const { country } = countryData;
    return country && (flag + country).replace(new RegExp(" ", "g"), "+");
  };

  const { id, path, color, isRegionSelected, strokeColor, countryData } = props;

  return useObserver(() => {
    return (
      <g
        id="region"
        onClick={() => onClick(countryData.country)}
        stroke={"rgba(0, 0, 0, 0.2)"}
        fill={
          Map.mapOption === "carImportCountry" || Map.mapOption === "truckImportCountry"
            ? `url(#${handleRegionImagePath() + countryData.country &&
                (handleRegionImagePath() + countryData.country).replace(
                  new RegExp(" ", "g"),
                  "+"
                )})`
            : color
        }
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseOut}
        strokeWidth={
          Map.mapOption === "mhFootprint" ||
          Map.mapOption === "carImportCountry" ||
          Map.mapOption === "truckImportCountry" ||
          Map.mapOption === "mhMarketShare"
            ? 2
            : isIE
            ? hover
              ? 2
              : 1
            : hover
            ? 1
            : 1
        }
        ref={groupRef}
      >
        <defs>
          <pattern id={formatFlagID("usFlag")} width="100%" height="100%">
            <image
              href={USFlag}
              x={getUSImageX(id)}
              y={getUSImageY(id)}
              preserveAspectRatio="none"
              width="300"
              height="200"
            />
          </pattern>
          <pattern id={formatFlagID("thailandFlag")} width="100%" height="100%">
            <image
              href={ThailandFlag}
              x={getImageX(id)}
              y={getImageY(id)}
              preserveAspectRatio="none"
              width="300"
              height="200"
            />
          </pattern>
          <pattern id={formatFlagID("euFlag")} width="100%" height="100%">
            <image
              href={EUFlag}
              x={getImageX(id)}
              y={getImageY(id)}
              preserveAspectRatio="none"
              width="300"
              height="200"
            />
          </pattern>
          <pattern id={formatFlagID("euFlag2x")} width="100%" height="100%">
            <image
              href={EU2x}
              x={getImageX(id)}
              y={getImageY(id)}
              preserveAspectRatio="none"
              width="300"
              height="200"
            />
          </pattern>
          <pattern id={formatFlagID("chinaFlag")} width="100%" height="100%">
            <image
              href={ChinaFlag}
              x={getImageX(id)}
              y={getImageY(id)}
              preserveAspectRatio="none"
              width="300"
              height="200"
            />
          </pattern>
          <pattern id={formatFlagID("chinaFlag2x")} width="100%" height="100%">
            <image
              href={China2x}
              x={getImageX(id)}
              y={getImageY(id)}
              preserveAspectRatio="none"
              width="300"
              height="200"
            />
          </pattern>
          <pattern id={formatFlagID("indiaFlag")} width="100%" height="100%">
            <image
              href={IndiaFlag}
              x={getImageX(id)}
              y={getImageY(id)}
              preserveAspectRatio="none"
              width="300"
              height="200"
            />
          </pattern>
          <pattern id={formatFlagID("indiaFlag2x")} width="100%" height="100%">
            <image
              href={India2x}
              x={getImageX(id)}
              y={getImageY(id)}
              preserveAspectRatio="none"
              width="300"
              height="200"
            />
          </pattern>
          <pattern id={formatFlagID("japanFlag")} width="100%" height="100%">
            <image
              href={JapanFlag}
              x={getImageX(id)}
              y={getImageY(id)}
              preserveAspectRatio="none"
              width="300"
              height="200"
            />
          </pattern>
          <pattern id={formatFlagID("japanFlag2x")} width="100%" height="100%">
            <image
              href={Japan2x}
              x={getImageX(id)}
              y={getImageY(id)}
              preserveAspectRatio="none"
              width="300"
              height="200"
            />
          </pattern>
          <pattern id={formatFlagID("southAfricaFlag")} width="100%" height="100%">
            <image
              href={SouthAfricaFlag}
              x={getZAImageX(id)}
              y={getZAImageY(id)}
              preserveAspectRatio="none"
              width={largeCountries.has(id) ? "300" : "150"}
              height={largeCountries.has(id) ? "200" : "100"}
            />
          </pattern>
        </defs>
        {path.map((d, index) => (
          <path key={index} fill="rgba(0,0,0,0.3)" stroke="none" d={d} />
        ))}
        {path.map((d, index) => {
          return (
            <path
              key={index}
              stroke={strokeColor}
              strokeWidth={1}
              style={{
                transform: (isRegionSelected || hover) && "translateY(-1%)"
              }}
              ref={index === 0 ? ref : null}
              d={d}
            />
          );
        })}
        {Map.mapOption !== "mhFootprint" &&
          Map.mapOption !== "carImportCountry" &&
          Map.mapOption !== "truckImportCountry" &&
          renderCircularTooltip()}
        {Map.mapOption === "carImportCountry" || Map.mapOption === "truckImportCountry"
          ? handleRegionImagePath()
          : null}
      </g>
    );
  });
}

export default Region;
