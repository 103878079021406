import { useObserver } from "mobx-react";
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import { useStore } from "../../_core/appContext";
import Region from "./Region";

const pickColor = (options, value, legendOption) => {
  let rcolor;
  options.forEach((obj, index) => {
    if (obj.min <= value && (!obj.max || obj.max >= value)) {
      if (legendOption !== null && legendOption !== index) {
        rcolor = "#cacaca";
      } else {
        const { color } = obj;
        rcolor = color;
      }
    }
  });

  return rcolor;
};

const setRegionColor = (mapOption, countryData, legendOption, legendOptionList) => {
  let color = "";

  switch (mapOption) {
    case "population": {
      if (countryData && countryData.population) {
        color = pickColor(
          legendOptionList,
          parseInt(countryData && countryData.population),
          legendOption
        );
      } else {
        color = "#cacaca";
      }
      break;
    }
    case "gdp": {
      if (countryData && countryData.gdp) {
        color = pickColor(legendOptionList, parseInt(countryData.gdp), legendOption);
      } else {
        color = "#cacaca";
      }
      break;
    }
    case "filterImportValue": {
      if (countryData && countryData.filterImportValue) {
        color = pickColor(legendOptionList, Math.ceil(countryData.filterImportValue), legendOption);
      } else {
        color = "#cacaca";
      }
      break;
    }
    case "vehicleImportValue": {
      if (countryData && countryData.vehicleImportValue) {
        color = pickColor(legendOptionList, countryData.vehicleImportValue, legendOption);
      } else {
        color = "#cacaca";
      }
      break;
    }
    case "mhFootprint": {
      if (countryData && countryData.mhFootprint) {
        color = "rgb(0, 105, 68)";
      } else color = "#cacaca";
      break;
    }
    case "mhMarketShare": {
      if (countryData && countryData.mhMarketShare) {
        color = pickColor(legendOptionList, countryData.mhMarketShare * 100, legendOption);
      } else {
        color = "#cacaca";
      }
      break;
    }
    case "motorizationRate": {
      if (countryData && countryData.motorizationRate) {
        color = pickColor(legendOptionList, countryData.motorizationRate, legendOption);
      } else {
        color = "#cacaca";
      }
      break;
    }
    default:
      color = "#cacaca";
  }
  return color;
};

function RegionDetail(props) {
  const { colors } = useContext(ThemeContext);
  const { Map } = useStore();
  const { d, d2, d3, id, selectedCity, countryData } = props;
  const text = id;
  const distributorColor = colors.MH_ORANGE;
  const value = "";
  let toolTipBackground;
  let toolTipTextColor;
  const color = setRegionColor(Map.mapOption, countryData, Map.legendOption, Map.legendOptionList);

  return useObserver(() => {
    return (
      <Region
        key={id}
        id={id}
        text={text}
        distributorColor={distributorColor}
        path={[d, d2, d3]}
        isRegionSelected={selectedCity === id}
        brandValue={value}
        color={color}
        toolTipBackground={toolTipBackground}
        toolTipTextColor={toolTipTextColor}
        countryData={countryData}
      />
    );
  });
}

export default RegionDetail;
