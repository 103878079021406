import React, { useEffect, useRef } from "react";
import { animated, useSprings } from "react-spring";

const bgColors = ["rgb(30, 74, 44)", "rgb(42, 100, 59)", "rgb(39, 94, 56)", "rgb(53, 121, 71)"];

function Box(props) {
  return (
    <animated.div
      onClick={props.onClick}
      style={{
        boxSizing: "border-box",
        height: 25,
        width: 25,
        border: "1.5px solid rgba(0,0,0,0)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.style
      }}
    ></animated.div>
  );
}

export default function Loading({ setLoading }) {
  const isMountedRef = useRef(true);
  const timeoutRef = useRef(null);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [setLoading]);

  const [loadingSprings] = useSprings(4, index => ({
    from: { opacity: 1 },
    to: async next => {
      while (isMountedRef.current) {
        await next({ opacity: 0.2 });
      }
    },
    reset: true,
    delay: index === 3 ? 400 : index === 2 ? 600 : index * 200,
    config: { duration: 800 }
  }));

  return (
    <div
      style={{
        marginTop: 50,
        display: "grid",
        gridTemplateColumns: "auto auto"
      }}
    >
      {[0, 1, 2, 3].map(i => {
        return (
          <Box
            key={i}
            style={{
              backgroundColor: bgColors[i],
              opacity: 0.2,
              ...loadingSprings[i]
            }}
          />
        );
      })}
    </div>
  );
}
