const colors = {
  MH_GREEN: "rgb(0, 130, 60)",
  MH_LIGHT_GREEN: "rgb(70,175,40)",
  MH_ACCENT_GREEN: "rgb(110,208,77)",
  MH_SEA_GREEN: "rgb(46,139,87)",
  MH_PERSIAN_GREEN: "rgb(0,165,114)",
  MH_FERN_GREEN: "rgb(79,121,66)",
  MH_RED: "rgb(200,15,45)",
  MH_ORANGE: "rgb(255,115,0)",
  MH_ACCENT_BLUE: "rgb(0,158,224)",
  MH_STEEL_BLUE: "#226b80",
  MH_BLACK: "rgb(25, 25, 25)",
  MH_GRAY: "rgba(74, 74, 74, 0.5)",
  MH_LIGHT_GRAY: "rgba(74, 74, 74, 0.2)",
  MH_ENDEAVOUR: "rgb(43,92,138)",
  MH_LOCHMARA: "rgb(53,104,154)",
  MH_DANUBE: "rgb(87,138,183)",
  MH_SEAGULL: "rgb(123,177,214)",
  MH_CORNFLOWER: "rgb(137, 190,221)",
  MH_CORNFLOWER_LIGHT: "rgb(144,196,224)"
};

export default colors;
