const populationOptions = [
  { min: 0.00000001, max: 10, text: "0-10M", color: "rgb(222, 236, 229)" },
  { min: 11, max: 20, text: "11M-20M", color: "rgb(208, 226, 216)" },
  { min: 21, max: 30, text: "21M-30M", color: "rgb(190, 214, 201)" },
  { min: 31, max: 40, text: "31M-40M", color: "rgb(154, 187, 169)" },
  { min: 41, max: 50, text: "41M-50M", color: "rgb(120, 167, 141)" },
  { min: 51, max: 60, text: "51M-60M", color: "rgb(102, 154, 125)" },
  { min: 61, max: 70, text: "61M-70M", color: "rgb(83, 142, 104)" },
  { min: 71, max: 80, text: "71M-80M", color: "rgb(64, 132, 94)" },
  { min: 81, max: 90, text: "81M-90M", color: "rgb(0, 105, 68)" },
  { min: 91, max: 99, text: "91M-99M", color: "rgb(0, 75, 40)" },
  { min: 100, text: "+100M", color: "rgb(2, 66, 36)" }
];

const gdpOptions = [
  { min: 0.00000001, max: 10, text: "0-$10B", color: "rgb(222, 236, 229)" },
  { min: 11, max: 20, text: "$11B-$20B", color: "rgb(208, 226, 216)" },
  { min: 21, max: 30, text: "$21B-$30B", color: "rgb(190, 214, 201)" },
  { min: 31, max: 40, text: "$31B-$40B", color: "rgb(154, 187, 169)" },
  { min: 41, max: 50, text: "$41B-$50B", color: "rgb(120, 167, 141)" },
  { min: 51, max: 60, text: "$51B-$60B", color: "rgb(102, 154, 125)" },
  { min: 61, max: 70, text: "$61B-$70B", color: "rgb(83, 142, 104)" },
  { min: 71, max: 80, text: "$71B-$80B", color: "rgb(64, 132, 94)" },
  { min: 81, max: 90, text: "$81B-$90B", color: "rgb(0, 105, 68)" },
  { min: 91, max: 99, text: "$91B-$99B", color: "rgb(0, 75, 40)" },
  { min: 100, text: "+$100B", color: "rgb(2, 66, 36)" }
];

const filterImportOptions = [
  { min: 0.00000001, max: 5, text: "0-$5M", color: "rgb(222, 236, 229)" },
  { min: 6, max: 10, text: "$6M-$10M", color: "rgb(208, 226, 216)" },
  { min: 11, max: 15, text: "$11M-$15M", color: "rgb(190, 214, 201)" },
  { min: 16, max: 20, text: "$16M-$20M", color: "rgb(154, 187, 169)" },
  { min: 21, max: 25, text: "$21M-$25M", color: "rgb(120, 167, 141)" },
  { min: 26, max: 30, text: "$26M-$30M", color: "rgb(102, 154, 125)" },
  { min: 31, max: 35, text: "$31M-$35M", color: "rgb(83, 142, 104)" },
  { min: 36, max: 40, text: "$36M-$40M", color: "rgb(64, 132, 94)" },
  { min: 41, max: 45, text: "$41M-$45M", color: "rgb(0, 105, 68)" },
  { min: 46, max: 49, text: "$46M-$49M", color: "rgb(0, 75, 40)" },
  { min: 50, text: "+$50M", color: "rgb(2, 66, 36)" }
];

const vehicleImportOptions = [
  { min: 0.00000001, max: 0.5, text: "0-$0.5B", color: "rgb(222, 236, 229)" },
  { min: 0.5, max: 1, text: "$0.6B-$1B", color: "rgb(208, 226, 216)" },
  { min: 1, max: 1.5, text: "$1.1B-$1.5B", color: "rgb(190, 214, 201)" },
  { min: 1.5, max: 2, text: "$1.6B-$2B", color: "rgb(154, 187, 169)" },
  { min: 2, max: 2.5, text: "$2.1B-$2.5B", color: "rgb(120, 167, 141)" },
  { min: 2.5, max: 3, text: "$2.6B-$3B", color: "rgb(102, 154, 125)" },
  { min: 3, max: 3.5, text: "$3.1B-$3.5B", color: "rgb(83, 142, 104)" },
  { min: 3.5, max: 4, text: "$3.6B-$4B", color: "rgb(64, 132, 94)" },
  { min: 4, max: 4.5, text: "$4.1B-$4.5B", color: "rgb(0, 105, 68)" },
  { min: 4.5, max: 4.9, text: "$4.6B-$4.9B", color: "rgb(0, 75, 40)" },
  { min: 5, text: "+$5B", color: "rgb(2, 66, 36)" }
];

const mhMarketShareOptions = [
  { min: 0.00000001, max: 5, text: "%0-%5", color: "rgb(222, 236, 229)" },
  { min: 5, max: 10, text: "%6-%10", color: "rgb(208, 226, 216)" },
  { min: 10, max: 15, text: "%11-%15", color: "rgb(190, 214, 201)" },
  { min: 15, max: 20, text: "%16-%20", color: "rgb(154, 187, 169)" },
  { min: 20, max: 25, text: "%21-%25", color: "rgb(120, 167, 141)" },
  { min: 25, max: 30, text: "%26-%30", color: "rgb(102, 154, 125)" },
  { min: 30, max: 35, text: "%31-%35", color: "rgb(83, 142, 104)" },
  { min: 35, max: 40, text: "%36-%40", color: "rgb(64, 132, 94)" },
  { min: 40, max: 45, text: "%41-%45", color: "rgb(0, 105, 68)" },
  { min: 45, max: 49, text: "%46-%49", color: "rgb(0, 75, 40)" },
  { min: 50, text: "+%50", color: "rgb(2, 66, 36)" }
];

const mhFootprintOptions = [
  { min: 0.00000001, max: 5, text: "Yes", color: "rgb(0, 105, 68)" },
  { min: 0.00000001, max: 5, text: "No", color: "#cacaca" }
];

const motorizationRateOptions = [
  { min: 0.00000001, max: 20, text: "0-19", color: "rgb(222, 236, 229)" },
  { min: 20, max: 40, text: "20-39", color: "rgb(208, 226, 216)" },
  { min: 40, max: 60, text: "40-59", color: "rgb(190, 214, 201)" },
  { min: 60, max: 80, text: "60-79", color: "rgb(154, 187, 169)" },
  { min: 80, max: 100, text: "80-99", color: "rgb(120, 167, 141)" },
  { min: 100, max: 120, text: "100-119", color: "rgb(102, 154, 125)" },
  { min: 120, max: 140, text: "120-139", color: "rgb(83, 142, 104)" },
  { min: 140, max: 160, text: "140-159", color: "rgb(64, 132, 94)" },
  { min: 160, max: 180, text: "160-179", color: "rgb(0, 105, 68)" },
  { min: 180, max: 200, text: "180-199", color: "rgb(0, 75, 40)" },
  { min: 200, text: "+200", color: "rgb(2, 66, 36)" }
];

export {
  populationOptions,
  gdpOptions,
  filterImportOptions,
  vehicleImportOptions,
  mhMarketShareOptions,
  mhFootprintOptions,
  motorizationRateOptions
};
