import React, { useCallback, useEffect, useState } from "react";

import colors from "../../styles/colors";
import worldCountryList from "../../utility/constants/worldCountryList";
const ReactHighcharts = require("react-highcharts");

const getFlagName = name => {
  const found = worldCountryList.find(country => country.text === name);
  if (found) return found.flag;
};

function highChartsConfig() {
  this.pieChart = () => {
    return {
      chart: {
        type: "bar",
        height: "270px"
      },
      title: { text: "" },
      xAxis: {
        categories: [],
        title: { text: "" }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          type: "pie",
          allowPointSelect: true,
          showInLegend: true
        }
      ],
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>:<br>{point.percentage:.1f} %<br>"
          }
        }
      }
    };
  };
  this.barChart = () => {
    return {
      chart: {
        type: "bar",
        height: "250px"
      },
      title: {
        text: ""
      },
      xAxis: {
        labels: {
          formatter: function() {
            if (getFlagName(this.value)) {
              return `<i class="${getFlagName(this.value)} flag"/>`;
            } else {
              return this.value;
            }
          },
          useHTML: true
        },
        title: {
          text: ""
        },
        x: -10
      },
      yAxis: {
        min: 0,
        title: {
          text: ""
        }
      },
      tooltip: {
        valueSuffix: "%"
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{ data: [], bar: {} }]
    };
  };
}

const Charts = props => {
  const { type, graphData, categoryKey, dataKey, height, suffix } = props;

  const [configPie, setConfigPie] = useState(null);
  const [configBar, setConfigBar] = useState(null);

  const initPieChartConfig = useCallback(() => {
    if (graphData) {
      const config = new highChartsConfig().pieChart();
      config.xAxis.categories = graphData.map(({ item }) => item).push("Other");

      let _total = 0;
      const generatedData = graphData.map(el => {
        _total += +el.percentage;
        return {
          name: el.item,
          y: +el.percentage
        };
      });
      generatedData.push({ name: "Other", y: 100 - _total });
      config.series[0].name = [""];
      config.series[0].keys = ["name", "y", "selected", "sliced"];
      config.series[0].colors = [
        colors.MH_GREEN,
        colors.MH_LIGHT_GREEN,
        colors.MH_PERSIAN_GREEN,
        colors.MH_SEA_GREEN,
        colors.MH_FERN_GREEN
      ];
      config.series[0].data = generatedData;
      setConfigPie(config);
    }
  }, [graphData]);

  const initBarChartConfig = useCallback(() => {
    graphData.sort((a, b) => b[dataKey] - a[dataKey]);
    const colorNames = [
      "MH_ORANGE",
      "MH_ENDEAVOUR",
      "MH_LOCHMARA",
      "MH_DANUBE",
      "MH_SEAGULL",
      "MH_CORNFLOWER",
      "MH_CORNFLOWER_LIGHT"
    ];

    const config = new highChartsConfig().barChart();
    config.chart.height = height;
    let total = 0;

    config.xAxis.categories = graphData.map(item => {
      return item[categoryKey];
    });

    config.series[0].name = [""];
    config.series[0].keys = ["name", "y", "selected", "sliced"];
    config.series[0].data = graphData.map((el, i) => {
      total += parseInt(el[dataKey]);

      return { y: parseInt(el[dataKey]), color: colors[colorNames[i + 1]] };
    });

    if (dataKey === "percentage") {
      config.xAxis.categories.push("Other");
      config.series[0].data.push({ y: 100 - total, color: colors[colorNames[0]] });
      config.yAxis.title.text = "Percentage";
      config.plotOptions = {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y}%"
          }
        }
      };
    } else {
      config.yAxis.title.text = suffix;
      config.tooltip.valueSuffix = ` ${suffix}`;
    }
    setConfigBar(config);
  }, [categoryKey, dataKey, graphData, height, suffix]);

  useEffect(() => {
    if (type === "pie") initPieChartConfig();
    else initBarChartConfig();
  }, [initBarChartConfig, initPieChartConfig, type]);

  if (!configBar && !configPie) {
    return null;
  }
  return <ReactHighcharts neverReflow={true} config={type === "pie" ? configPie : configBar} />;
};

// class Charts extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   componentDidMount() {
//     const { type } = this.props;
//     if (type === "pie") this.initPieChartConfig();
//     else this.setInit();
//   }

//   initPieChartConfig = () => {
//     const { graphData } = this.props;
//     if (graphData) {
//       const config = new highChartsConfig().pieChart();
//       config.xAxis.categories = graphData.map(({ item }) => item).push("Other");

//       let _total = 0;
//       const generatedData = graphData.map(el => {
//         _total += +el.percentage;
//         return {
//           name: el.item,
//           y: +el.percentage
//         };
//       });
//       generatedData.push({ name: "Other", y: 100 - _total });
//       config.series[0].name = [""];
//       config.series[0].keys = ["name", "y", "selected", "sliced"];
//       config.series[0].colors = [
//         colors.MH_GREEN,
//         colors.MH_LIGHT_GREEN,
//         colors.MH_PERSIAN_GREEN,
//         colors.MH_SEA_GREEN,
//         colors.MH_FERN_GREEN
//       ];
//       config.series[0].data = generatedData;
//       this.setState({ configPie: config });
//     }
//   };

//   setInit = () => {
//     const { graphData, categoryKey, dataKey, height, suffix } = this.props;
//     graphData.sort((a, b) => b[dataKey] - a[dataKey]);
//     const colorNames = [
//       "MH_ORANGE",
//       "MH_ENDEAVOUR",
//       "MH_LOCHMARA",
//       "MH_DANUBE",
//       "MH_SEAGULL",
//       "MH_CORNFLOWER",
//       "MH_CORNFLOWER_LIGHT"
//     ];

//     const config = new highChartsConfig().barChart();
//     config.chart.height = height;
//     let total = 0;

//     config.xAxis.categories = graphData.map(item => {
//       return item[categoryKey];
//     });

//     config.series[0].name = [""];
//     config.series[0].keys = ["name", "y", "selected", "sliced"];
//     config.series[0].data = graphData.map((el, i) => {
//       total += parseInt(el[dataKey]);

//       return { y: parseInt(el[dataKey]), color: colors[colorNames[i + 1]] };
//     });

//     if (dataKey === "percentage") {
//       config.xAxis.categories.push("Other");
//       config.series[0].data.push({ y: 100 - total, color: colors[colorNames[0]] });
//       config.yAxis.title.text = "Percentage";
//       config.plotOptions = {
//         series: {
//           borderWidth: 0,
//           dataLabels: {
//             enabled: true,
//             format: "{point.y}%"
//           }
//         }
//       };
//     } else {
//       config.yAxis.title.text = suffix;
//       config.tooltip.valueSuffix = ` ${suffix}`;
//     }

//     this.setState({ configBar: config });
//   };

//   render() {
//     const { type } = this.props;
//     const { configBar, configPie } = this.state;
//     if (!configBar && !configPie) {
//       return null;
//     }
//     return (
//       <ReactHighcharts
//         neverReflow={true}
//         config={type === "pie" ? configPie : configBar}
//         ref={ReactHighcharts => {
//           this.pieChartRef = ReactHighcharts;
//         }}
//       />
//     );
//   }
// }

export default Charts;
