import { useObserver } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Modal, Pagination, Radio, Table } from "semantic-ui-react";
import { ThemeContext } from "styled-components";

import { useStore } from "../_core/appContext";
import { ButtonBasicGreen, ButtonBasicRed, ButtonGreen } from "./DefaultComponents/Button";
import WarningModal from "./DefaultComponents/WarningModal";

const UsersPage = () => {
  const { User, Auth } = useStore();
  const { colors } = useContext(ThemeContext);
  const [state, setState] = useState({
    showPopup: false,
    formName: "",
    formUsername: "",
    formPassword: "",
    formStatus: "user",
    modal: false,
    deleteUsername: undefined,
    warningModal: false
  });

  useEffect(() => {
    User.getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddHandler = () => {
    const { formName, formUsername, formPassword, formStatus } = state;
    User.addUser({
      name: formName,
      username: formUsername,
      password: formPassword,
      admin: formStatus
    });
    setState({ ...state, modal: false });
  };

  const onDeleteHandler = () => {
    User.deleteUser({ username: state.deleteUsername });
    setState({ ...state, warningModal: false });
  };

  const onUpdateHandler = () => {
    const { formId, formName, formUsername, formPassword, formStatus } = state;
    User.updateUser({
      userId: formId,
      name: formName,
      username: formUsername,
      password: formPassword === "" ? undefined : formPassword,
      admin: formStatus
    });
    setState({ ...state, modal: false });
  };

  const handleChange = (type, event) => {
    setState({ ...state, [type]: event.target.value });
  };

  const handleRadioChange = (e, { value }) => setState({ ...state, formStatus: value });

  const onHandlePagination = (event, data) => {
    User.handlePagination(data);
  };

  const popupHandler = user => {
    setState({
      ...state,
      formPassword: "",
      formName: user.name,
      formUsername: user.username,
      formStatus: user.admin,
      modal: true,
      formId: user._id,
      isNew: false
    });
  };

  const onNewOpen = () => {
    setState({
      ...state,
      modal: true,
      formPassword: "",
      formName: "",
      formUsername: "",
      formStatus: false,
      formId: "",
      isNew: true
    });
  };

  const onNewClose = () => {
    setState({ ...state, modal: false });
  };

  const displayWarningModal = username => {
    setState({ ...state, warningModal: true, deleteUsername: username });
  };

  function renderModal() {
    const { isNew, formName, formUsername, formPassword } = state;
    return (
      <Modal size={"tiny"} open={state.modal} onClose={onNewClose}>
        <Modal.Header>Add User</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form>
              <Form.Field>
                <label>Name</label>
                <input
                  placeholder="Name"
                  value={formName}
                  name="formName"
                  onChange={e => handleChange("formName", e)}
                />
              </Form.Field>

              <Form.Field>
                <Radio
                  label="Admin"
                  name="radioGroupNew"
                  value={true}
                  checked={state.formStatus}
                  onChange={(e, data) => handleRadioChange(e, data)}
                />
              </Form.Field>

              <Form.Field>
                <Radio
                  label="User"
                  name="radioGroupNew"
                  value={false}
                  checked={!state.formStatus}
                  onChange={(e, data) => handleRadioChange(e, data)}
                  disabled={state.formId === Auth.user._id}
                />
              </Form.Field>
              <Form.Field>
                <label>Username</label>
                <input
                  placeholder="Username"
                  value={formUsername}
                  name="formUsername"
                  onChange={e => handleChange("formUsername", e)}
                />
              </Form.Field>
              <Form.Field>
                <label>Password</label>
                <input
                  placeholder="******"
                  name="formPassword"
                  type="password"
                  onChange={e => handleChange("formPassword", e)}
                />
              </Form.Field>
              <Button onClick={onNewClose} style={{ float: "right", marginTop: 10 }}>
                Cancel
              </Button>
              <ButtonGreen
                style={{ float: "right", marginTop: 10, marginRight: 10 }}
                disabled={isNew ? !formPassword || !formName || !formUsername : false}
                onClick={isNew ? onAddHandler : onUpdateHandler}
              >
                Save
              </ButtonGreen>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  return useObserver(() => {
    return (
      <Grid className="default-page-padding" centered>
        <Grid.Row>
          <Grid.Column width={10}>
            <Grid.Row>
              <Grid stackable>
                <Grid.Column width={8} style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ color: colors.MH_GRAY, fontSize: 18 }}>Users List</span>
                </Grid.Column>
                <Grid.Column width={8}>
                  <ButtonBasicGreen
                    size={"medium"}
                    style={{ float: "right" }}
                    disabled={Auth.user && !Auth.user.admin}
                    onClick={onNewOpen}
                  >
                    Add User
                  </ButtonBasicGreen>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10} style={{ overflow: "auto" }}>
            <Table singleLine unstackable celled color={"green"}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Username</Table.HeaderCell>
                  <Table.HeaderCell>User Type</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: 1, textAlign: "center" }}>
                    Update
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: 1, textAlign: "center" }}>
                    Delete
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {User.users.map(user => {
                  return (
                    <Table.Row key={user._id}>
                      <Table.Cell>{user.name}</Table.Cell>
                      <Table.Cell>{user.username}</Table.Cell>
                      <Table.Cell>{user.admin ? "Admin" : "User"}</Table.Cell>
                      <Table.Cell style={{ textAlign: "center", padding: 5 }}>
                        <ButtonBasicGreen
                          disabled={Auth.user && !Auth.user.admin}
                          style={{ margin: 0, padding: 8 }}
                          onClick={() => popupHandler(user)}
                        >
                          <Icon style={{ margin: 0 }} name="edit outline" />
                        </ButtonBasicGreen>
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center", padding: 10 }}>
                        <ButtonBasicRed
                          disabled={Auth.user && !Auth.user.admin}
                          style={{ margin: 0, padding: 8 }}
                          onClick={() => displayWarningModal(user.username)}
                        >
                          <Icon style={{ margin: 0 }} name="trash alternate outline" />
                        </ButtonBasicRed>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan="14" style={{ borderLeft: 0 }}>
                    <Pagination
                      style={{ float: "right" }}
                      defaultActivePage={1}
                      totalPages={Math.ceil(User.userCount / 10.0)}
                      onPageChange={onHandlePagination}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <WarningModal
          open={state.warningModal}
          head={"Warning"}
          body={`Do you want to delete user ${state.deleteUsername}? `}
          parentFunc={onDeleteHandler}
          onCancelHandler={() =>
            setState({ ...state, warningModal: false, deleteUsername: undefined })
          }
        />
        <Grid.Row>{renderModal()}</Grid.Row>
      </Grid>
    );
  });
};

export default UsersPage;
