import { useObserver } from "mobx-react";
import React, { useContext } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { Flag, Table } from "semantic-ui-react";
import { ThemeContext } from "styled-components";

import { useStore } from "../../_core/appContext";
import worldCountryList from "../../utility/constants/worldCountryList";
import { GridColumn, InputField, TabContainer, Title } from "../CountryDetail/CountryDetailStyles";

const vehicleData = {
  cars: "Cars",
  deliveryTrucks: "Delivery Trucks",
  buses: "Buses",
  motorcycles: "Motorcycles",
  tractors: "Tractors"
};

const ExplanationPopup = ({ field }) => {
  const { colors } = useContext(ThemeContext);
  const { Country } = useStore();
  const getFlagName = name => {
    const found = worldCountryList.find(country => country.text === name);
    if (found) return found.flag;
  };

  const PopupContentHandler = () => {
    const brandNameSet = new Set(["MANN-FILTER", "FILTRON", "WIX"]);
    Country.currentCountry.walletShareDistributors.slice().forEach(({ data }) => {
      Object.keys(data).forEach(brandName => {
        if (!brandNameSet.has(brandName)) {
          brandNameSet.add(brandName);
        }
      });
    });
    const brandNames = Array.from(brandNameSet);
    switch (field) {
      case "motorizationRate":
        return (
          <span>
            Number of road motor vehicles per 1.000 inhabitants. This includes cars, vans, buses,
            and freight and other trucks; but excludes motorcycles and other two-wheelers
          </span>
        );
      case "internetPenetration":
        return (
          <span>
            The ranking is led by Denmark with a value of 88. The sad last place is occupied by
            Somalia (10 points).
          </span>
        );
      case "exportBreakdown":
        return (
          <React.Fragment>
            <b>Agricultural (or primary) sector</b>
            <p>
              Agriculture (a term which encompasses farming) is the process of producing food, feed,
              fiber and other goods by the systematic raising of plants and animals. Agricultural
              output is a component of the GDP of a nation.
            </p>
            <b>Industrial (or secondary) sector</b>
            <p>
              Industry is the segment of economy concerned with production of goods (including fuels
              and fertilisers). Industrial output is a component of the GDP of a nation.
            </p>
            <b>Service (or tertiary) sector</b>
            <p>
              A service is the non-material equivalent of a good. Service provision is defined as an
              economic activity that does not result in ownership, and this is what differentiates
              it from providing physical goods. It is claimed to be a process that creates benefits
              by facilitating either a change in customers, a change in their physical possessions,
              or a change in their intangible assets. Service output is a component of the GDP of a
              nation. The service sector includes (but is not limited to) farm and factory related
              activities.
            </p>
          </React.Fragment>
        );
      case "population":
        return (
          <GridColumn>
            <TabContainer>
              <Title>Major Cities</Title>
              <Table celled size="small" fixed textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="bold" width="8">
                      City
                    </Table.HeaderCell>
                    <Table.HeaderCell className="bold">Population</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Country.currentCountry.majorCities.map((data, index) => {
                    return (
                      <Table.Row key={data}>
                        <Table.Cell className="bold" width="8">
                          {data.city}
                        </Table.Cell>
                        <Table.Cell>
                          <InputField
                            id={`majorCities${index}`}
                            disabled={true}
                            defaultValue={new Intl.NumberFormat("de-DE").format(data.population)}
                            clicked={false}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </TabContainer>
          </GridColumn>
        );
      case "vehicleImport":
        return (
          <React.Fragment>
            <GridColumn>
              <TabContainer>
                <Title>Import of Vehicles (2016)</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width="8" className="bold">
                        Total (2016) (Bil USD)
                      </Table.Cell>
                      <Table.Cell>
                        <InputField
                          id="vehicleImportTotal"
                          disabled={true}
                          defaultValue={Country.currentCountry.vehicleImportExp[2016].total}
                          clicked={false}
                          type="number"
                        />
                      </Table.Cell>
                    </Table.Row>
                    {Object.keys(vehicleData).map(data => (
                      <Table.Row key={data}>
                        <Table.Cell width="8" className="bold">
                          {vehicleData[data]}
                        </Table.Cell>
                        <Table.Cell>
                          <InputField
                            id={data}
                            disabled={true}
                            defaultValue={
                              Country.currentCountry.vehicleImportExp[2016][vehicleData[data]]
                                .percentage + "%"
                            }
                            clicked={false}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridColumn>
            <GridColumn>
              <TabContainer>
                <Title>Import of Vehicles (2015)</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width="8" className="bold">
                        Total (2015) (Bil USD)
                      </Table.Cell>
                      <Table.Cell>
                        <InputField
                          id="vehicleImportTotal"
                          disabled={true}
                          defaultValue={Country.currentCountry.vehicleImportExp[2015].total}
                          clicked={false}
                          type="number"
                        />
                      </Table.Cell>
                    </Table.Row>
                    {Object.keys(vehicleData).map(data => (
                      <Table.Row key={data}>
                        <Table.Cell width="8" className="bold">
                          {vehicleData[data]}
                        </Table.Cell>
                        <Table.Cell>
                          <InputField
                            id={data}
                            disabled={true}
                            defaultValue={
                              Country.currentCountry.vehicleImportExp[2015][vehicleData[data]]
                                .percentage + "%"
                            }
                            clicked={false}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridColumn>
          </React.Fragment>
        );
      case "carImport":
        return (
          <React.Fragment>
            <GridColumn>
              <TabContainer>
                <Title>Car Import Countries (2016)</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    {Country.currentCountry.carImportExp[2016].data &&
                      Country.currentCountry.carImportExp[2016].data.map((data, index) => (
                        <Table.Row key={index}>
                          <Table.Cell width="8" style={{ overflow: "visible" }}>
                            <div className="bold">
                              {data.country && <Flag name={getFlagName(data.country)} />}
                              {data.country}
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              id={`carImportExplanationInput${index}`}
                              disabled={true}
                              defaultValue={data.percentage}
                              clicked={false}
                              type="number"
                              content={data.percentage + "%"}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridColumn>
            <GridColumn>
              <TabContainer>
                <Title>Car Import Countries (2015)</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    {Country.currentCountry.carImportExp[2016].data &&
                      Country.currentCountry.carImportExp[2015].data.map((data, index) => (
                        <Table.Row key={index}>
                          <Table.Cell width="8" style={{ overflow: "visible" }}>
                            <div className="bold">
                              {data.country && <Flag name={getFlagName(data.country)} />}
                              {data.country}
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              id={`carImportExplanationInput${index}`}
                              disabled={true}
                              defaultValue={data.percentage}
                              clicked={false}
                              type="number"
                              content={data.percentage + "%"}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridColumn>
          </React.Fragment>
        );
      case "airFilterImport":
        return (
          <React.Fragment>
            <GridColumn>
              <TabContainer>
                <Title>Air Filter Import (2016)</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="bold" width="8">
                        Total (2016) (Mil. USD)
                      </Table.Cell>
                      <Table.Cell>
                        <InputField
                          id="airFilterImportTotal"
                          defaultValue={Country.currentCountry.airFilterImportExp[2016].total}
                          disabled={true}
                          type="number"
                          clicked={false}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {Country.currentCountry.airFilterImportExp[2016].data &&
                      Country.currentCountry.airFilterImportExp[2016].data.map((data, index) => (
                        <Table.Row key={data.country}>
                          <Table.Cell width="8" style={{ overflow: "visible" }}>
                            <div className="bold">
                              {data.country && <Flag name={getFlagName(data.country)} />}
                              {data.country}
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              defaultValue={data.percentage}
                              disabled={true}
                              id={`airFilterImportExp${index}`}
                              clicked={false}
                              type="number"
                              content={data.percentage + "%"}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridColumn>
            <GridColumn>
              <TabContainer>
                <Title>Air Filter Import (2015)</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="bold" width="8">
                        Total (2015) (Mil. USD)
                      </Table.Cell>
                      <Table.Cell>
                        <InputField
                          id="airFilterImportTotal"
                          defaultValue={Country.currentCountry.airFilterImportExp[2015].total}
                          disabled={true}
                          type="number"
                          clicked={false}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {Country.currentCountry.airFilterImportExp[2015].data &&
                      Country.currentCountry.airFilterImportExp[2015].data.map((data, index) => (
                        <Table.Row key={data.country}>
                          <Table.Cell width="8" style={{ overflow: "visible" }}>
                            <div className="bold">
                              {data.country && <Flag name={getFlagName(data.country)} />}
                              {data.country}
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              defaultValue={data.percentage}
                              disabled={true}
                              id={`airFilterImportExp${index}`}
                              clicked={false}
                              type="number"
                              content={data.percentage + "%"}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridColumn>
          </React.Fragment>
        );
      case "oilFilterImport":
        return (
          <React.Fragment>
            <GridColumn>
              <TabContainer>
                <Title>Oil & Petrol Filter Import (2016)</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="bold" width="8">
                        Total (2016) (Mil. USD)
                      </Table.Cell>
                      <Table.Cell>
                        <InputField
                          id="airFilterImportTotal"
                          defaultValue={Country.currentCountry.oilFilterImportExp[2016].total}
                          disabled={true}
                          type="number"
                          clicked={false}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {Country.currentCountry.oilFilterImportExp[2016].data &&
                      Country.currentCountry.oilFilterImportExp[2016].data.map((data, index) => (
                        <Table.Row key={data.country}>
                          <Table.Cell width="8" style={{ overflow: "visible" }}>
                            <div className="bold">
                              {data.country && <Flag name={getFlagName(data.country)} />}
                              {data.country}
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              defaultValue={data.percentage}
                              disabled={true}
                              id={`airFilterImportExp${index}`}
                              clicked={false}
                              type="number"
                              content={data.percentage + "%"}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridColumn>
            <GridColumn>
              <TabContainer>
                <Title>Oil & Petrol Filter Import (2015)</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="bold" width="8">
                        Total (2015) (Mil. USD)
                      </Table.Cell>
                      <Table.Cell>
                        <InputField
                          id="oilFilterImportTotal"
                          defaultValue={Country.currentCountry.oilFilterImportExp[2015].total}
                          disabled={true}
                          type="number"
                          clicked={false}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {Country.currentCountry.oilFilterImportExp[2015].data &&
                      Country.currentCountry.oilFilterImportExp[2015].data.map((data, index) => (
                        <Table.Row key={data.country}>
                          <Table.Cell width="8" style={{ overflow: "visible" }}>
                            <div className="bold">
                              {data.country && <Flag name={getFlagName(data.country)} />}
                              {data.country}
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              defaultValue={data.percentage}
                              disabled={true}
                              id={`airFilterImportExp${index}`}
                              clicked={false}
                              type="number"
                              content={data.percentage + "%"}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridColumn>
          </React.Fragment>
        );
      case "walletShare":
        return (
          <GridColumn style={{ width: brandNames && brandNames.length > 5 ? "1100px" : "700px" }}>
            <TabContainer>
              <Title>Country Wallet Share</Title>
              <Table celled size="small" fixed>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell className="bold" width="8">
                      Distributor
                    </Table.Cell>
                    {brandNames.map(brand => (
                      <Table.Cell key={brand} className="bold" width="8">
                        {brand}
                      </Table.Cell>
                    ))}
                    <Table.Cell className="bold" width="8">
                      Total (K €)
                    </Table.Cell>
                    <Table.Cell className="bold" width="8">
                      M+H WALLET SHARE
                    </Table.Cell>
                  </Table.Row>
                  {Country.currentCountry.walletShareDistributors
                    .slice()
                    .filter(data => data.totalValue)
                    .map(({ totalValue, mhWalletShare, distributorName, data }) => {
                      return (
                        <Table.Row key={distributorName}>
                          <Table.Cell width="10" style={{ overflow: "visible" }}>
                            <div className="bold">{distributorName}</div>
                          </Table.Cell>
                          {brandNames.map((brand, index) => {
                            return (
                              <Table.Cell width="8" key={index + brand + "row"}>
                                {data[brand] ? data[brand].brandValue : ""}
                              </Table.Cell>
                            );
                          })}
                          <Table.Cell width="8" style={{ overflow: "visible" }}>
                            <div className="bold">{totalValue}</div>
                          </Table.Cell>
                          <Table.Cell width="8" style={{ overflow: "visible" }}>
                            <div className="bold">{mhWalletShare.toFixed(2) + "%"}</div>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </TabContainer>
          </GridColumn>
        );
      default:
        return null;
    }
  };

  return useObserver(() => {
    return (
      <Popup
        position="right center"
        trigger={
          <Icon
            style={{ color: colors.MH_ACCENT_BLUE, marginLeft: 2, cursor: "pointer" }}
            name="info circle"
          />
        }
        hoverable
        content={PopupContentHandler()}
        wide={field === "walletShare" ? "very" : true}
      />
    );
  });
};

export default ExplanationPopup;
