import React from "react";
import Chart from "react-apexcharts";

const StrokedGaugeChart = ({ value, color, label }) => {
  const state = {
    series: [value / 10],
    options: {
      chart: {
        height: 224,
        type: "radialBar",
        animations: {
          speed: 1800,
          easing: "easein",
          animateGradually: {
            enabled: true,
            delay: 2000
          }
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: "14px",
              color: "rgb(55, 61, 63);",
              offsetY: 70
            },
            value: {
              offsetY: -5,
              fontSize: "20px",
              color: undefined,
              formatter: function() {
                return value;
              }
            }
          }
        }
      },
      colors: [color],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91]
        }
      },
      stroke: {
        dashArray: 4
      },
      labels: [label]
    }
  };
  return (
    <div id="chart">
      <Chart options={state.options} series={state.series} type="radialBar" height={232} />
    </div>
  );
};

export default StrokedGaugeChart;
