const config = {};
let baseUrl;
config.getBasePublicUrl = () => {
  if (process.env.NODE_ENV === "development") {
    baseUrl = "http://localhost:8080/";
  } else if (process.env.NODE_ENV === "production") {
    baseUrl = "/";
  } else {
    baseUrl = "http://localhost:8080/";
  }
  return baseUrl;
};

export default config;
