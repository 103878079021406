const pickColor = (options, value, min, max) => {
  let shouldPickColur;
  if (min || max) {
    if (!max) {
      if (min <= value) {
        shouldPickColur = true;
      }
    } else {
      if (min <= value && max >= value) {
        shouldPickColur = true;
      }
    }
  } else {
    shouldPickColur = true;
  }
  let rcolor;
  if (shouldPickColur) {
    options.forEach(obj => {
      if (obj.min <= value && (!obj.max || obj.max >= value)) {
        const { color } = obj;
        rcolor = color;
      }
    });
  }
  return rcolor;
};

const getX = (state, country) => {
  if (country === "benin") {
    return state.x + 10;
  } else if (country === "burkina faso") {
    return state.x;
  } else if (country === "ivory coast") {
    return state.x + 10;
  } else if (country === "gambia") {
    return state.x - 40;
  } else if (country === "cameroon") {
    return state.x + 15;
  } else if (country === "guinea") {
    return state.x + 20;
  } else if (country === "guinea-bissau") {
    return state.x - 35;
  } else if (country === "liberia") {
    return state.x - 10;
  } else if (country === "mali") {
    return state.x + 60;
  } else if (country === "mauritania") {
    return state.x + 40;
  } else if (country === "niger") {
    return state.x + 60;
  } else if (country === "senegal") {
    return state.x - 10;
  } else if (country === "sierra leone") {
    return state.x - 30;
  } else if (country === "togo") {
    return state.x - 10;
  } else if (country === "algeria") {
    return state.x + 70;
  } else if (country === "libya") {
    return state.x + 50;
  } else if (country === "morocco") {
    return state.x + 30;
  } else if (country === "equatorial guinea") {
    return state.x - 30;
  } else if (country === "uganda") {
    return state.x;
  } else if (country === "egypt") {
    return state.x + 20;
  } else if (country === "nigeria") {
    return state.x + 30;
  } else if (country === "sudan") {
    return state.x + 40;
  } else if (country === "south sudan") {
    return state.x + 30;
  } else if (country === "ethiopia") {
    return state.x + 25;
  } else if (country === "somalia") {
    return state.x + 30;
  } else if (country === "western sahara") {
    return state.x;
  } else if (country === "democratic republic of the congo") {
    return state.x + 60;
  } else if (country === "gabon") {
    return state.x - 20;
  } else if (country === "south africa") {
    return state.x + 30;
  } else if (country === "zambia") {
    return state.x + 10;
  } else if (country === "zimbabwe") {
    return state.x + 10;
  } else if (country === "angola") {
    return state.x + 20;
  } else if (country === "namibia") {
    return state.x + 20;
  } else if (country === "botswana") {
    return state.x + 10;
  } else if (country === "mozambique") {
    return state.x + 20;
  } else if (country === "malawi") {
    return state.x;
  } else if (country === "madagascar") {
    return state.x + 5;
  } else if (country === "tanzania") {
    return state.x + 40;
  } else if (country === "republic of the congo") {
    return state.x + 20;
  } else if (country === "ghana") {
    return state.x;
  } else if (country === "chad") {
    return state.x + 30;
  } else if (country === "central african republic") {
    return state.x + 30;
  } else if (country === "burundi") {
    return state.x;
  } else if (country === "eritrea") {
    return state.x;
  } else if (country === "kenya") {
    return state.x + 10;
  } else if (country === "rwanda") {
    return state.x - 30;
  } else if (country === "tunisia") {
    return state.x;
  } else if (country === "cape verde") {
    return state.x - 20;
  } else if (country === "saint helena") {
    return state.x;
  } else if (country === "swaziland") {
    return state.x;
  } else if (country === "lesotho") {
    return state.x;
  } else if (country === "sao tome and principe") {
    return state.x - 60;
  } else if (country === "british indian ocean territory") {
    return state.x;
  } else if (country === "djibouti") {
    return state.x;
  } else if (country === "comoros") {
    return state.x - 10;
  } else if (country === "mauritius") {
    return state.x;
  } else if (country === "mayotte") {
    return state.x + 10;
  } else if (country === "reunion") {
    return state.x - 20;
  } else if (country === "seychelles") {
    return state.x;
  }
};

const getY = (state, country) => {
  if (country === "benin") {
    return state.y + 10;
  } else if (country === "burkina faso") {
    return state.y;
  } else if (country === "ivory coast") {
    return state.y + 30;
  } else if (country === "gambia") {
    return state.y - 10;
  } else if (country === "cameroon") {
    return state.y + 35;
  } else if (country === "guinea") {
    return state.y - 25;
  } else if (country === "guinea-bissau") {
    return state.y + 15;
  } else if (country === "liberia") {
    return state.y + 15;
  } else if (country === "mali") {
    return state.y + 20;
  } else if (country === "mauritania") {
    return state.y + 30;
  } else if (country === "niger") {
    return state.y + 20;
  } else if (country === "senegal") {
    return state.y - 30;
  } else if (country === "sierra leone") {
    return state.y + 10;
  } else if (country === "togo") {
    return state.y + 30;
  } else if (country === "algeria") {
    return state.y + 50;
  } else if (country === "libya") {
    return state.y + 30;
  } else if (country === "morocco") {
    return state.y + 12;
  } else if (country === "equatorial guinea") {
    return state.y - 10;
  } else if (country === "uganda") {
    return state.y - 10;
  } else if (country === "egypt") {
    return state.y + 20;
  } else if (country === "nigeria") {
    return state.y + 10;
  } else if (country === "sudan") {
    return state.y + 20;
  } else if (country === "south sudan") {
    return state.y + 10;
  } else if (country === "ethiopia") {
    return state.y + 25;
  } else if (country === "somalia") {
    return state.y + 30;
  } else if (country === "western sahara") {
    return state.y;
  } else if (country === "democratic republic of the congo") {
    return state.y + 40;
  } else if (country === "gabon") {
    return state.y + 20;
  } else if (country === "south africa") {
    return state.y + 60;
  } else if (country === "zambia") {
    return state.y + 30;
  } else if (country === "zimbabwe") {
    return state.y;
  } else if (country === "angola") {
    return state.y + 50;
  } else if (country === "namibia") {
    return state.y + 30;
  } else if (country === "botswana") {
    return state.y + 10;
  } else if (country === "mozambique") {
    return state.y + 50;
  } else if (country === "malawi") {
    return state.y;
  } else if (country === "madagascar") {
    return state.y + 40;
  } else if (country === "tanzania") {
    return state.y + 30;
  } else if (country === "republic of the congo") {
    return state.y + 20;
  } else if (country === "ghana") {
    return state.y;
  } else if (country === "chad") {
    return state.y + 30;
  } else if (country === "central african republic") {
    return state.y + 10;
  } else if (country === "burundi") {
    return state.y + 10;
  } else if (country === "eritrea") {
    return state.y;
  } else if (country === "kenya") {
    return state.y + 10;
  } else if (country === "rwanda") {
    return state.y - 10;
  } else if (country === "tunisia") {
    return state.y;
  } else if (country === "cape verde") {
    return state.y + 20;
  } else if (country === "saint helena") {
    return state.y;
  } else if (country === "swaziland") {
    return state.y;
  } else if (country === "lesotho") {
    return state.y;
  } else if (country === "sao tome and principe") {
    return state.y;
  } else if (country === "british indian ocean territory") {
    return state.y;
  } else if (country === "djibouti") {
    return state.y;
  } else if (country === "comoros") {
    return state.y - 40;
  } else if (country === "mauritius") {
    return state.y;
  } else if (country === "mayotte") {
    return state.y - 30;
  } else if (country === "reunion") {
    return state.y + 10;
  } else if (country === "seychelles") {
    return state.y;
  }
};

const getImageX = country => {
  if (country === "benin") {
    return "-139";
  } else if (country === "burkina faso") {
    return "-110";
  } else if (country === "ivory coast") {
    return "-120";
  } else if (country === "gambia") {
    return "-30";
  } else if (country === "cameroon") {
    return "-120";
  } else if (country === "guinea") {
    return "-110";
  } else if (country === "guinea-bissau") {
    return "-30";
  } else if (country === "liberia") {
    return "-130";
  } else if (country === "mali") {
    return "-60";
  } else if (country === "mauritania") {
    return "-90";
  } else if (country === "niger") {
    return "-80";
  } else if (country === "senegal") {
    return "-125";
  } else if (country === "sierra leone") {
    return "-137";
  } else if (country === "togo") {
    return "0";
  } else if (country === "algeria") {
    return "-50";
  } else if (country === "libya") {
    return "-80";
  } else if (country === "morocco") {
    return "-100";
  } else if (country === "equatorial guinea") {
    return "-50";
  } else if (country === "uganda") {
    return "-130";
  } else if (country === "egypt") {
    return "-110";
  } else if (country === "nigeria") {
    return "-110";
  } else if (country === "sudan") {
    return "-80";
  } else if (country === "south sudan") {
    return "-93";
  } else if (country === "ethiopia") {
    return "-100";
  } else if (country === "somalia") {
    return "-100";
  } else if (country === "western sahara") {
    return "0";
  } else if (country === "democratic republic of the congo") {
    return "-60";
  } else if (country === "gabon") {
    return "-125";
  } else if (country === "south africa") {
    return "-90";
  } else if (country === "zambia") {
    return "-120";
  } else if (country === "zimbabwe") {
    return "0";
  } else if (country === "angola") {
    return "-100";
  } else if (country === "namibia") {
    return "0";
  } else if (country === "botswana") {
    return "10";
  } else if (country === "mozambique") {
    return "-120";
  } else if (country === "malawi") {
    return "0";
  } else if (country === "madagascar") {
    return "-125";
  } else if (country === "tanzania") {
    return "-105";
  } else if (country === "republic of the congo") {
    return "-115";
  } else if (country === "ghana") {
    return "-130";
  } else if (country === "chad") {
    return "-110";
  } else if (country === "central african republic") {
    return "-90";
  } else if (country === "burundi") {
    return "0";
  } else if (country === "eritrea") {
    return "-135";
  } else if (country === "kenya") {
    return "-120";
  } else if (country === "rwanda") {
    return "-40";
  } else if (country === "tunisia") {
    return "-135";
  } else if (country === "cape verde") {
    return "-20";
  } else if (country === "saint helena") {
    return "0";
  } else if (country === "swaziland") {
    return "0";
  } else if (country === "lesotho") {
    return "0";
  } else if (country === "sao tome and principe") {
    return "-60";
  } else if (country === "british indian ocean territory") {
    return "0";
  } else if (country === "djibouti") {
    return "0";
  } else if (country === "comoros") {
    return "-10";
  } else if (country === "mauritius") {
    return "0";
  } else if (country === "mayotte") {
    return "10";
  } else if (country === "reunion") {
    return "-20";
  } else if (country === "seychelles") {
    return "0";
  }
};

const getImageY = country => {
  if (country === "benin") {
    return "-72";
  } else if (country === "burkina faso") {
    return "-80";
  } else if (country === "ivory coast") {
    return "-70";
  } else if (country === "gambia") {
    return "-30";
  } else if (country === "cameroon") {
    return "-30";
  } else if (country === "guinea") {
    return "-80";
  } else if (country === "guinea-bissau") {
    return "-30";
  } else if (country === "liberia") {
    return "-80";
  } else if (country === "mali") {
    return "-40";
  } else if (country === "mauritania") {
    return "-30";
  } else if (country === "niger") {
    return "-40";
  } else if (country === "senegal") {
    return "-85";
  } else if (country === "sierra leone") {
    return "-87";
  } else if (country === "togo") {
    return "0";
  } else if (country === "algeria") {
    return "0";
  } else if (country === "libya") {
    return "-40";
  } else if (country === "morocco") {
    return "-50";
  } else if (country === "equatorial guinea") {
    return "-50";
  } else if (country === "uganda") {
    return "-72";
  } else if (country === "egypt") {
    return "-55";
  } else if (country === "nigeria") {
    return "-60";
  } else if (country === "sudan") {
    return "-40";
  } else if (country === "south sudan") {
    return "-60";
  } else if (country === "ethiopia") {
    return "-55";
  } else if (country === "somalia") {
    return "-40";
  } else if (country === "western sahara") {
    return "0";
  } else if (country === "democratic republic of the congo") {
    return "-40";
  } else if (country === "gabon") {
    return "-80";
  } else if (country === "south africa") {
    return "-30";
  } else if (country === "zambia") {
    return "-45";
  } else if (country === "zimbabwe") {
    return "0";
  } else if (country === "angola") {
    return "-30";
  } else if (country === "namibia") {
    return "0";
  } else if (country === "botswana") {
    return "10";
  } else if (country === "mozambique") {
    return "-30";
  } else if (country === "malawi") {
    return "0";
  } else if (country === "madagascar") {
    return "-30";
  } else if (country === "tanzania") {
    return "-60";
  } else if (country === "republic of the congo") {
    return "-60";
  } else if (country === "ghana") {
    return "-70";
  } else if (country === "chad") {
    return "-30";
  } else if (country === "central african republic") {
    return "-60";
  } else if (country === "burundi") {
    return "0";
  } else if (country === "eritrea") {
    return "-80";
  } else if (country === "kenya") {
    return "-60";
  } else if (country === "rwanda") {
    return "-40";
  } else if (country === "tunisia") {
    return "-70";
  } else if (country === "cape verde") {
    return "-20";
  } else if (country === "saint helena") {
    return "0";
  } else if (country === "swaziland") {
    return "0";
  } else if (country === "lesotho") {
    return "0";
  } else if (country === "sao tome and principe") {
    return "-60";
  } else if (country === "british indian ocean territory") {
    return "0";
  } else if (country === "djibouti") {
    return "0";
  } else if (country === "comoros") {
    return "-10";
  } else if (country === "mauritius") {
    return "0";
  } else if (country === "mayotte") {
    return "10";
  } else if (country === "reunion") {
    return "-20";
  } else if (country === "seychelles") {
    return "0";
  }
};

const getUSImageX = country => {
  if (country === "benin") {
    return "-100";
  } else if (country === "burkina faso") {
    return "-100";
  } else if (country === "ivory coast") {
    return "-95";
  } else if (country === "gambia") {
    return "-30";
  } else if (country === "cameroon") {
    return "-90";
  } else if (country === "guinea") {
    return "-110";
  } else if (country === "guinea-bissau") {
    return "-30";
  } else if (country === "liberia") {
    return "-100";
  } else if (country === "mali") {
    return "-50";
  } else if (country === "mauritania") {
    return "-90";
  } else if (country === "niger") {
    return "-70";
  } else if (country === "senegal") {
    return "-95";
  } else if (country === "sierra leone") {
    return "-102";
  } else if (country === "togo") {
    return "0";
  } else if (country === "algeria") {
    return "-90";
  } else if (country === "libya") {
    return "-90";
  } else if (country === "morocco") {
    return "-70";
  } else if (country === "equatorial guinea") {
    return "-50";
  } else if (country === "uganda") {
    return "-95";
  } else if (country === "egypt") {
    return "-90";
  } else if (country === "nigeria") {
    return "-90";
  } else if (country === "sudan") {
    return "-80";
  } else if (country === "south sudan") {
    return "-95";
  } else if (country === "ethiopia") {
    return "-90";
  } else if (country === "somalia") {
    return "-90";
  } else if (country === "western sahara") {
    return "-80";
  } else if (country === "democratic republic of the congo") {
    return "-50";
  } else if (country === "gabon") {
    return "-90";
  } else if (country === "south africa") {
    return "-90";
  } else if (country === "zambia") {
    return "-90";
  } else if (country === "zimbabwe") {
    return "-85";
  } else if (country === "angola") {
    return "-85";
  } else if (country === "namibia") {
    return "-90";
  } else if (country === "botswana") {
    return "-90";
  } else if (country === "mozambique") {
    return "-70";
  } else if (country === "malawi") {
    return "0";
  } else if (country === "madagascar") {
    return "-60";
  } else if (country === "tanzania") {
    return "-105";
  } else if (country === "republic of the congo") {
    return "60";
  } else if (country === "ghana") {
    return "-100";
  } else if (country === "chad") {
    return "-80";
  } else if (country === "central african republic") {
    return "-90";
  } else if (country === "burundi") {
    return "0";
  } else if (country === "eritrea") {
    return "-100";
  } else if (country === "kenya") {
    return "-95";
  } else if (country === "rwanda") {
    return "-40";
  } else if (country === "tunisia") {
    return "-60";
  } else if (country === "cape verde") {
    return "-20";
  } else if (country === "saint helena") {
    return "0";
  } else if (country === "swaziland") {
    return "0";
  } else if (country === "lesotho") {
    return "0";
  } else if (country === "sao tome and principe") {
    return "-60";
  } else if (country === "british indian ocean territory") {
    return "0";
  } else if (country === "djibouti") {
    return "0";
  } else if (country === "comoros") {
    return "-10";
  } else if (country === "mauritius") {
    return "0";
  } else if (country === "mayotte") {
    return "10";
  } else if (country === "reunion") {
    return "-20";
  } else if (country === "seychelles") {
    return "0";
  }
};

const getUSImageY = country => {
  if (country === "benin") {
    return "-82";
  } else if (country === "burkina faso") {
    return "0";
  } else if (country === "ivory coast") {
    return "-85";
  } else if (country === "gambia") {
    return "-30";
  } else if (country === "cameroon") {
    return "-30";
  } else if (country === "guinea") {
    return "-80";
  } else if (country === "guinea-bissau") {
    return "-30";
  } else if (country === "liberia") {
    return "0";
  } else if (country === "mali") {
    return "-80";
  } else if (country === "mauritania") {
    return "-30";
  } else if (country === "niger") {
    return "-40";
  } else if (country === "senegal") {
    return "-85";
  } else if (country === "sierra leone") {
    return "-95";
  } else if (country === "togo") {
    return "-85";
  } else if (country === "algeria") {
    return "0";
  } else if (country === "libya") {
    return "-80";
  } else if (country === "morocco") {
    return "-60";
  } else if (country === "equatorial guinea") {
    return "-50";
  } else if (country === "uganda") {
    return "-80";
  } else if (country === "egypt") {
    return "-80";
  } else if (country === "nigeria") {
    return "-85";
  } else if (country === "sudan") {
    return "-80";
  } else if (country === "south sudan") {
    return "0";
  } else if (country === "ethiopia") {
    return "-60";
  } else if (country === "somalia") {
    return "-60";
  } else if (country === "western sahara") {
    return "-80";
  } else if (country === "democratic republic of the congo") {
    return "-70";
  } else if (country === "gabon") {
    return "-80";
  } else if (country === "south africa") {
    return "-30";
  } else if (country === "zambia") {
    return "-60";
  } else if (country === "zimbabwe") {
    return "-80";
  } else if (country === "angola") {
    return "-70";
  } else if (country === "namibia") {
    return "-80";
  } else if (country === "botswana") {
    return "-80";
  } else if (country === "mozambique") {
    return "-60";
  } else if (country === "malawi") {
    return "-80";
  } else if (country === "madagascar") {
    return "-80";
  } else if (country === "tanzania") {
    return "-60";
  } else if (country === "republic of the congo") {
    return "60";
  } else if (country === "ghana") {
    return "-80";
  } else if (country === "chad") {
    return "-80";
  } else if (country === "central african republic") {
    return "-60";
  } else if (country === "burundi") {
    return "0";
  } else if (country === "eritrea") {
    return "-85";
  } else if (country === "kenya") {
    return "-80";
  } else if (country === "rwanda") {
    return "-40";
  } else if (country === "tunisia") {
    return "-80";
  } else if (country === "cape verde") {
    return "-20";
  } else if (country === "saint helena") {
    return "0";
  } else if (country === "swaziland") {
    return "0";
  } else if (country === "lesotho") {
    return "0";
  } else if (country === "sao tome and principe") {
    return "-60";
  } else if (country === "british indian ocean territory") {
    return "0";
  } else if (country === "djibouti") {
    return "0";
  } else if (country === "comoros") {
    return "-10";
  } else if (country === "mauritius") {
    return "0";
  } else if (country === "mayotte") {
    return "10";
  } else if (country === "reunion") {
    return "-20";
  } else if (country === "seychelles") {
    return "0";
  }
};

const getZAImageX = country => {
  if (country === "benin") {
    return "-40";
  } else if (country === "burkina faso") {
    return "-20";
  } else if (country === "ivory coast") {
    return "-80";
  } else if (country === "gambia") {
    return "-30";
  } else if (country === "cameroon") {
    return "-70";
  } else if (country === "guinea") {
    return "-30";
  } else if (country === "guinea-bissau") {
    return "-30";
  } else if (country === "liberia") {
    return "-40";
  } else if (country === "mali") {
    return "-60";
  } else if (country === "mauritania") {
    return "-70";
  } else if (country === "niger") {
    return "-50";
  } else if (country === "senegal") {
    return "-15";
  } else if (country === "sierra leone") {
    return "-40";
  } else if (country === "togo") {
    return "0";
  } else if (country === "algeria") {
    return "-50";
  } else if (country === "libya") {
    return "-80";
  } else if (country === "morocco") {
    return "0";
  } else if (country === "equatorial guinea") {
    return "-50";
  } else if (country === "uganda") {
    return "-40";
  } else if (country === "egypt") {
    return "-110";
  } else if (country === "nigeria") {
    return "-60";
  } else if (country === "sudan") {
    return "-80";
  } else if (country === "south sudan") {
    return "0";
  } else if (country === "ethiopia") {
    return "-100";
  } else if (country === "somalia") {
    return "-30";
  } else if (country === "western sahara") {
    return "0";
  } else if (country === "democratic republic of the congo") {
    return "-45";
  } else if (country === "gabon") {
    return "-32";
  } else if (country === "south africa") {
    return "-90";
  } else if (country === "zambia") {
    return "-10";
  } else if (country === "zimbabwe") {
    return "-20";
  } else if (country === "angola") {
    return "-60";
  } else if (country === "namibia") {
    return "0";
  } else if (country === "botswana") {
    return "10";
  } else if (country === "mozambique") {
    return "0";
  } else if (country === "malawi") {
    return "0";
  } else if (country === "madagascar") {
    return "-120";
  } else if (country === "tanzania") {
    return "-105";
  } else if (country === "republic of the congo") {
    return "60";
  } else if (country === "ghana") {
    return "-40";
  } else if (country === "chad") {
    return "-110";
  } else if (country === "central african republic") {
    return "-90";
  } else if (country === "burundi") {
    return "0";
  } else if (country === "eritrea") {
    return "-45";
  } else if (country === "kenya") {
    return "-120";
  } else if (country === "rwanda") {
    return "-40";
  } else if (country === "tunisia") {
    return "-60";
  } else if (country === "cape verde") {
    return "-20";
  } else if (country === "saint helena") {
    return "0";
  } else if (country === "swaziland") {
    return "0";
  } else if (country === "lesotho") {
    return "0";
  } else if (country === "sao tome and principe") {
    return "-60";
  } else if (country === "british indian ocean territory") {
    return "0";
  } else if (country === "djibouti") {
    return "0";
  } else if (country === "comoros") {
    return "-10";
  } else if (country === "mauritius") {
    return "0";
  } else if (country === "mayotte") {
    return "10";
  } else if (country === "reunion") {
    return "-20";
  } else if (country === "seychelles") {
    return "0";
  }
};

const getZAImageY = country => {
  if (country === "benin") {
    return "-30";
  } else if (country === "burkina faso") {
    return "0";
  } else if (country === "ivory coast") {
    return "-70";
  } else if (country === "gambia") {
    return "-30";
  } else if (country === "cameroon") {
    return "-30";
  } else if (country === "guinea") {
    return "-30";
  } else if (country === "guinea-bissau") {
    return "-30";
  } else if (country === "liberia") {
    return "-40";
  } else if (country === "mali") {
    return "-40";
  } else if (country === "mauritania") {
    return "-30";
  } else if (country === "niger") {
    return "-50";
  } else if (country === "senegal") {
    return "-25";
  } else if (country === "sierra leone") {
    return "-40";
  } else if (country === "togo") {
    return "0";
  } else if (country === "algeria") {
    return "-10";
  } else if (country === "libya") {
    return "-40";
  } else if (country === "morocco") {
    return "-10";
  } else if (country === "equatorial guinea") {
    return "-50";
  } else if (country === "uganda") {
    return "-30";
  } else if (country === "egypt") {
    return "-50";
  } else if (country === "nigeria") {
    return "-65";
  } else if (country === "sudan") {
    return "-40";
  } else if (country === "south sudan") {
    return "0";
  } else if (country === "ethiopia") {
    return "-55";
  } else if (country === "somalia") {
    return "0";
  } else if (country === "western sahara") {
    return "0";
  } else if (country === "democratic republic of the congo") {
    return "-40";
  } else if (country === "gabon") {
    return "-30";
  } else if (country === "south africa") {
    return "-30";
  } else if (country === "zambia") {
    return "-10";
  } else if (country === "zimbabwe") {
    return "-20";
  } else if (country === "angola") {
    return "-30";
  } else if (country === "namibia") {
    return "0";
  } else if (country === "botswana") {
    return "10";
  } else if (country === "mozambique") {
    return "20";
  } else if (country === "malawi") {
    return "0";
  } else if (country === "madagascar") {
    return "-30";
  } else if (country === "tanzania") {
    return "-60";
  } else if (country === "republic of the congo") {
    return "60";
  } else if (country === "ghana") {
    return "-30";
  } else if (country === "chad") {
    return "-30";
  } else if (country === "central african republic") {
    return "-60";
  } else if (country === "burundi") {
    return "0";
  } else if (country === "eritrea") {
    return "-40";
  } else if (country === "kenya") {
    return "-60";
  } else if (country === "rwanda") {
    return "-40";
  } else if (country === "tunisia") {
    return "0";
  } else if (country === "cape verde") {
    return "-20";
  } else if (country === "saint helena") {
    return "0";
  } else if (country === "swaziland") {
    return "0";
  } else if (country === "lesotho") {
    return "0";
  } else if (country === "sao tome and principe") {
    return "-60";
  } else if (country === "british indian ocean territory") {
    return "0";
  } else if (country === "djibouti") {
    return "0";
  } else if (country === "comoros") {
    return "-10";
  } else if (country === "mauritius") {
    return "0";
  } else if (country === "mayotte") {
    return "10";
  } else if (country === "reunion") {
    return "-20";
  } else if (country === "seychelles") {
    return "0";
  }
};

export {
  pickColor,
  getX,
  getY,
  getImageX,
  getImageY,
  getUSImageX,
  getUSImageY,
  getZAImageX,
  getZAImageY
};
