const worldCountryList = [
  { value: "Afghanistan", text: "Afghanistan", flag: "af" },
  { value: "Aland Islands", text: "Aland Islands", flag: "ax" },
  { value: "Albania", text: "Albania", flag: "al" },
  { value: "Algeria", text: "Algeria", flag: "dz" },
  { value: "American Samoa", text: "American Samoa", flag: "as" },
  { value: "Andorra", text: "Andorra", flag: "ad" },
  { value: "Angola", text: "Angola", flag: "ao" },
  { value: "Anguilla", text: "Anguilla", flag: "ai" },
  { value: "Antigua", text: "Antigua", flag: "ag" },
  { value: "Argentina", text: "Argentina", flag: "ar" },
  { value: "Armenia", text: "Armenia", flag: "am" },
  { value: "Aruba", text: "Aruba", flag: "aw" },
  { value: "Australia", text: "Australia", flag: "au" },
  { value: "Austria", text: "Austria", flag: "at" },
  { value: "Azerbaijan", text: "Azerbaijan", flag: "az" },
  { value: "Bahamas", text: "Bahamas", flag: "bs" },
  { value: "Bahrain", text: "Bahrain", flag: "bh" },
  { value: "Bangladesh", text: "Bangladesh", flag: "bd" },
  { value: "Barbados", text: "Barbados", flag: "bb" },
  { value: "Belarus", text: "Belarus", flag: "by" },
  { value: "Belgium", text: "Belgium", flag: "be" },
  { value: "Belize", text: "Belize", flag: "bz" },
  { value: "Benin", text: "Benin", flag: "bj" },
  { value: "Bermuda", text: "Bermuda", flag: "bm" },
  { value: "Bhutan", text: "Bhutan", flag: "bt" },
  { value: "Bolivia", text: "Bolivia", flag: "bo" },
  { value: "Bosnia", text: "Bosnia", flag: "ba" },
  { value: "Botswana", text: "Botswana", flag: "bw" },
  { value: "Bouvet Island", text: "Bouvet Island", flag: "bv" },
  { value: "Brazil", text: "Brazil", flag: "br" },
  { value: "British Virgin Islands", text: "British Virgin Islands", flag: "vg" },
  { value: "Brunei", text: "Brunei", flag: "bn" },
  { value: "Bulgaria", text: "Bulgaria", flag: "bg" },
  { value: "Burkina Faso", text: "Burkina Faso", flag: "bf" },
  { value: "Burma", text: "Burma", flag: "mm" },
  { value: "Burundi", text: "Burundi", flag: "bi" },
  { value: "Caicos Islands", text: "Caicos Islands", flag: "tc" },
  { value: "Cambodia", text: "Cambodia", flag: "kh" },
  { value: "Cameroon", text: "Cameroon", flag: "cm" },
  { value: "Canada", text: "Canada", flag: "ca" },
  { value: "Cape Verde", text: "Cape Verde", flag: "cv" },
  { value: "Cayman Islands", text: "Cayman Islands", flag: "ky" },
  { value: "Central African Republic", text: "Central African Republic", flag: "cf" },
  { value: "Chad", text: "Chad", flag: "td" },
  { value: "Chile", text: "Chile", flag: "cl" },
  { value: "China", text: "China", flag: "cn" },
  { value: "Christmas Island", text: "Christmas Island", flag: "cx" },
  { value: "Cocos Islands", text: "Cocos Islands", flag: "cc" },
  { value: "Colombia", text: "Colombia", flag: "co" },
  { value: "Comoros", text: "Comoros", flag: "km" },
  { value: "Congo", text: "Congo", flag: "cd" },
  { value: "Congo Brazzaville", text: "Congo Brazzaville", flag: "cg" },
  { value: "Cook Islands", text: "Cook Islands", flag: "ck" },
  { value: "Costa Rica", text: "Costa Rica", flag: "cr" },
  { value: "Ivory Coast", text: "Ivory Coast", flag: "ci" },
  { value: "Croatia", text: "Croatia", flag: "hr" },
  { value: "Cuba", text: "Cuba", flag: "cu" },
  { value: "Cyprus", text: "Cyprus", flag: "cy" },
  { value: "Czech Republic", text: "Czech Republic", flag: "cz" },
  { value: "Denmark", text: "Denmark", flag: "dk" },
  { value: "Djibouti", text: "Djibouti", flag: "dj" },
  { value: "Dominica", text: "Dominica", flag: "dm" },
  { value: "Dominican Republic", text: "Dominican Republic", flag: "do" },
  { value: "Ecuador", text: "Ecuador", flag: "ec" },
  { value: "Egypt", text: "Egypt", flag: "eg" },
  { value: "El Salvador", text: "El Salvador", flag: "sv" },
  { value: "Equatorial Guinea", text: "Equatorial Guinea", flag: "gq" },
  { value: "Eritrea", text: "Eritrea", flag: "er" },
  { value: "Estonia", text: "Estonia", flag: "ee" },
  { value: "Ethiopia", text: "Ethiopia", flag: "et" },
  { value: "Europeanunion", text: "Europeanunion", flag: "eu" },
  { value: "Falkland Islands", text: "Falkland Islands", flag: "fk" },
  { value: "Faroe Islands", text: "Faroe Islands", flag: "fo" },
  { value: "Fiji", text: "Fiji", flag: "fj" },
  { value: "Finland", text: "Finland", flag: "fi" },
  { value: "France", text: "France", flag: "fr" },
  { value: "French Guiana", text: "French Guiana", flag: "gf" },
  { value: "French Polynesia", text: "French Polynesia", flag: "pf" },
  { value: "French Territories", text: "French Territories", flag: "tf" },
  { value: "Gabon", text: "Gabon", flag: "ga" },
  { value: "Gambia", text: "Gambia", flag: "gm" },
  { value: "Georgia", text: "Georgia", flag: "ge" },
  { value: "Germany", text: "Germany", flag: "de" },
  { value: "Ghana", text: "Ghana", flag: "gh" },
  { value: "Gibraltar", text: "Gibraltar", flag: "gi" },
  { value: "Greece", text: "Greece", flag: "gr" },
  { value: "Greenland", text: "Greenland", flag: "gl" },
  { value: "Grenada", text: "Grenada", flag: "gd" },
  { value: "Guadeloupe", text: "Guadeloupe", flag: "gp" },
  { value: "Guam", text: "Guam", flag: "gu" },
  { value: "Guatemala", text: "Guatemala", flag: "gt" },
  { value: "Guinea", text: "Guinea", flag: "gn" },
  { value: "Guinea-Bissau", text: "Guinea-Bissau", flag: "gw" },
  { value: "Guyana", text: "Guyana", flag: "gy" },
  { value: "Haiti", text: "Haiti", flag: "ht" },
  { value: "Heard Island", text: "Heard Island", flag: "hm" },
  { value: "Honduras", text: "Honduras", flag: "hn" },
  { value: "Hong Kong", text: "Hong Kong", flag: "hk" },
  { value: "Hungary", text: "Hungary", flag: "hu" },
  { value: "Iceland", text: "Iceland", flag: "is" },
  { value: "India", text: "India", flag: "in" },
  { value: "Indian Ocean Territory", text: "Indian Ocean Territory", flag: "io" },
  { value: "Indonesia", text: "Indonesia", flag: "id" },
  { value: "Iran", text: "Iran", flag: "ir" },
  { value: "Iraq", text: "Iraq", flag: "iq" },
  { value: "Ireland", text: "Ireland", flag: "ie" },
  { value: "Israel", text: "Israel", flag: "il" },
  { value: "Italy", text: "Italy", flag: "it" },
  { value: "Jamaica", text: "Jamaica", flag: "jm" },
  { value: "Jan Mayen", text: "Jan Mayen", flag: "sj" },
  { value: "Japan", text: "Japan", flag: "jp" },
  { value: "Jordan", text: "Jordan", flag: "jo" },
  { value: "Kazakhstan", text: "Kazakhstan", flag: "kz" },
  { value: "Kenya", text: "Kenya", flag: "ke" },
  { value: "Kiribati", text: "Kiribati", flag: "ki" },
  { value: "Kuwait", text: "Kuwait", flag: "kw" },
  { value: "Kyrgyzstan", text: "Kyrgyzstan", flag: "kg" },
  { value: "Laos", text: "Laos", flag: "la" },
  { value: "Latvia", text: "Latvia", flag: "lv" },
  { value: "Lebanon", text: "Lebanon", flag: "lb" },
  { value: "Lesotho", text: "Lesotho", flag: "ls" },
  { value: "Liberia", text: "Liberia", flag: "lr" },
  { value: "Libya", text: "Libya", flag: "ly" },
  { value: "Liechtenstein", text: "Liechtenstein", flag: "li" },
  { value: "Lithuania", text: "Lithuania", flag: "lt" },
  { value: "Luxembourg", text: "Luxembourg", flag: "lu" },
  { value: "Macau", text: "Macau", flag: "mo" },
  { value: "Macedonia", text: "Macedonia", flag: "mk" },
  { value: "Madagascar", text: "Madagascar", flag: "mg" },
  { value: "Malawi", text: "Malawi", flag: "mw" },
  { value: "Malaysia", text: "Malaysia", flag: "my" },
  { value: "Maldives", text: "Maldives", flag: "mv" },
  { value: "Mali", text: "Mali", flag: "ml" },
  { value: "Malta", text: "Malta", flag: "mt" },
  { value: "Marshall Islands", text: "Marshall Islands", flag: "mh" },
  { value: "Martinique", text: "Martinique", flag: "mq" },
  { value: "Mauritania", text: "Mauritania", flag: "mr" },
  { value: "Mauritius", text: "Mauritius", flag: "mu" },
  { value: "Mayotte", text: "Mayotte", flag: "yt" },
  { value: "Mexico", text: "Mexico", flag: "mx" },
  { value: "Micronesia", text: "Micronesia", flag: "fm" },
  { value: "Moldova", text: "Moldova", flag: "md" },
  { value: "Monaco", text: "Monaco", flag: "mc" },
  { value: "Mongolia", text: "Mongolia", flag: "mn" },
  { value: "Montenegro", text: "Montenegro", flag: "me" },
  { value: "Montserrat", text: "Montserrat", flag: "ms" },
  { value: "Morocco", text: "Morocco", flag: "ma" },
  { value: "Mozambique", text: "Mozambique", flag: "mz" },
  { value: "Namibia", text: "Namibia", flag: "na" },
  { value: "Nauru", text: "Nauru", flag: "nr" },
  { value: "Nepal", text: "Nepal", flag: "np" },
  { value: "Netherlands", text: "Netherlands", flag: "nl" },
  { value: "Netherlandsantilles", text: "Netherlandsantilles", flag: "an" },
  { value: "New Caledonia", text: "New Caledonia", flag: "nc" },
  { value: "New Guinea", text: "New Guinea", flag: "pg" },
  { value: "New Zealand", text: "New Zealand", flag: "nz" },
  { value: "Nicaragua", text: "Nicaragua", flag: "ni" },
  { value: "Niger", text: "Niger", flag: "ne" },
  { value: "Nigeria", text: "Nigeria", flag: "ng" },
  { value: "Niue", text: "Niue", flag: "nu" },
  { value: "Norfolk Island", text: "Norfolk Island", flag: "nf" },
  { value: "North Korea", text: "North Korea", flag: "kp" },
  { value: "Northern Mariana Islands", text: "Northern Mariana Islands", flag: "mp" },
  { value: "Norway", text: "Norway", flag: "no" },
  { value: "Oman", text: "Oman", flag: "om" },
  { value: "Pakistan", text: "Pakistan", flag: "pk" },
  { value: "Palau", text: "Palau", flag: "pw" },
  { value: "Palestine", text: "Palestine", flag: "ps" },
  { value: "Panama", text: "Panama", flag: "pa" },
  { value: "Paraguay", text: "Paraguay", flag: "py" },
  { value: "Peru", text: "Peru", flag: "pe" },
  { value: "Philippines", text: "Philippines", flag: "ph" },
  { value: "Pitcairn Islands", text: "Pitcairn Islands", flag: "pn" },
  { value: "Poland", text: "Poland", flag: "pl" },
  { value: "Portugal", text: "Portugal", flag: "pt" },
  { value: "Puerto Rico", text: "Puerto Rico", flag: "pr" },
  { value: "Qatar", text: "Qatar", flag: "qa" },
  { value: "Reunion", text: "Reunion", flag: "re" },
  { value: "Romania", text: "Romania", flag: "ro" },
  { value: "Russia", text: "Russia", flag: "ru" },
  { value: "Rwanda", text: "Rwanda", flag: "rw" },
  { value: "Saint Helena", text: "Saint Helena", flag: "sh" },
  { value: "Saint Kitts and Nevis", text: "Saint Kitts and Nevis", flag: "kn" },
  { value: "Saint Lucia", text: "Saint Lucia", flag: "lc" },
  { value: "Saint Pierre", text: "Saint Pierre", flag: "pm" },
  { value: "Saint Vincent", text: "Saint Vincent", flag: "vc" },
  { value: "Samoa", text: "Samoa", flag: "ws" },
  { value: "San Marino", text: "San Marino", flag: "sm" },
  { value: "Sandwich Islands", text: "Sandwich Islands", flag: "gs" },
  { value: "Sao Tome", text: "Sao Tome", flag: "st" },
  { value: "Saudi Arabia", text: "Saudi Arabia", flag: "sa" },
  { value: "Scotland", text: "Scotland", flag: "gb sct" },
  { value: "Senegal", text: "Senegal", flag: "sn" },
  { value: "Serbia", text: "Serbia", flag: "rs" },
  { value: "Seychelles", text: "Seychelles", flag: "sc" },
  { value: "Sierra Leone", text: "Sierra Leone", flag: "sl" },
  { value: "Singapore", text: "Singapore", flag: "sg" },
  { value: "Slovakia", text: "Slovakia", flag: "sk" },
  { value: "Slovenia", text: "Slovenia", flag: "si" },
  { value: "Solomon Islands", text: "Solomon Islands", flag: "sb" },
  { value: "Somalia", text: "Somalia", flag: "so" },
  { value: "South Africa", text: "South Africa", flag: "za" },
  { value: "South Korea", text: "South Korea", flag: "kr" },
  { value: "Spain", text: "Spain", flag: "es" },
  { value: "Sri Lanka", text: "Sri Lanka", flag: "lk" },
  { value: "Sudan", text: "Sudan", flag: "sd" },
  { value: "Suritext", text: "Suritext", flag: "sr" },
  { value: "Swaziland", text: "Swaziland", flag: "sz" },
  { value: "Sweden", text: "Sweden", flag: "se" },
  { value: "Switzerland", text: "Switzerland", flag: "ch" },
  { value: "Syria", text: "Syria", flag: "sy" },
  { value: "Taiwan", text: "Taiwan", flag: "tw" },
  { value: "Tajikistan", text: "Tajikistan", flag: "tj" },
  { value: "Tanzania", text: "Tanzania", flag: "tz" },
  { value: "Thailand", text: "Thailand", flag: "th" },
  { value: "Timorleste", text: "Timorleste", flag: "tl" },
  { value: "Togo", text: "Togo", flag: "tg" },
  { value: "Tokelau", text: "Tokelau", flag: "tk" },
  { value: "Tonga", text: "Tonga", flag: "to" },
  { value: "Trinidad", text: "Trinidad", flag: "tt" },
  { value: "Tunisia", text: "Tunisia", flag: "tn" },
  { value: "Turkey", text: "Turkey", flag: "tr" },
  { value: "Turkmenistan", text: "Turkmenistan", flag: "tm" },
  { value: "Tuvalu", text: "Tuvalu", flag: "tv" },
  { value: "UAE", text: "UAE", flag: "ae" },
  { value: "Uganda", text: "Uganda", flag: "ug" },
  { value: "Ukraine", text: "Ukraine", flag: "ua" },
  { value: "United Kingdom", text: "United Kingdom", flag: "gb" },
  { value: "United States", text: "United States", flag: "us" },
  { value: "Uruguay", text: "Uruguay", flag: "uy" },
  { value: "US Minor Islands", text: "US Minor Islands", flag: "um" },
  { value: "US Virgin Islands", text: "US Virgin Islands", flag: "vi" },
  { value: "Uzbekistan", text: "Uzbekistan", flag: "uz" },
  { value: "Vanuatu", text: "Vanuatu", flag: "vu" },
  { value: "Vatican City", text: "Vatican City", flag: "va" },
  { value: "Venezuela", text: "Venezuela", flag: "ve" },
  { value: "Vietnam", text: "Vietnam", flag: "vn" },
  { value: "Wales", text: "Wales", flag: "gb wls" },
  { value: "Wallis and Futuna", text: "Wallis and Futuna", flag: "wf" },
  { value: "Western Sahara", text: "Western Sahara", flag: "eh" },
  { value: "Yemen", text: "Yemen", flag: "ye" },
  { value: "Zambia", text: "Zambia", flag: "zm" },
  { value: "Zimbabwe", text: "Zimbabwe", flag: "zw" }
];

export default worldCountryList;
