import axios from "axios";

import stores from "../stores";
import config from "./config";
import { loadState, saveState } from "./localStorage";

const isUserLoggedIn = () => !!(loadState().user && loadState().user.loggedIn);
const baseRequest = {};
const baseUrl = config.getBasePublicUrl() + "api";

axios.defaults.baseURL = baseUrl;
baseRequest.addHeader = token => {
  let sessionToken = null;
  if (isUserLoggedIn()) {
    ({ sessionToken } = loadState().user);
  }
  axios.defaults.headers.common["Authorization"] = sessionToken || token;
};

baseRequest.setAcceptLanguage = language => {
  const lang = loadState().locale && loadState().locale.language;
  axios.defaults.headers.common["Accept-Language"] = lang || language;
};

baseRequest.request = (method, path, params, responseType) => {
  return axios({ method, url: path, data: params, responseType })
    .then(result => {
      if (result.data.message) {
        stores.Alert.setAlert({ body: result.data.message });
      } else {
        return result.data;
      }
    })
    .catch(err => {
      if (err.response && err.response.status === 401) {
        saveState({});
        window.location.reload();
      }
      return {};
    });
};

baseRequest.get = path => baseRequest.request("GET", path);
baseRequest.post = (path, params) => baseRequest.request("POST", path, params);
baseRequest.download = (path, params) => baseRequest.request("POST", path, params, "blob");

baseRequest.addHeader();
baseRequest.setAcceptLanguage();
export default baseRequest;
