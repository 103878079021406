import { useObserver } from "mobx-react";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { AuthenticationHandler } from "./AuthenticationHandler";
import { AuthorizationHandler } from "./AuthorizationHandler";
import CountryDetailPage from "./CountryDetailPage";
import HeaderWrapper from "./HeaderWrapper";
import LoginPage from "./LoginPage";
import MapPage from "./MapPage";
import NotFoundPage from "./NotFoundPage";
import UsersPage from "./UsersPage";

const Routes = () => {
  return useObserver(() => {
    return (
      <BrowserRouter>
        <HeaderWrapper>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return (
                  <AuthorizationHandler>
                    <MapPage />
                  </AuthorizationHandler>
                );
              }}
            />
            <Route
              path="/users"
              render={() => {
                return (
                  <AuthorizationHandler>
                    <UsersPage />
                  </AuthorizationHandler>
                );
              }}
            />
            <Route
              path="/login"
              render={() => {
                return (
                  <AuthenticationHandler>
                    <LoginPage />
                  </AuthenticationHandler>
                );
              }}
            />
            <Route
              path="/countryDetail/:country?"
              render={props => {
                return (
                  <AuthorizationHandler>
                    <CountryDetailPage country={props.match.params.country} />
                  </AuthorizationHandler>
                );
              }}
            />
            <Route
              path="*"
              render={() => {
                return <NotFoundPage></NotFoundPage>;
              }}
            />
          </Switch>
        </HeaderWrapper>
      </BrowserRouter>
    );
  });
};

export default Routes;
