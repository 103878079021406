import Alert from "./alert";
import Auth from "./auth";
import Country from "./country";
import Map from "./map";
import User from "./user";
const stores = {
  User: new User(),
  Map: new Map(),
  Auth: new Auth(),
  Alert: new Alert(),
  Country: new Country()
};

export default stores;
