import { Dropdown, Grid, Icon, Input, TextArea } from "semantic-ui-react";
import styled from "styled-components";

export const TabContainer = styled.div.attrs({ className: "new-input-container" })`
  padding: 12px 20px;
  margin-top: 20px;
  margin-bottom: 15px;
  height: fit-content;
  min-height: ${props => props.minheight || "unset"};
`;

export const Title = styled.h2`
  font-size: 17px;
  top: -11px !important;
  @media (max-width: 1900px) {
    font-size: ${props => props.size || 17}px;
  }

  @media (max-width: 1500px) {
    word-spacing: ${props => (props.size ? "-1px" : "unset")};
    max-width: 93%;
  }
`;
export const GridContainer = styled(Grid)`
  padding: ${props => props.padding || "unset"} !important;
`;

export const GridRow = styled(Grid.Row)`
  padding: ${props => props.padding || "0"} !important;
`;

export const GridColumn = styled(Grid.Column)`
  padding: ${props => props.padding || "0"} !important;
  width: ${props => (props.colwidth ? `${props.colwidth}% !important` : "unset")};
`;

export const Description = styled.div`
  height: ${props => props.height}px;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
`;

export const NoteForm = styled(TextArea)`
  font-size: 12px !important;
  padding: 5px !important;
  min-height: ${props => props.minheight}px;
  line-height: 19px !important;
`;

export const InputField = styled(Input).attrs({
  maxLength: 50,
  transparent: true,
  size: "small",
  autoComplete: "new-password"
})`
  width: 100%;
  border: 1px solid ${props => (props.clicked ? "green" : props.note ? "#e8e8e9" : "white")};
  border-radius: 4px;
  padding: 2px;
  opacity: 1 !important;
  cursor: text;
  &>input {
    pointer-events:unset !important;
    margin-left: ${props => (props.note ? "10px !important" : "unset")};
  }

  &:after {
    position: absolute;
    pointer-events: none;
    top:0;
    left: 3px;
    background: white;
    content: "${props => props.content || ""}";
  }
  
`;

export const DropDown = styled(Dropdown)`
  padding: 5px !important;
  line-height: unset;
  min-height: 0px;
`;

export const ChartIcon = styled(Icon)`
  float: right;
  margin: 5px 0 15px !important;
  cursor: pointer;
  color: rgb(58, 132, 73);
  z-index: 2;
`;

export const TotalContainer = styled.div`
  display: flex;
  margin: 10px 10px 0;
  font-size: 13px;
`;

export const TotalRight = styled.div.attrs({ className: "bold" })`
  margin-left: 5px;
`;

export const ChartContainer = styled.div`
  border: 1px solid gainsboro;
  margin-top: ${props => props.margintop || 15}px;
  border-radius: 4px;
`;

export const FlagContainer = styled.div`
  display: inline-flex;
  vertical-align: middle;
  margin-top: -1px;
`;
