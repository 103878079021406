import { action, flow, observable, toJS } from "mobx";

import baseRequest from "../_core/baseRequest";
import history from "../_core/history";

class Country {
  @observable currentCountry = null;
  @observable loading = false;
  @observable countryDataState = "idle";
  @observable editMode = false;
  @observable noteEditor = false;
  @observable macroEconomicEditor = false;

  @action get = object => {
    return toJS(object);
  };

  getCountryFlow = flow(function*(country) {
    this.loading = true;
    try {
      const result = yield baseRequest.post("/countryData", { country });
      if (result.data) {
        this.currentCountry = result.data;
        return true;
      } else {
        this.loading = false;
        history.push("/");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  });

  @action
  getCountry = country => {
    this.loading = true;
    return baseRequest.post("/countryData", { country }).then(result => {
      if (result.data) {
        this.currentCountry = result.data;
        return true;
      } else {
        this.loading = false;
        history.push("/");
        window.location.reload();
      }
    });
  };

  @action
  setCountryLoading = loading => {
    this.loading = loading;
  };

  updateCountry = flow(function*(countryObject) {
    this.currentCountry = countryObject;
    this.countryDataState = "pending";
    try {
      yield baseRequest.post("/countryDataUpdate", { countryObject });
      this.countryDataState = "done";
    } catch (error) {
      this.countryDataState = "err";
    }
  });

  @action
  setEditMode = () => {
    this.editMode = !this.editMode;
    this.noteEditor = false;
    this.macroEconomicEditor = false;
  };

  @action
  setNote = (name, value) => {
    this[name] = value;
  };
}

export default Country;
