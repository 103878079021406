import { useObserver } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Grid, Icon } from "semantic-ui-react";
import styled from "styled-components";

import { useStore } from "../_core/appContext";
import config from "../_core/config";
import africaCountryList from "../utility/constants/africaCountryList";
import { Documents, FastFacts, FilterMarket, VehicleParc } from "./CountryDetail";
import Loading from "./DefaultComponents/Loading";
import Tab from "./DefaultComponents/Tab";

const countrySet = new Set(africaCountryList.map(({ value }) => value));

const CountryDetailPage = ({ country }) => {
  const { Country, Auth } = useStore();
  const history = useHistory();
  const isAdmin = Auth.isAdmin();
  const [activeTab, setActiveTab] = useState(0);
  const [renderTabs, setRenderTabs] = useState(false);
  const [stop, setStop] = useState(null);
  const [open, setOpen] = useState(null);
  const animationTimeoutID = useRef(null);
  useEffect(() => {
    if (!countrySet.has(country)) history.push("/");
    else {
      Country.getCountryFlow(country);
      animationTimeoutID.current = setTimeout(() => {
        setRenderTabs(true);
        setStop(true);
      }, 3500);
    }

    return () => clearTimeout(animationTimeoutID.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedTab = tabId => {
    setActiveTab(tabId);
  };

  const renderCountryDetailTabs = () => {
    switch (activeTab) {
      case 0:
        return <FastFacts isAdmin={isAdmin} />;
      case 1:
        return <Documents />;
      case 2:
        return <FilterMarket isAdmin={isAdmin} />;
      case 3:
        return <VehicleParc isAdmin={isAdmin} />;
      default:
        return <FastFacts isAdmin={isAdmin} />;
    }
  };

  return useObserver(() => {
    if ((Country.loading || !Country.currentCountry) && !stop) {
      return (
        <Grid className="default-page-padding" centered style={{ marginTop: -64.5 }}>
          <Loading setLoading={Country.setCountryLoading}></Loading>
        </Grid>
      );
    }
    return (
      <Grid className="default-page-padding" centered style={{ marginTop: -30 }}>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width="15">
            <Grid
              style={{
                margin: "20px",
                borderRadius: 5,
                border: `1.5px solid ${renderTabs ? "#3a8449" : "white"}`,
                transition: "border 1s"
              }}
            >
              <Grid.Column width="4" style={{ paddingRight: 0 }}>
                <Grid
                  style={{
                    flexWrap: "nowrap",
                    opacity: renderTabs ? 1 : 0,
                    transition: "opacity 1s"
                  }}
                >
                  <Grid.Column style={{ minWidth: "fit-content", width: "140px", height: 94 }}>
                    <Img
                      src={
                        Country.currentCountry &&
                        Country.currentCountry.flagPhotoId &&
                        config.getBasePublicUrl() +
                          "api/imagefile/" +
                          Country.currentCountry.flagPhotoId
                      }
                    />
                  </Grid.Column>
                  <Grid.Column style={{ padding: 0, width: "100%" }} verticalAlign="middle">
                    <TitleContainer>
                      <CountryTitle
                        size={Country.currentCountry.country.length}
                        onClick={() => setOpen(true)}
                      >
                        {Country.currentCountry.country}
                        <Icon size="small" name="angle down" />
                      </CountryTitle>
                      {isAdmin && (
                        <EditButton editOn={Country.editMode} onClick={() => Country.setEditMode()}>
                          <EditIcon editOn={Country.editMode} name="edit" />
                          <span
                            style={{
                              fontSize: 13,
                              margin: "auto 0",
                              marginTop: 2
                            }}
                          >
                            Edit {Country.editMode ? "On" : "Off"}
                          </span>
                        </EditButton>
                      )}
                      {open && (
                        <Dropdown
                          style={{ position: "absolute", top: -2 }}
                          selectOnBlur={false}
                          search
                          selection
                          defaultOpen
                          closeOnChange
                          onClose={() => setOpen(false)}
                          onChange={(e, { value }) => {
                            setRenderTabs(false);
                            Country.getCountry(value).then(() => {
                              setOpen(false);
                              setActiveTab(0);
                              setRenderTabs(true);
                              history.push(`/countryDetail/${value}`);
                            });
                          }}
                          placeholder={Country.currentCountry && Country.currentCountry.country}
                          value={Country.currentCountry.country}
                          options={africaCountryList.sort((a, b) =>
                            a.text > b.text ? 1 : b.text > a.text ? -1 : 0
                          )}
                        />
                      )}
                    </TitleContainer>
                    {Country.currentCountry.managedBy && Country.currentCountry.subRegion && (
                      <List>
                        <ListItem>{Country.currentCountry.managedBy}</ListItem>
                        <ListItem>{Country.currentCountry.subRegion}</ListItem>
                      </List>
                    )}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column style={{ margin: "0 auto", width: "max-content" }}>
                <Tab selectedTab={activeTab} setSelectedTab={setSelectedTab} />
              </Grid.Column>
              <Grid.Column
                width="4"
                textAlign="right"
                style={{ opacity: renderTabs ? 1 : 0, transition: "opacity 1s" }}
              >
                <Img
                  map
                  src={
                    Country.currentCountry &&
                    Country.currentCountry.mapPhotoId &&
                    config.getBasePublicUrl() + "api/imagefile/" + Country.currentCountry.mapPhotoId
                  }
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ opacity: renderTabs ? 1 : 0, transition: "opacity 1s" }}>
          <Grid.Column width="15">{renderTabs && renderCountryDetailTabs()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  });
};

const List = styled.div`
  display: flex;
  margin-top: 5px;
`;

const ListItem = styled.div`
  margin-right: 10px;
  color: ${props => props.theme.MH_GRAY};
  font-size: 11px;
  &:before {
    content: "•";
    margin-right: 6px;
  }
`;

const Img = styled.img`
  max-width: 100%;
  height: 60px;
  border-radius: 4px;
  position: ${props => props.map && "absolute"};
  right: 15px;
  z-index: 3;
  &:hover {
    height: ${props => props.map && "400px"};
  }
  transition: height 0.4s;
`;

const CountryTitle = styled.div`
  font-size: ${props => (props.size > 7 ? 25 - props.size / 3 : 25)}px;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  @media (min-width: 1830px) {
    font-size: 25px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EditButton = styled.div`
  cursor: pointer;
  white-space: nowrap;
  color: ${props =>
    props.editOn ? "rgb(0, 130, 60) !important" : "rgba(74, 74, 74, 0.5) !important"};
`;

const EditIcon = styled(Icon)`
  font-size: 14px !important;
  margin-top: 0 !important;
  margin-left: 4px !important;
  color: ${props =>
    props.editOn ? "rgb(0, 130, 60) !important" : "rgba(74, 74, 74, 0.5) !important"};
  transform: ${props => `rotateY(${props.editOn ? 0 : 360}deg) !important`};
  transition: all 0.5s !important;
`;

export default CountryDetailPage;
