import React, { useRef } from "react";
import { useChain, useSpring, useSprings } from "react-spring";

import { LoadingBox } from "./Box";

const bgColors = ["rgb(30, 74, 44)", "rgb(42, 100, 59)", "rgb(39, 94, 56)", "rgb(53, 121, 71)"];

export default function Tab(props) {
  const rotateRef = useRef();
  const rotateSprings = useSprings(2, index => ({
    ref: rotateRef,
    from: { rotate: 0 },
    to: { rotate: index === 1 ? 180 : -180 },
    config: { duration: 700 }
  }));
  const [[r1, r2]] = rotateSprings;

  const widthRef = useRef();
  const widthSpring = useSpring({
    ref: widthRef,
    from: { width: 30, height: 30 },
    to: { width: 150, height: 40 },
    // to: { width: 150, height: 95 },
    config: { duration: 700 }
  });

  const backgroundRef = useRef();
  const [backgroundSprings] = useSprings(4, index => ({
    ref: backgroundRef,
    from: {
      backgroundColor: bgColors[index],
      border: "1px solid rgba(0,0,0,0)",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      color: "rgba(0, 0, 0, 0)"
    },
    to: {
      backgroundColor: index === 0 ? bgColors[0] : "white",
      border: "1px solid " + bgColors[0],
      borderTopLeftRadius: index === 1 ? 5 : 0,
      borderTopRightRadius: index === 0 ? 5 : 0,
      borderBottomLeftRadius: index === 1 ? 5 : 0,
      borderBottomRightRadius: index === 0 ? 5 : 0,
      color: index === 0 ? "white" : bgColors[0]
    },
    config: { duration: 700 }
  }));

  // const containerRef = useRef();
  // const containerSprings = useSpring({
  //   ref: containerRef,
  //   from: { marginTop: -50 },
  //   to: { marginTop: -110 },
  //   config: { duration: 700 }
  // });

  useChain([rotateRef, widthRef, backgroundRef]);
  return (
    <div
      style={{
        // marginTop: -110,
        // marginBottom: -14,
        marginTop: -30,
        display: "grid",
        gridTemplateColumns: "auto auto"
      }}
    >
      <LoadingBox
        onClick={() => props.setSelectedTab(0)}
        style={{
          cursor: "pointer",
          transform: r1.rotate.interpolate(t => `rotate(${t}deg)`),
          transformOrigin: "0 100%",
          ...widthSpring,
          ...backgroundSprings[0],
          color: props.selectedTab === 0 ? backgroundSprings[0].color : bgColors[0],

          backgroundColor:
            props.selectedTab === 0
              ? bgColors[0]
              : props.selectedTab !== null
              ? "white"
              : backgroundSprings[0].backgroundColor
        }}
      >
        <span style={{ transform: "rotate(180deg)" }}>Fast Facts</span>
      </LoadingBox>
      <LoadingBox
        onClick={() => props.setSelectedTab(1)}
        style={{
          cursor: "pointer",
          transform: r2.rotate.interpolate(t => `rotate(${t}deg)`),
          transformOrigin: "100% 100%",
          ...widthSpring,
          ...backgroundSprings[1],
          color: props.selectedTab === 1 ? "white" : backgroundSprings[1].color,
          backgroundColor:
            props.selectedTab === 1 ? bgColors[0] : backgroundSprings[1].backgroundColor
        }}
      >
        <span style={{ transform: "rotate(-180deg)" }}>Documents</span>
      </LoadingBox>
      <LoadingBox
        onClick={() => props.setSelectedTab(3)}
        style={{
          cursor: "pointer",
          ...widthSpring,
          ...backgroundSprings[3],
          color: props.selectedTab === 3 ? "white" : backgroundSprings[3].color,
          backgroundColor:
            props.selectedTab === 3 ? bgColors[0] : backgroundSprings[3].backgroundColor
        }}
      >
        <span>Vehicle Parc</span>
      </LoadingBox>
      <LoadingBox
        onClick={() => props.setSelectedTab(2)}
        style={{
          cursor: "pointer",
          ...widthSpring,
          ...backgroundSprings[2],
          color: props.selectedTab === 2 ? "white" : backgroundSprings[2].color,
          backgroundColor:
            props.selectedTab === 2 ? bgColors[0] : backgroundSprings[2].backgroundColor
        }}
      >
        <span>Filter Market</span>
      </LoadingBox>
    </div>
  );
}
