import btoa from "btoa";
import { action, observable } from "mobx";

import baseRequest from "../_core/baseRequest";
import { loadState, saveState } from "../_core/localStorage";

export default class Auth {
  @observable user = {};

  @action
  isUserLoggedIn = () => {
    const loggedIn = !!(loadState().user && loadState().user.loggedIn);
    if (loggedIn) {
      this.user = loadState().user;
    }
    return loggedIn;
  };

  @action
  isAdmin = () => !!(loadState().user && loadState().user.admin);

  @action
  login = params => {
    const token = btoa(params.username + ":" + params.password);
    baseRequest.addHeader("Basic " + token);
    return baseRequest.post("/login").then(result => {
      this.user = {
        loggedIn: true,
        ...result.data.user,
        sessionToken: result.data.sessionToken
      };
      saveState({ user: this.user });
      return result;
    });
  };

  @action
  logout = () => {
    return baseRequest.post("/logout").then(() => {
      this.user = {};
      saveState({});
    });
  };
}
