import draftToHtml from "draftjs-to-html";
import { useObserver } from "mobx-react";
import React, { useState } from "react";
import { Flag, Form, Table } from "semantic-ui-react";

import { useStore } from "../../_core/appContext";
import worldCountryList from "../../utility/constants/worldCountryList";
import ExplanationPopup from "../DefaultComponents/ExplanationPopup";
import TextEditor from "../DefaultComponents/TextEditor";
import Charts from "./Charts";
import {
  ChartContainer,
  ChartIcon,
  Description,
  DropDown,
  FlagContainer,
  GridColumn,
  GridContainer,
  GridRow,
  InputField,
  NoteForm,
  TabContainer,
  Title,
  TotalContainer,
  TotalRight
} from "./CountryDetailStyles";
const filterBrans = {
  mannFilter: "MANN FILTER",
  filtron: "FILTRON",
  wix: "WIX"
};

const FilterMarketTab = ({ isAdmin }) => {
  const { Country } = useStore();
  const [state, setState] = useState({
    airFilterChart: true,
    oilFilterChart: true
  });

  const onSendHandler = (event, index, mainKey, subKey, number = false) => {
    const newCountry = { ...Country.currentCountry };
    const { id, value } = event.target;
    const maxLength =
      id === "notesForMarketDistribution" ? null : id === "notesForCarParc" ? 300 : 50;
    if (maxLength && value && value.length > maxLength) {
      return;
    }
    let update = false;

    if (subKey) {
      if (index === "none") {
        update = newCountry[mainKey][subKey] !== (number ? +value : value);
        newCountry[mainKey][subKey] = number ? +value : value;
      } else {
        update = newCountry[mainKey].data[index][subKey] !== (number ? +value : value);
        newCountry[mainKey].data[index][subKey] = number ? +value : value;
      }
    } else if (index === "total") {
      update = newCountry[mainKey].total !== +value;
      newCountry[mainKey].total = +value;
    } else {
      update = newCountry[id] !== value;
      newCountry[id] = number ? +value : value;
    }

    if (
      mainKey === "airFilterImportData" ||
      mainKey === "oilPetrolFilterImportData" ||
      mainKey === "localFilterProduction" ||
      mainKey === "mhFirstTierSales"
    ) {
      const { mannFilter, filtron, wix } = newCountry.mhFirstTierSales;
      const { localAirFilterUsage, localOilPetrolFilterUsage } = newCountry.localFilterProduction;
      const divide =
        (parseFloat(newCountry.airFilterImportData.total) +
          parseFloat(newCountry.oilPetrolFilterImportData.total) +
          parseFloat(localAirFilterUsage) +
          parseFloat(localOilPetrolFilterUsage)) *
        0.5;
      const mhMarketShare =
        (parseFloat(mannFilter) + parseFloat(filtron) + parseFloat(wix)) / divide;
      newCountry.mhMarketShare = mhMarketShare;
    }

    if (update) Country.updateCountry(newCountry);
    setState({ ...state, [id]: null, borderClick: false });
  };

  const getFlagName = name => {
    const found = worldCountryList.find(country => country.text === name);
    if (found) return found.flag;
  };

  return useObserver(() => {
    const { airFilterChart, oilFilterChart } = state;
    return (
      <React.Fragment>
        <GridContainer padding="0 20px">
          <GridColumn width="6" padding="0 10px 0 0">
            <GridRow>
              <TabContainer minheight="310px">
                <Title>Air Filter Import Data</Title>
                <ExplanationPopup field="airFilterImport"></ExplanationPopup>
                <ChartIcon
                  onClick={() => setState({ ...state, airFilterChart: !airFilterChart })}
                  name={airFilterChart ? "list" : "chart bar"}
                />
                {airFilterChart ? (
                  <ChartContainer>
                    <TotalContainer>
                      Total:
                      <TotalRight>
                        {Country.currentCountry.airFilterImportData.total} Mil. USD
                      </TotalRight>
                    </TotalContainer>
                    <Charts
                      height="200px"
                      categoryKey="country"
                      dataKey="percentage"
                      graphData={Country.currentCountry.airFilterImportData.data.filter(
                        data => data.country
                      )}
                    />
                  </ChartContainer>
                ) : (
                  <Table celled size="small" fixed>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell className="bold" width="8">
                          Total (2017) (Mil. USD)
                        </Table.Cell>
                        <Table.Cell>
                          <InputField
                            id="airFilterImportTotal"
                            defaultValue={Country.currentCountry.airFilterImportData.total}
                            disabled={!isAdmin || !Country.editMode}
                            type="number"
                            clicked={state.airFilterImportTotal}
                            onBlur={event => onSendHandler(event, "total", "airFilterImportData")}
                            onClick={() => {
                              setState({
                                ...state,
                                airFilterImportTotal: true
                              });
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {Country.currentCountry.airFilterImportData.data.map((data, index) => {
                        if (!data.country && !data.percentage && !Country.editMode) {
                          return null;
                        }
                        return (
                          <Table.Row key={`airFilterImport${index}`}>
                            <Table.Cell width="8" style={{ overflow: "visible" }}>
                              {Country.editMode && isAdmin ? (
                                <DropDown
                                  compact
                                  selectOnBlur={false}
                                  search
                                  placeholder="Select Country"
                                  options={worldCountryList}
                                  defaultValue={data.country}
                                  selection
                                  style={{
                                    padding: 5,
                                    lineHeight: "unset",
                                    minHeight: 0,
                                    width: "100%"
                                  }}
                                  onChange={(event, { value }) => {
                                    event.target.value = value;
                                    onSendHandler(event, index, "airFilterImportData", "country");
                                  }}
                                ></DropDown>
                              ) : (
                                <div className="bold">
                                  {data.country && (
                                    <FlagContainer>
                                      <Flag name={getFlagName(data.country)} />
                                    </FlagContainer>
                                  )}
                                  {data.country}
                                </div>
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <InputField
                                defaultValue={data.percentage}
                                disabled={!isAdmin || !Country.editMode}
                                id={`airFilterPercentage${index}`}
                                clicked={state[`airFilterPercentage${index}`]}
                                onBlur={event =>
                                  onSendHandler(
                                    event,
                                    index,
                                    "airFilterImportData",
                                    "percentage",
                                    true
                                  )
                                }
                                type="number"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    [`airFilterPercentage${index}`]: true
                                  });
                                }}
                                content={
                                  state[`airFilterPercentage${index}`] ? "" : data.percentage + "%"
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </TabContainer>
            </GridRow>
            <GridRow>
              <TabContainer minheight="310px">
                <Title>M+H 1st Tier Sales</Title>
                <ExplanationPopup field="walletShare"></ExplanationPopup>
                <Table celled size="small" fixed>
                  <Table.Body>
                    {Object.keys(filterBrans).map(data => (
                      <Table.Row key={data}>
                        <Table.Cell className="bold">{`${filterBrans[data]} (Mil. USD)`}</Table.Cell>
                        <Table.Cell width="4">
                          <InputField
                            defaultValue={Country.currentCountry.mhFirstTierSales[data]}
                            disabled={!isAdmin || !Country.editMode}
                            id={data}
                            clicked={state[data]}
                            type="number"
                            onBlur={event =>
                              onSendHandler(event, "none", "mhFirstTierSales", data, true)
                            }
                            onClick={() => {
                              setState({
                                ...state,
                                [data]: true
                              });
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                    <Table.Row>
                      <Table.Cell className="bold" width="8">
                        Estimated M+H Market Share(IAM)
                      </Table.Cell>
                      <Table.Cell>
                        {Country.currentCountry.mhMarketShare &&
                          (Country.currentCountry.mhMarketShare * 100).toFixed(2) + "%"}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridRow>
          </GridColumn>
          <GridColumn
            width="6"
            padding="0 10px"
            style={{
              borderRight: "1px solid rgb(110,208,77)",
              borderLeft: "1px solid rgb(110,208,77)"
            }}
          >
            <GridRow>
              <TabContainer minheight="310px">
                <Title>Oil & Petrol Filter Import Data</Title>
                <ExplanationPopup field="oilFilterImport"></ExplanationPopup>
                <ChartIcon
                  onClick={() => {
                    setState({
                      ...state,
                      oilFilterChart: !oilFilterChart
                    });
                  }}
                  name={oilFilterChart ? "list" : "chart bar"}
                />
                {oilFilterChart ? (
                  <ChartContainer>
                    <TotalContainer>
                      Total:
                      <TotalRight>
                        {Country.currentCountry.oilPetrolFilterImportData.total} Mil. USD
                      </TotalRight>
                    </TotalContainer>
                    <Charts
                      height="200px"
                      categoryKey="country"
                      dataKey="percentage"
                      graphData={Country.currentCountry.oilPetrolFilterImportData.data.filter(
                        data => data.country
                      )}
                    />
                  </ChartContainer>
                ) : (
                  <Table celled size="small" fixed>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell className="bold" width="8">
                          Total (2017) (Mil. USD)
                        </Table.Cell>
                        <Table.Cell>
                          <InputField
                            defaultValue={Country.currentCountry.oilPetrolFilterImportData.total}
                            disabled={!isAdmin || !Country.editMode}
                            id="oilPetrolTotal"
                            type="number"
                            clicked={state.oilPetrolTotal}
                            onBlur={event =>
                              onSendHandler(event, "total", "oilPetrolFilterImportData")
                            }
                            onClick={() => {
                              setState({
                                ...state,
                                oilPetrolTotal: true
                              });
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {Country.currentCountry.oilPetrolFilterImportData.data.map((data, index) => {
                        if (!data.country && !data.percentage && !Country.editMode) {
                          return null;
                        }
                        return (
                          <Table.Row key={`oilFilterImport${index}`}>
                            <Table.Cell width="8" style={{ overflow: "visible" }}>
                              {Country.editMode && isAdmin ? (
                                <DropDown
                                  compact
                                  selectOnBlur={false}
                                  search
                                  placeholder="Select Country"
                                  options={worldCountryList}
                                  defaultValue={data.country}
                                  selection
                                  style={{
                                    padding: 5,
                                    lineHeight: "unset",
                                    minHeight: 0,
                                    width: "100%"
                                  }}
                                  onChange={(event, { value }) => {
                                    event.target.value = value;
                                    onSendHandler(
                                      event,
                                      index,
                                      "oilPetrolFilterImportData",
                                      "country"
                                    );
                                  }}
                                ></DropDown>
                              ) : (
                                <div className="bold">
                                  {data.country && (
                                    <FlagContainer>
                                      <Flag name={getFlagName(data.country)} />
                                    </FlagContainer>
                                  )}
                                  {data.country}
                                </div>
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <InputField
                                defaultValue={data.percentage}
                                size="small"
                                type="number"
                                disabled={!isAdmin || !Country.editMode}
                                clicked={state[`oilFilterPercentage${index}`]}
                                onBlur={event =>
                                  onSendHandler(
                                    event,
                                    index,
                                    "oilPetrolFilterImportData",
                                    "percentage",
                                    true
                                  )
                                }
                                onClick={() => {
                                  setState({
                                    ...state,
                                    [`oilFilterPercentage${index}`]: true
                                  });
                                }}
                                id={`oilFilterPercentage${index}`}
                                content={
                                  state[`oilFilterPercentage${index}`] ? "" : data.percentage + "%"
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </TabContainer>
            </GridRow>
            <GridRow>
              <TabContainer minheight="310px">
                <Title>Local Filter Production</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="bold">Any Plant</Table.Cell>
                      <Table.Cell
                        width="4"
                        style={{
                          overflow: "visible"
                        }}
                      >
                        {Country.editMode && isAdmin ? (
                          <DropDown
                            compact
                            selectOnBlur={false}
                            search
                            options={[
                              { value: true, text: "Yes" },
                              { value: false, text: "No" }
                            ]}
                            defaultValue={Country.currentCountry.localFilterProduction.anyPlant}
                            selection
                            style={{
                              padding: 5,
                              lineHeight: "unset",
                              minHeight: 0,
                              width: "100%"
                            }}
                            onChange={(event, { value }) => {
                              event.target.value = value;
                              onSendHandler(event, "none", "localFilterProduction", "anyPlant");
                            }}
                          ></DropDown>
                        ) : (
                          <div>
                            {Country.currentCountry.localFilterProduction.anyPlant ? "Yes" : "No"}
                          </div>
                        )}
                      </Table.Cell>
                    </Table.Row>
                    {Country.currentCountry.localFilterProduction &&
                      ["localAirFilterUsage", "localOilPetrolFilterUsage"].map(data => (
                        <Table.Row key="data">
                          <Table.Cell className="bold">
                            {data === "localAirFilterUsage"
                              ? "Estimated Local Air Filter Usage (Mil. USD)"
                              : "Estimated Local Oil & Petrol Filter Usage (Mil. USD)"}
                          </Table.Cell>
                          <Table.Cell width="4">
                            <InputField
                              defaultValue={Country.currentCountry.localFilterProduction[data]}
                              disabled={!isAdmin || !Country.editMode}
                              type="number"
                              clicked={state[data]}
                              onBlur={event =>
                                onSendHandler(event, "none", "localFilterProduction", data, true)
                              }
                              onClick={() => {
                                setState({
                                  ...state,
                                  [data]: true
                                });
                              }}
                              id={data}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
                <div style={{ display: "flex" }}>
                  <p className="bold" style={{ marginRight: 5 }}>
                    Notes:
                  </p>
                  <div style={{ width: "100%" }}>
                    <Form>
                      <NoteForm
                        label="notes"
                        id="notesForCarParc"
                        disabled={!isAdmin || !Country.editMode}
                        defaultValue={
                          Country.currentCountry.localFilterProduction &&
                          Country.currentCountry.localFilterProduction.notes
                        }
                        maxLength={300}
                        onBlur={event =>
                          onSendHandler(event, "none", "localFilterProduction", "notes")
                        }
                      />
                    </Form>
                  </div>
                </div>
              </TabContainer>
            </GridRow>
          </GridColumn>
          <GridColumn width="4" padding="0 0 0 10px">
            <GridRow>
              <TabContainer minheight="640px">
                <Title size="15">M+H Sales Focus Areas & Distribution</Title>
                <Table celled size="small" fixed>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Objectives & Focus Areas</Table.HeaderCell>
                      <Table.HeaderCell width="4">Expected T/O (mil. USD)</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {Country.currentCountry.mhSalesFocusAreas.data.map((data, index) => (
                      <Table.Row key={"mhSales" + index}>
                        <Table.Cell>
                          <InputField
                            defaultValue={data.objective}
                            disabled={!Country.editMode}
                            clicked={state[`mhSalesFocusObjective${index}`]}
                            onBlur={event =>
                              onSendHandler(event, index, "mhSalesFocusAreas", "objective")
                            }
                            onClick={() => {
                              setState({
                                ...state,
                                [`mhSalesFocusObjective${index}`]: true
                              });
                            }}
                            id={`mhSalesFocusObjective${index}`}
                            className="bold"
                            content={state[`mhSalesFocusObjective${index}`] ? "" : data.objective}
                          />
                        </Table.Cell>
                        <Table.Cell width="4">
                          <InputField
                            defaultValue={data.value}
                            disabled={!Country.editMode}
                            clicked={state[`mhSalesFocusValue${index}`]}
                            onBlur={event =>
                              onSendHandler(event, index, "mhSalesFocusAreas", "value")
                            }
                            onClick={() => {
                              setState({
                                ...state,
                                [`mhSalesFocusValue${index}`]: true
                              });
                            }}
                            id={`mhSalesFocusValue${index}`}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <h4 style={{ color: "rgb(58, 132, 73)" }}>
                  Notes for Market & Distribution - M+H Team
                </h4>
                <Table>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell style={{ overflow: "visible" }}>
                        {Country.editMode && Country.noteEditor ? (
                          <TextEditor
                            id="notesForMarketDistribution"
                            textObject={Country.get(
                              Country.currentCountry.notesForMarketDistribution
                            )}
                            send={event => {
                              Country.setNote("noteEditor", false);
                              onSendHandler(event);
                            }}
                          ></TextEditor>
                        ) : (
                          <Description
                            height={193}
                            onClick={() => {
                              Country.setNote("noteEditor", true);
                            }}
                            dangerouslySetInnerHTML={{
                              __html: Country.currentCountry.notesForMarketDistribution.richText
                                ? draftToHtml(
                                    Country.currentCountry.notesForMarketDistribution.richText
                                  )
                                : Country.currentCountry.notesForMarketDistribution.plainText
                            }}
                          ></Description>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridRow>
          </GridColumn>
        </GridContainer>
      </React.Fragment>
    );
  });
};

export default FilterMarketTab;
