import React from "react";
import { Button, Header, Modal } from "semantic-ui-react";

const WarningModal = ({ open, head, body, parentFunc, onCancelHandler }) => {
  return (
    <Modal open={open} size="small">
      <Header content={head} />
      <Modal.Content>
        <p>{body}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={parentFunc}>
          Delete
        </Button>
        <Button basic color="grey" onClick={onCancelHandler}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WarningModal;
