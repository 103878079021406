import React from "react";
import { useHistory } from "react-router-dom";

import { useStore } from "../_core/appContext.js";

const AuthorizationHandler = props => {
  const history = useHistory();
  const { Auth } = useStore();
  if (!Auth.isUserLoggedIn()) {
    history.push("/login");
    return null;
  } else return <React.Fragment>{props.children}</React.Fragment>;
};

export { AuthorizationHandler };
