import { useObserver } from "mobx-react";
import React, { useContext } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { ThemeContext } from "styled-components";

import { useStore } from "../../_core/appContext";
import { ButtonGreen, ButtonOrange } from "./Button";

const AlertViewFunction = () => {
  const { colors } = useContext(ThemeContext);
  const { Alert } = useStore();
  const close = () => {
    Alert.close();
  };

  return useObserver(() => {
    return (
      <Modal size="tiny" open={Alert.showAlert} onClose={() => close()}>
        <Modal.Header>{Alert.title === "Error" ? "Error" : Alert.title}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {Alert.actionFunc && (
              <Icon style={{ color: colors.MH_GREEN }} size="huge" name="check circle outline" />
            )}
            {Alert.body}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => close()}>Close</Button>
          {Alert.actionFunc && (
            <ButtonOrange
              onClick={() => {
                close();
                Alert.actionFunc();
              }}
            >
              {Alert.actionTitle}
            </ButtonOrange>
          )}
          {Alert.actionFunc2 && (
            <ButtonGreen
              onClick={() => {
                close();
                Alert.actionFunc2();
              }}
            >
              {Alert.actionTitle2}
            </ButtonGreen>
          )}
        </Modal.Actions>
      </Modal>
    );
  });
};

export default AlertViewFunction;
