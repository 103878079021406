import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ContentState, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Button } from "semantic-ui-react";

const TextEditor = ({ textObject, send, id }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    let rawContent;
    if (textObject.richText) {
      rawContent = convertFromRaw(textObject.richText);
    } else {
      rawContent = ContentState.createFromText(textObject.plainText);
    }
    setEditorState(EditorState.createWithContent(rawContent));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBeforeInput = () => {
    if (editorState.getCurrentContent().getPlainText(" ").length >= 4000) {
      return "handled";
    }
  };

  return (
    <React.Fragment>
      <Editor
        spellcheck
        editorStyle={{ display: "flex", width: "100%", height: "inherit" }}
        editorState={editorState}
        onEditorStateChange={editorState => {
          setEditorState(editorState);
        }}
        handleBeforeInput={handleBeforeInput}
        toolbar={{
          options: ["inline", "colorPicker"],
          inline: {
            options: ["bold", "italic", "underline"]
          },
          colorPicker: {
            popupClassName: "editor-color-picker"
          }
        }}
        toolbarStyle={{ justifyContent: "center" }}
      />
      <Button
        floated="right"
        style={{ marginTop: 8 }}
        onClick={() => {
          const richText = convertToRaw(editorState.getCurrentContent());
          const plainText = editorState.getCurrentContent().getPlainText(" ");
          send({
            target: { value: { richText, plainText }, id }
          });
        }}
      >
        Send
      </Button>
    </React.Fragment>
  );
};

export default TextEditor;
