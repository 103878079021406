import { useObserver } from "mobx-react";
import React, { useEffect } from "react";
import { Grid } from "semantic-ui-react";
import styled from "styled-components";

import { useStore } from "../_core/appContext";
import Loading from "./DefaultComponents/Loading";
import MapThing from "./MapPage/MapSvg";

const LegendBox = styled.div`
  border-radius: 3px;
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-bottom: 2px;
  cursor: pointer;
  margin-left: 10px;
  background-size: contain;
  background-color: ${props => props.color};
  background-image: url(${props => props.image});
`;

const GreenLine = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(0, 130, 60);
`;

const mapDropdownOptions = [
  { id: 0, text: "Country Population", value: "population" },
  { id: 1, text: "Country GDP", value: "gdp" },
  { id: 2, text: "Filter Import Volume", value: "filterImportValue" },
  { id: 3, text: "Vehicle Import Volume", value: "vehicleImportValue" },
  { id: 4, text: "M+H Footprint", value: "mhFootprint" },
  { id: 5, text: "M+H Market Share", value: "mhMarketShare" },
  { id: 6, text: "Car Import Country", value: "carImportCountry" },
  { id: 7, text: "Truck Import Country", value: "truckImportCountry" },
  { id: 8, text: "Motorization Rate", value: "motorizationRate" }
];

const MapPage = props => {
  const { Map } = useStore();
  useEffect(() => {
    Map.getMapReports();
    return () => Map.initMapState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScreenClick = e => {
    let parent = e.target;
    let regionOrInfo = false;
    while (parent) {
      if (parent.id === "info" || parent.id === "region" || parent.id === "legend") {
        regionOrInfo = true;
      }
      parent = parent.parentNode;
    }
    if (!regionOrInfo) {
      Map.setLegendOption(null);
    }
  };

  const onKeyDown = e => {
    if (e.key === "Escape") {
      Map.setLegendOption(null);
    }
  };

  return useObserver(() => {
    if (Map.mapState === "pending" || Map.mapLoading) {
      return (
        <React.Fragment>
          <Grid stackable>
            <Grid.Row style={{ height: "100%", justifyContent: "center", marginTop: "-30px" }}>
              <Loading setLoading={Map.setMapLoading}></Loading>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      );
    } else
      return (
        <div
          style={{
            width: "100%",
            height: "calc( 100vh - 72px)",
            background: "linear-gradient(90deg,#fafafa,#fff 50%,#fafafa)",
            outline: "none"
          }}
          onClick={e => onScreenClick(e)}
          onKeyDown={e => onKeyDown(e)}
          tabIndex="0"
        >
          <Grid stackable style={{ margin: 0, height: "inherit" }}>
            <OptionList id="legend" edge={window.navigator.userAgent.indexOf("Edge") !== -1}>
              {Map.mapOption !== "carImportCountry" &&
                Map.mapOption !== "truckImportCountry" &&
                Map.legendOptionList.map((item, index) => {
                  return (
                    <div
                      key={`legendBox${item.min + item.text}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 7,
                        height: 40,
                        margin: "auto 0"
                      }}
                      onClick={() =>
                        Map.mapOption !== "mhFootprint" &&
                        Map.setLegendOption(Map.legendOption === index ? null : index)
                      }
                    >
                      <LegendBox
                        className="box"
                        color={
                          item.color && (Map.legendOption === index || Map.legendOption === null)
                            ? item.color
                            : "#e3e3e3"
                        }
                      />
                      <span
                        style={{
                          marginLeft: 5,
                          fontSize: 12,
                          fontWeight: "bold",
                          cursor: "pointer"
                        }}
                      >
                        {item.text}
                      </span>
                    </div>
                  );
                })}
            </OptionList>
            <Grid.Column
              width={12}
              style={{ marginLeft: -62, display: "flex", alignItems: "center" }}
            >
              <MapThing filters={props.filters} mapReport={Map.mapReport} />
            </Grid.Column>
            <Grid.Column
              width={3}
              style={{
                margin: "auto 0"
              }}
            >
              <GreenLine />
              {mapDropdownOptions.map((option, index) => {
                return (
                  <div
                    key={option.text + "mapOption"}
                    style={{
                      display: "flex",
                      borderBottom:
                        index !== mapDropdownOptions.length - 1
                          ? "0.5px solid rgba(0,0,0,0.4)"
                          : "",
                      backgroundColor:
                        Map.mapOption === option.value ? "rgba(197, 197, 197, 0.1)" : "",
                      padding: 10,
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "ponter",
                      fontWeight: Map.mapOption === option.value ? "bold" : ""
                    }}
                    onClick={() => {
                      Map.setMapOption(option.value);
                    }}
                  >
                    <div></div>
                    <span style={{ alignSelf: "center", cursor: "pointer" }}>{option.text}</span>
                  </div>
                );
              })}
              <GreenLine />
            </Grid.Column>
          </Grid>
        </div>
      );
  });
};

const OptionList = styled.div`
  position: absolute;
  justify-content: center;
  height: calc((100vh - 748px) / 2);
  animation: 1s linear 0s 1 normal none running opacition;
  display: flex;
  width: 100%;
  z-index: 4;
  margin-top: ${props => (props.edge ? "10px" : "unset")};
  @media (max-width: 1500px) {
    height: calc((100vh - 624px) / 2);
  }
`;

export default MapPage;
