import styled from "styled-components";

export const VisitTooltip = styled.div.attrs(props => ({
  style: {
    background: props.isOutletMap ? "#226b80" : "rgb(58, 132, 73)",
    top: props.top,
    left: props.left
  }
}))`
  position: fixed;
  color: #fff;
  padding: 8px 16px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  z-index: 1000;
`;
