const cityAttributes = [
  { id: "benin", cityName: "Benin", widthI: 50, heightI: 50 },
  {
    id: "burkina faso",
    cityName: "Burkina Faso",
    widthI: 50,
    heightI: 50
  },
  {
    id: "ivory coast",
    cityName: "Ivory Coast",
    widthI: 50,
    heightI: 50
  },
  { id: "gambia", cityName: "Gambia", widthI: 50, heightI: 50 },
  { id: "cameroon", cityName: "Cameroon", widthI: 50, heightI: 50 },
  { id: "guinea", cityName: "Guinea", widthI: 50, heightI: 50 },
  {
    id: "guinea-bissau",
    cityName: "Guinea-Bissau",
    widthI: 50,
    heightI: 50
  },
  { id: "liberia", cityName: "Liberia", widthI: 50, heightI: 50 },
  { id: "mali", cityName: "Mali", widthI: 50, heightI: 50 },
  { id: "mauritania", cityName: "Mauritania", widthI: 50, heightI: 50 },
  { id: "niger", cityName: "Niger", widthI: 50, heightI: 50 },
  { id: "senegal", cityName: "Senegal", widthI: 50, heightI: 50 },
  {
    id: "sierra leone",
    cityName: "Sierra Leone",
    widthI: 50,
    heightI: 50
  },
  { id: "togo", cityName: "Togo", widthI: 50, heightI: 50 },
  { id: "algeria", cityName: "Algeria", widthI: 50, heightI: 50 },
  { id: "libya", cityName: "Libya", widthI: 50, heightI: 50 },
  { id: "morocco", cityName: "Morocco", widthI: 50, heightI: 50 },
  {
    id: "equatorial guinea",
    cityName: "Equatorial Guinea",
    widthI: 50,
    heightI: 50
  },
  { id: "uganda", cityName: "Uganda", widthI: 50, heightI: 50 },
  { id: "egypt", cityName: "Egypt", widthI: 50, heightI: 50 },
  { id: "nigeria", cityName: "Nigeria", widthI: 50, heightI: 50 },
  { id: "sudan", cityName: "Sudan", widthI: 50, heightI: 50 },
  {
    id: "south sudan",
    cityName: "South Sudan",
    widthI: 50,
    heightI: 50
  },
  { id: "ethiopia", cityName: "Ethiopia", widthI: 50, heightI: 50 },
  { id: "somalia", cityName: "Somalia", widthI: 50, heightI: 50 },
  {
    id: "western sahara",
    cityName: "Western Sahara",
    widthI: 50,
    heightI: 50
  },
  {
    id: "democratic republic of the congo",
    cityName: "Democratic Republic of the Congo",
    widthI: 50,
    heightI: 50
  },
  { id: "gabon", cityName: "Gabon", widthI: 50, heightI: 50 },
  {
    id: "south africa",
    cityName: "South Africa",
    widthI: 50,
    heightI: 50
  },
  { id: "zambia", cityName: "Zambia", widthI: 50, heightI: 50 },
  { id: "zimbabwe", cityName: "Zimbabwe", widthI: 50, heightI: 50 },
  { id: "angola", cityName: "Angola", widthI: 50, heightI: 50 },
  { id: "namibia", cityName: "Namibia", widthI: 50, heightI: 50 },
  { id: "botswana", cityName: "Botswana", widthI: 50, heightI: 50 },
  { id: "mozambique", cityName: "Mozambique", widthI: 50, heightI: 50 },
  { id: "malawi", cityName: "Malawi", widthI: 50, heightI: 50 },
  { id: "madagascar", cityName: "Madagascar", widthI: 50, heightI: 50 },
  { id: "tanzania", cityName: "Tanzania", widthI: 50, heightI: 50 },
  {
    id: "republic of the congo",
    cityName: "Republic of the Congo",
    widthI: 50,
    heightI: 50
  },
  { id: "ghana", cityName: "Ghana", widthI: 50, heightI: 50 },
  { id: "chad", cityName: "Chad", widthI: 50, heightI: 50 },
  {
    id: "central african republic",
    cityName: "Central African Republic",
    widthI: 50,
    heightI: 50
  },
  { id: "burundi", cityName: "Burundi", widthI: 50, heightI: 50 },
  { id: "eritrea", cityName: "Eritrea", widthI: 50, heightI: 50 },
  { id: "kenya", cityName: "Kenya", widthI: 50, heightI: 50 },
  { id: "rwanda", cityName: "Rwanda", widthI: 50, heightI: 50 },
  { id: "tunisia", cityName: "Tunisia", widthI: 50, heightI: 50 }
];

export default cityAttributes;
