import React from "react";

import AlertView from "./DefaultComponents/AlertView";
import Header from "./DefaultComponents/Header";

const HeaderWrapper = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      {children}
      <AlertView />
    </React.Fragment>
  );
};

export default HeaderWrapper;
