import React from "react";
import { ThemeProvider } from "styled-components";

import colors from "../src/styles/colors";
import { StoreProvider } from "./_core/appContext";
import Routes from "./components/Routes";
const theme = { colors };
function App() {
  return (
    <ThemeProvider theme={theme}>
      <StoreProvider>
        <Routes />
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
