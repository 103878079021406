import draftToHtml from "draftjs-to-html";
import { useObserver } from "mobx-react";
import React, { useState } from "react";
import { Flag, Icon, Table } from "semantic-ui-react";

import { useStore } from "../../_core/appContext";
import worldCountryList from "../../utility/constants/worldCountryList";
import ExplanationPopup from "../DefaultComponents/ExplanationPopup";
import TextEditor from "../DefaultComponents/TextEditor";
import Charts from "./Charts";
import {
  ChartContainer,
  ChartIcon,
  Description,
  DropDown,
  FlagContainer,
  GridColumn,
  GridContainer,
  GridRow,
  InputField,
  TabContainer,
  Title,
  TotalContainer,
  TotalRight
} from "./CountryDetailStyles";
import StrokedGaugeChart from "./GaugeChart";

const vehicleData = {
  cars: "Cars",
  deliveryTrucks: "Delivery Trucks",
  buses: "Buses",
  motorcycles: "Motorcycles",
  tractors: "Tractors"
};

const VehicleParcTab = ({ isAdmin }) => {
  const { Country } = useStore();
  const [state, setState] = useState({
    mostUsedChart: true,
    carImportChart: true,
    truckImportChart: true,
    vehicleImportChart: true,
    carEstimationChart: true,
    truckEstimationChart: true,
    displayNotesForCarParc: false
  });

  const onSendHandler = (event, index, mainKey, subKey, number = false) => {
    const newCountry = { ...Country.currentCountry };
    const { id, value } = event.target;

    const maxLength = id === "notesForCarParc" ? null : 50;
    if (maxLength && value && value.length > maxLength) {
      return;
    }

    let update = false;
    if (subKey) {
      if (index === "none") {
        update = newCountry[mainKey].data[subKey] !== (number ? +value : value);
        newCountry[mainKey].data[subKey] = number ? +value : value;
      } else if (index === "total") {
        update = newCountry[mainKey].total !== +value;
        newCountry[mainKey].total = +value;
      } else {
        update = newCountry[mainKey].data[index][subKey] !== (number ? +value : value);
        newCountry[mainKey].data[index][subKey] = number ? +value : value;
      }
    } else if (mainKey) {
      update = newCountry[mainKey][index] !== (number ? +value : value);
      newCountry[mainKey][index] = number ? +value : value;
    } else {
      update = newCountry[id] !== (number ? +value : value);
      newCountry[id] = number ? +value : value;
    }

    if (update) {
      Country.updateCountry(newCountry);
    }
    setState({ ...state, [id]: false });
  };

  const getFlagName = name => {
    const found = worldCountryList.find(country => country.text === name);
    if (found) return found.flag;
  };

  const getWidth = () => {
    const mostUsedCarStore = Country.get(Country.currentCountry.mostUsedPassengerCar);
    const [first = "", second = "", third = "", fourth = "", fifth = ""] = mostUsedCarStore;
    return Math.max(
      134 + first.length * 8,
      163 + second.length * 8,
      173 + third.length * 8,
      163 + fourth.length * 8,
      134 + fifth.length * 8
    );
  };

  return useObserver(() => {
    const {
      mostUsedChart,
      carImportChart,
      truckImportChart,
      vehicleImportChart,
      carEstimationChart,
      truckEstimationChart,
      displayNotesForCarParc
    } = state;
    const mostUsedCarStore = Country.get(Country.currentCountry.mostUsedPassengerCar);
    const mostUsedCars = Array.from(Array(5)).map((_, index) => mostUsedCarStore[index] || "");
    return (
      <React.Fragment>
        <GridContainer padding="0 20px" centered>
          <GridColumn colwidth="16" padding="0 10px 0 0">
            <GridRow>
              <TabContainer minheight="690px">
                <Title>Motorization Rate</Title>
                <ExplanationPopup field="motorizationRate"></ExplanationPopup>
                <StrokedGaugeChart
                  label={Country.currentCountry.country}
                  value={Country.currentCountry.motorizationRate || 0}
                  color="#00823d"
                ></StrokedGaugeChart>
                <StrokedGaugeChart
                  label="Africa Average"
                  value={42}
                  color="#c80f2d"
                ></StrokedGaugeChart>
                <StrokedGaugeChart
                  label="EU Average"
                  value={581}
                  color="#2b5c8a"
                ></StrokedGaugeChart>
              </TabContainer>
            </GridRow>
          </GridColumn>
          <GridColumn
            padding="0 10px 0 10px"
            colwidth="28"
            style={{
              borderRight: "1px solid rgb(110,208,77)",
              borderLeft: "1px solid rgb(110,208,77)"
            }}
          >
            <GridRow>
              <TabContainer minheight="310px">
                <Title>Vehicle Import Data (2017)</Title>
                <ExplanationPopup field="vehicleImport"></ExplanationPopup>
                <ChartIcon
                  onClick={() => {
                    setState({
                      ...state,
                      vehicleImportChart: !vehicleImportChart
                    });
                  }}
                  name={vehicleImportChart ? "list" : "chart bar"}
                />
                {vehicleImportChart ? (
                  <ChartContainer>
                    <TotalContainer>
                      Total:
                      <TotalRight>
                        {Country.currentCountry.vehicleImportData.total} Bil USD
                      </TotalRight>
                    </TotalContainer>
                    <Charts
                      height="200px"
                      categoryKey="vehicle"
                      dataKey="percentage"
                      graphData={Object.keys(vehicleData).map(data => ({
                        vehicle: vehicleData[data],
                        percentage: Country.currentCountry.vehicleImportData.data[data]
                      }))}
                    />
                  </ChartContainer>
                ) : (
                  <Table celled size="small" fixed>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width="9" className="bold">
                          Total (2017) (Bil USD)
                        </Table.Cell>
                        <Table.Cell>
                          <InputField
                            id="vehicleImportTotal"
                            disabled={!isAdmin || !Country.editMode}
                            defaultValue={Country.currentCountry.vehicleImportData.total}
                            clicked={state.vehicleImportTotal}
                            onBlur={event =>
                              onSendHandler(event, "total", "vehicleImportData", false, true)
                            }
                            onClick={() => {
                              setState({
                                ...state,
                                vehicleImportTotal: true
                              });
                            }}
                            type="number"
                          />
                        </Table.Cell>
                      </Table.Row>
                      {Object.keys(vehicleData).map(data => (
                        <Table.Row key={data}>
                          <Table.Cell width="9" className="bold">
                            {vehicleData[data]}
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              id={data}
                              disabled={!isAdmin || !Country.editMode}
                              defaultValue={Country.currentCountry.vehicleImportData.data[data]}
                              clicked={state[data]}
                              onBlur={event =>
                                onSendHandler(event, "none", "vehicleImportData", data)
                              }
                              type="number"
                              content={
                                state[data]
                                  ? ""
                                  : Country.currentCountry.vehicleImportData.data[data] + "%"
                              }
                              onClick={() => {
                                setState({
                                  ...state,
                                  [data]: true
                                });
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </TabContainer>
            </GridRow>
            <GridRow>
              <TabContainer minheight="360px">
                <Title>Most Used Passenger Car</Title>
                <ChartIcon
                  onClick={() => {
                    setState({
                      ...state,
                      mostUsedChart: !mostUsedChart
                    });
                  }}
                  name={mostUsedChart ? "list" : "chart line"}
                />
                {mostUsedChart ? (
                  <div
                    className="graph-container"
                    style={{
                      width: getWidth()
                    }}
                  >
                    <div className="circle-container">
                      <div className="half-circle"></div>
                      <div className="graph-circle">
                        <Icon
                          style={{ margin: 0, color: "white", alignSelf: "center" }}
                          size="big"
                          name="car"
                        />
                      </div>
                    </div>
                    <div className="rank-row rank-row-1">
                      <div className="l"></div>
                      <div className="r">
                        <div style={{ flexDirection: "column", marginTop: 1 }}>
                          <div className="line-s"></div>
                        </div>
                        <div className="rank-box">
                          <div className="rank">1</div>
                          {Country.currentCountry.mostUsedPassengerCar[0]}
                        </div>
                      </div>
                    </div>
                    <div className="rank-row rank-row-2">
                      <div className="l"></div>
                      <div className="r">
                        <div style={{ flexDirection: "column", marginTop: 1 }}>
                          <div className="line-s"></div>
                        </div>
                        <div className="rank-box">
                          <div className="rank">2</div>
                          {Country.currentCountry.mostUsedPassengerCar[1]}
                        </div>
                      </div>
                    </div>
                    <div className="rank-row rank-row-3">
                      <div className="r" style={{ marginLeft: 10 }}>
                        <div style={{ flexDirection: "column", marginTop: 1 }}>
                          <div className="line-s" style={{ width: 63 }}></div>
                        </div>
                        <div className="rank-box">
                          <div className="rank">3</div>
                          {Country.currentCountry.mostUsedPassengerCar[2]}
                        </div>
                      </div>
                    </div>
                    <div className="rank-row rank-row-4">
                      <div className="l l-4"></div>
                      <div className="r" style={{ marginTop: 25 }}>
                        <div style={{ flexDirection: "column", marginTop: 1 }}>
                          <div className="line-s"></div>
                        </div>
                        <div className="rank-box">
                          {" "}
                          <div className="rank">4</div>
                          {Country.currentCountry.mostUsedPassengerCar[3]}
                        </div>
                      </div>
                    </div>
                    <div className="rank-row rank-row-5">
                      <div className="l l-5"></div>
                      <div className="r" style={{ marginTop: 25 }}>
                        <div style={{ flexDirection: "column", marginTop: 1 }}>
                          <div className="line-s"></div>
                        </div>
                        <div className="rank-box">
                          <div className="rank">5</div>
                          {Country.currentCountry.mostUsedPassengerCar[4]}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Table celled size="small" fixed textAlign="center">
                    <Table.Body>
                      {mostUsedCars.map((data, index) => (
                        <Table.Row key={"mostUsedCar" + index}>
                          <Table.Cell className="bold" width="8">
                            {index + 1}
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              id={`mostUsed${index}`}
                              disabled={!isAdmin || !Country.editMode}
                              defaultValue={data}
                              clicked={state[`mostUsed${index}`]}
                              onBlur={event => onSendHandler(event, index, "mostUsedPassengerCar")}
                              onClick={() => {
                                setState({
                                  ...state,
                                  [`mostUsed${index}`]: true
                                });
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
                <div style={{ marginTop: 40 }}>
                  <InputField
                    label="Sources:"
                    note
                    id="mostUsedPassengerCarSource"
                    disabled={!isAdmin || !Country.editMode}
                    defaultValue={Country.currentCountry.mostUsedPassengerCarSource}
                    clicked={state.mostUsedPassengerCarSource}
                    onBlur={event => onSendHandler(event)}
                    onClick={() => {
                      setState({
                        ...state,
                        mostUsedPassengerCarSource: true
                      });
                    }}
                  />
                </div>
              </TabContainer>
            </GridRow>
          </GridColumn>
          <GridColumn
            padding="0 10px"
            colwidth="28"
            style={{
              borderRight: "1px solid rgb(110,208,77)"
            }}
          >
            <GridRow>
              <TabContainer minheight="310px">
                <Title>Car Import Countries (2017)</Title>
                <ChartIcon
                  onClick={() => {
                    setState({
                      ...state,
                      carImportChart: !carImportChart
                    });
                  }}
                  name={carImportChart ? "list" : "chart bar"}
                />
                <ExplanationPopup field="carImport"></ExplanationPopup>
                {carImportChart ? (
                  <ChartContainer margintop={15}>
                    <Charts
                      height="230px"
                      categoryKey="country"
                      dataKey="percentage"
                      graphData={Country.get(Country.currentCountry.carImportCountries.data)}
                    />
                  </ChartContainer>
                ) : (
                  <Table celled size="small" fixed>
                    <Table.Body>
                      {Country.currentCountry.carImportCountries.data.map((data, index) => (
                        <Table.Row key={index}>
                          <Table.Cell width="9" style={{ overflow: "visible" }}>
                            {Country.editMode && isAdmin ? (
                              <DropDown
                                compact
                                selectOnBlur={false}
                                search
                                placeholder="Select Country"
                                options={worldCountryList}
                                defaultValue={data.country}
                                selection
                                style={{
                                  padding: 5,
                                  lineHeight: "unset",
                                  minHeight: 0,
                                  width: "100%"
                                }}
                                disabled={!isAdmin || !Country.editMode}
                                onChange={(event, { value }) => {
                                  event.target.value = value;
                                  onSendHandler(event, index, "carImportCountries", "country");
                                }}
                                className="bold"
                              ></DropDown>
                            ) : (
                              <div className="bold">
                                {data.country && (
                                  <FlagContainer>
                                    <Flag name={getFlagName(data.country)} />
                                  </FlagContainer>
                                )}
                                {data.country}
                              </div>
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              id={`importPercentage${index}`}
                              disabled={!isAdmin || !Country.editMode}
                              defaultValue={data.percentage}
                              clicked={state[`importPercentage${index}`]}
                              onBlur={event =>
                                onSendHandler(
                                  event,
                                  index,
                                  "carImportCountries",
                                  "percentage",
                                  true
                                )
                              }
                              onClick={() => {
                                setState({
                                  ...state,
                                  [`importPercentage${index}`]: true
                                });
                              }}
                              type="number"
                              content={
                                state[`importPercentage${index}`] ? "" : data.percentage + "%"
                              }
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </TabContainer>
            </GridRow>
            <GridRow>
              <TabContainer minheight="360px">
                <Title>Car Parc Estimations (PC 2019)</Title>
                <ChartIcon
                  onClick={() => {
                    setState({
                      ...state,
                      carEstimationChart: !carEstimationChart
                    });
                  }}
                  name={carEstimationChart ? "list" : "chart bar"}
                />
                {carEstimationChart ? (
                  <ChartContainer margintop={30}>
                    <Charts
                      height="205px"
                      categoryKey="manufacturer"
                      dataKey="parc"
                      suffix="Units"
                      graphData={Country.get(Country.currentCountry.carParcEstimations.data)}
                    />
                  </ChartContainer>
                ) : (
                  <Table celled size="small" fixed>
                    <Table.Body>
                      {Country.currentCountry.carParcEstimations.data.map((data, index) => (
                        <Table.Row key={index}>
                          <Table.Cell width="9">
                            <InputField
                              className="bold"
                              content={state[`carManufacturer${index}`] ? "" : data.manufacturer}
                              id={`carManufacturer${index}`}
                              disabled={!isAdmin || !Country.editMode}
                              defaultValue={data.manufacturer || "xxx"}
                              clicked={state[`carManufacturer${index}`]}
                              onBlur={event =>
                                onSendHandler(event, index, "carParcEstimations", "manufacturer")
                              }
                              onClick={() => {
                                setState({
                                  ...state,
                                  [`carManufacturer${index}`]: true
                                });
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              id={`carParc${index}`}
                              disabled={!isAdmin || !Country.editMode}
                              defaultValue={data.parc}
                              clicked={state[`carParc${index}`] || state[`carParc${index}`] === 0}
                              onBlur={event =>
                                onSendHandler(event, index, "carParcEstimations", "parc", true)
                              }
                              content={
                                state[`carParc${index}`] || state[`carParc${index}`] === 0
                                  ? ""
                                  : data.parc.toLocaleString("de-DE")
                              }
                              type="number"
                              onClick={() => {
                                setState({
                                  ...state,
                                  [`carParc${index}`]: true
                                });
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
                <div style={{ marginTop: 40 }}>
                  <InputField
                    label="Sources:"
                    note
                    id="carEstimationSource"
                    disabled={!isAdmin || !Country.editMode}
                    defaultValue={Country.currentCountry.carParcEstimations.sources}
                    clicked={state.carEstimationSource}
                    onBlur={event => onSendHandler(event, "sources", "carParcEstimations")}
                    onClick={() => {
                      setState({
                        ...state,
                        carEstimationSource: true
                      });
                    }}
                  />
                </div>
              </TabContainer>
            </GridRow>
          </GridColumn>
          <GridColumn colwidth="28" padding="0 0 0 10px">
            <GridRow>
              <TabContainer minheight="310px">
                <Title>Truck Import Countries (2017)</Title>
                <ChartIcon
                  onClick={() => {
                    setState({
                      ...state,
                      truckImportChart: !truckImportChart
                    });
                  }}
                  name={truckImportChart ? "list" : "chart bar"}
                />
                {truckImportChart ? (
                  <ChartContainer margintop={30}>
                    <Charts
                      height="230px"
                      categoryKey="country"
                      dataKey="percentage"
                      graphData={Country.get(Country.currentCountry.truckImportCountries.data)}
                    />
                  </ChartContainer>
                ) : (
                  <Table celled size="small" fixed>
                    <Table.Body>
                      {Country.currentCountry.truckImportCountries &&
                        Country.currentCountry.truckImportCountries.data.map((data, index) => (
                          <Table.Row key={index}>
                            <Table.Cell width="9" style={{ overflow: "visible" }}>
                              {Country.editMode && isAdmin ? (
                                <DropDown
                                  compact
                                  selectOnBlur={false}
                                  search
                                  placeholder="Select Country"
                                  options={worldCountryList}
                                  defaultValue={data.country}
                                  selection
                                  style={{
                                    padding: 5,
                                    lineHeight: "unset",
                                    minHeight: 0,
                                    width: "100%"
                                  }}
                                  disabled={!isAdmin || !Country.editMode}
                                  onChange={(event, { value }) => {
                                    event.target.value = value;
                                    onSendHandler(event, index, "truckImportCountries", "country");
                                  }}
                                  className="bold"
                                ></DropDown>
                              ) : (
                                <div className="bold">
                                  {data.country && (
                                    <FlagContainer>
                                      <Flag name={getFlagName(data.country)} />
                                    </FlagContainer>
                                  )}
                                  {data.country}
                                </div>
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <InputField
                                id={`truckImportPercentage${index}`}
                                disabled={!isAdmin || !Country.editMode}
                                defaultValue={data.percentage}
                                clicked={state[`truckImportPercentage${index}`]}
                                onBlur={event =>
                                  onSendHandler(
                                    event,
                                    index,
                                    "truckImportCountries",
                                    "percentage",
                                    true
                                  )
                                }
                                onClick={() => {
                                  setState({
                                    ...state,
                                    [`truckImportPercentage${index}`]: true
                                  });
                                }}
                                type="number"
                                content={
                                  state[`truckImportPercentage${index}`]
                                    ? ""
                                    : data.percentage + "%"
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                )}
              </TabContainer>
            </GridRow>
            <GridRow>
              <TabContainer minheight="360px">
                <Title size={15}>Truck Parc Estimations (HCV 2019)</Title>
                <ChartIcon
                  onClick={() => {
                    setState({
                      ...state,
                      truckEstimationChart: !truckEstimationChart
                    });
                  }}
                  name={truckEstimationChart ? "list" : "chart bar"}
                />
                {truckEstimationChart ? (
                  <ChartContainer margintop={30}>
                    <Charts
                      height="205px"
                      categoryKey="manufacturer"
                      dataKey="parc"
                      suffix="Units"
                      graphData={Country.get(Country.currentCountry.truckParcEstimations.data)}
                    />
                  </ChartContainer>
                ) : (
                  <Table celled size="small" fixed>
                    <Table.Body>
                      {Country.currentCountry.truckParcEstimations.data.map((data, index) => (
                        <Table.Row key={index}>
                          <Table.Cell width="9">
                            <InputField
                              id={`truckManufacturer${index}`}
                              disabled={!isAdmin || !Country.editMode}
                              defaultValue={data.manufacturer || "xxx"}
                              clicked={state[`truckManufacturer${index}`]}
                              onBlur={event =>
                                onSendHandler(event, index, "truckParcEstimations", "manufacturer")
                              }
                              onClick={() => {
                                setState({
                                  ...state,
                                  [`truckManufacturer${index}`]: true
                                });
                              }}
                              className="bold"
                              content={
                                state[`truckManufacturer${index}`] ? "" : data.manufacturer || "xxx"
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <InputField
                              id={`truckParc${index}`}
                              disabled={!isAdmin || !Country.editMode}
                              defaultValue={data.parc}
                              clicked={state[`truckParc${index}`]}
                              onBlur={event =>
                                onSendHandler(event, index, "truckParcEstimations", "parc", true)
                              }
                              onClick={() => {
                                setState({
                                  ...state,
                                  [`truckParc${index}`]: true
                                });
                              }}
                              type="number"
                              content={
                                state[`truckParc${index}`] || state[`truckParc${index}`] === 0
                                  ? ""
                                  : data.parc.toLocaleString("de-DE")
                              }
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
                <div style={{ marginTop: 40 }}>
                  <InputField
                    note
                    label="Sources:"
                    id="truckEstimationSource"
                    disabled={!isAdmin || !Country.editMode}
                    defaultValue={Country.currentCountry.truckParcEstimations.sources}
                    clicked={state.truckEstimationSource}
                    onBlur={event => onSendHandler(event, "sources", "truckParcEstimations")}
                    onClick={() => {
                      setState({
                        ...state,
                        truckEstimationSource: true
                      });
                    }}
                  />
                </div>
              </TabContainer>
            </GridRow>
          </GridColumn>
          <GridRow>
            <GridColumn width="10">
              <TabContainer
                minheight={displayNotesForCarParc ? "250px" : "10px"}
                style={{ cursor: "pointer" }}
              >
                <Title
                  onClick={() => {
                    setState({ ...state, displayNotesForCarParc: !displayNotesForCarParc });

                    setTimeout(() => {
                      if (!displayNotesForCarParc) window.scrollTo(0, document.body.scrollHeight);
                    }, 1);
                  }}
                >
                  Notes for Car Parc & Product Range - M+H Team
                </Title>
                {displayNotesForCarParc ? (
                  <Table celled size="small" fixed>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell style={{ overflow: "visible" }}>
                          {Country.editMode && Country.noteEditor ? (
                            <TextEditor
                              id="notesForCarParc"
                              textObject={Country.get(Country.currentCountry.notesForCarParc)}
                              send={event => {
                                Country.setNote("noteEditor", false);
                                onSendHandler(event);
                              }}
                            ></TextEditor>
                          ) : (
                            <Description
                              height={270}
                              onClick={() => {
                                Country.setNote("noteEditor", true);
                              }}
                              dangerouslySetInnerHTML={{
                                __html: Country.currentCountry.notesForCarParc.richText
                                  ? draftToHtml(Country.currentCountry.notesForCarParc.richText)
                                  : Country.currentCountry.notesForCarParc.plainText
                              }}
                            ></Description>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                ) : null}
              </TabContainer>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </React.Fragment>
    );
  });
};

export default VehicleParcTab;
