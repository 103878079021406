const mhconf = {
  industrialRegionEx: true,
  countryDialCode: 90,
  countryCode: "TR",
  hasFuelFilterChangeFrequency: false,
  hasOilFilterChangeFrequency: false,
  filterChangeFrequency: undefined,
  legendOffset: { min: 400, max: 500 },
  svgProps: { viewBox: "-130 -30 1007 670", height: "700px" },
  hasContactTitle: false,
  hasRetailerMap: false
};

export default mhconf;
