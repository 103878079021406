import { action, flow, observable, runInAction } from "mobx";

import baseRequest from "../_core/baseRequest";
import {
  filterImportOptions,
  gdpOptions,
  mhFootprintOptions,
  mhMarketShareOptions,
  motorizationRateOptions,
  populationOptions,
  vehicleImportOptions
} from "../utility/constants/legendScales";

class Map {
  @observable showToolTip = false;
  @observable toolTipX = 0;
  @observable toolTipY = 0;
  @observable text = "";
  @observable mapOption = "population";
  @observable mapReport = {};
  @observable mapState = "pending";
  @observable mapLoading = false;
  @observable legendOption = null;
  @observable legendOptionList = populationOptions;

  @action
  initMapState = () => {
    this.mapOption = "population";
    this.mapState = "pending";
    this.legendOptionList = populationOptions;
    this.legendOption = null;
  };

  @action
  setTooltipProps = (toolTipX, toolTipY, text) => {
    this.showToolTip = true;
    this.toolTipX = toolTipX;
    this.toolTipY = toolTipY;
    this.text = text;
  };

  @action
  hideTooltip = () => {
    this.showToolTip = false;
    this.toolTipX = 0;
    this.toolTipY = 0;
    this.text = "";
  };

  @action
  setMapOption = mapOption => {
    this.mapOption = mapOption;
    switch (mapOption) {
      case "population": {
        this.legendOptionList = populationOptions;
        break;
      }
      case "gdp": {
        this.legendOptionList = gdpOptions;
        break;
      }
      case "filterImportValue": {
        this.legendOptionList = filterImportOptions;
        break;
      }
      case "vehicleImportValue": {
        this.legendOptionList = vehicleImportOptions;
        break;
      }
      case "mhMarketShare": {
        this.legendOptionList = mhMarketShareOptions;
        break;
      }
      case "mhFootprint": {
        this.legendOptionList = mhFootprintOptions;
        break;
      }
      case "motorizationRate": {
        this.legendOptionList = motorizationRateOptions;
        break;
      }
      default:
        this.legendOptionList = populationOptions;
        break;
    }
  };

  @action
  setMapLoading = mapLoading => {
    this.mapLoading = mapLoading;
  };

  @action
  setLegendOption = legendOption => {
    this.legendOption = legendOption;
  };

  getMapReports = flow(function*() {
    this.mapState = "pending";
    this.mapLoading = true;
    try {
      const result = yield baseRequest.get("/countryDataReport");
      runInAction(() => {
        this.mapState = "done";
        this.mapReport = result.data.countryData;
      });
    } catch (error) {
      this.mapState = "error";
    }
  });
}

export default Map;
