import { useObserver } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Grid, Segment } from "semantic-ui-react";

import { useStore } from "../_core/appContext";
import baseRequest from "../_core/baseRequest";

const LoginPage = () => {
  const { Auth } = useStore();
  const history = useHistory();
  const [state, setState] = useState({});

  const login = () => {
    const { username, password } = state;
    Auth.login({ username, password })
      .then(result => {
        baseRequest.addHeader(result.data.sessionToken);
        history.push("/");
      })
      .catch(() => {});
  };

  const onChange = (field, e) => {
    const user = {};
    user[field] = e.target.value;
    setState({ ...state, ...user });
  };

  return useObserver(() => {
    return (
      <React.Fragment>
        <Grid stackable>
          <Grid.Row width={2} style={{ justifyContent: "center" }}>
            <Grid.Column width={4}>
              <Segment style={{ padding: 50, marginTop: 50 }}>
                <Form>
                  <Form.Field>
                    <label>Username</label>
                    <input
                      name="login-username"
                      placeholder="Username"
                      type="text"
                      onChange={e => onChange("username", e)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Password</label>
                    <input
                      name="login-password"
                      placeholder="********"
                      type="password"
                      onChange={e => onChange("password", e)}
                    />
                  </Form.Field>
                  <Button type="submit" style={{ float: "right" }} onClick={login}>
                    Login
                  </Button>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  });
};

export default LoginPage;
