import { useObserver } from "mobx-react";
import React from "react";

import * as mhUtilities from "../../utility";
import RegionDetail from "./RegionDetail";

const { countryAttributes, countryPaths } = mhUtilities;

function RegionMap(props) {
  const { mapType, mapReport } = props;
  return useObserver(() => {
    return (
      <g>
        {countryAttributes.map((attr, i) => {
          return (
            <RegionDetail
              key={attr.id}
              id={attr.id}
              countryData={mapReport[attr.id] || {}}
              d={countryPaths[i].path}
              d2={countryPaths[i].path2}
              d3={countryPaths[i].path3}
              mapType={mapType}
            />
          );
        })}
      </g>
    );
  });
}

export default RegionMap;
