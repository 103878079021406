import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import FilePondImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import { useObserver } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { Button, Form, Modal, Select, Table } from "semantic-ui-react";
import styled from "styled-components";

import { useStore } from "../../_core/appContext";
import config from "../../_core/config";
import { loadState } from "../../_core/localStorage";
import { getFormattedDate } from "../../utility/dateHelper";
import { GridColumn, GridContainer, GridRow, TabContainer, Title } from "./CountryDetailStyles";

registerPlugin(FilePondImagePreview);
registerPlugin(FilePondPluginImageTransform);
registerPlugin(FilePondPluginImageResize);

const fileScopeOptions = [
  {
    value: "Economic Trends",
    text: "Economic Trends"
  },
  {
    value: "Marketing Trends",
    text: "Marketing Trends"
  },
  {
    value: "Car Parc Information",
    text: "Car Parc Information"
  },
  {
    value: "Spare Part Market",
    text: "Spare Part Market"
  },
  {
    value: "Competitor Info",
    text: "Competitor Info"
  },
  {
    value: "International Sales Document",
    text: "International Sales Document"
  },
  {
    value: "Other",
    text: "Other"
  }
];

const DocumentsTab = () => {
  const { Country, Auth } = useStore();
  const [state, setState] = useState({
    uploadedFiles: [],
    files: [],
    scopeOfDocument: "",
    fileId: "",
    fileType: "",
    fileName: "",
    showModal: false
  });

  useEffect(() => {
    setState({ ...state, uploadedFiles: Country.get(Country.currentCountry.fileIds) || [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveHandler = async () => {
    const username = Auth.user.name;
    const countryData = { ...Country.currentCountry };
    const { uploadedFiles, scopeOfDocument, fileId, fileType, fileName } = state;
    const updatedFileIds = [...uploadedFiles];
    updatedFileIds.push({
      scopeOfDocument: scopeOfDocument,
      fileId,
      fileType,
      fileName,
      username,
      uploadDate: new Date()
    });
    countryData.fileIds = updatedFileIds;
    await Country.updateCountry(countryData);
    setState({
      ...state,
      uploadedFiles: updatedFileIds,
      showModal: false,
      scopeOfDocument: "",
      fileId: "",
      fileType: "",
      files: []
    });
  };

  const onDeleteHandler = async index => {
    const countryData = { ...Country.currentCountry };
    const { uploadedFiles } = state;
    uploadedFiles.splice(index, 1);
    countryData.fileIds = uploadedFiles;
    await Country.updateCountry(countryData);
    setState({ ...state, uploadedFiles });
  };

  return useObserver(() => {
    const { uploadedFiles, scopeOfDocument, files, fileId } = state;
    return (
      <GridContainer padding="0 20px">
        <GridRow>
          <GridColumn width="16">
            <TabContainer>
              <Title>Documents & Reports & Useful Links</Title>
              <Table celled size="small" fixed textAlign="center">
                {uploadedFiles && uploadedFiles.length ? (
                  <React.Fragment>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Scope Of Document</Table.HeaderCell>
                        <Table.HeaderCell>File Name</Table.HeaderCell>
                        <Table.HeaderCell>Uploaded User</Table.HeaderCell>
                        <Table.HeaderCell>Uploaded Date</Table.HeaderCell>
                        <Table.HeaderCell width="1">Delete</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {uploadedFiles.map((file, index) => {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell>{file.scopeOfDocument || "-"}</Table.Cell>
                            <Table.Cell>
                              <div
                                onClick={() => {
                                  window.open(
                                    config.getBasePublicUrl() + "api/documentfile/" + file.fileId
                                  );
                                }}
                                style={{
                                  cursor: "pointer"
                                }}
                              >
                                {file.fileName}
                              </div>
                            </Table.Cell>
                            <Table.Cell>{file.username}</Table.Cell>
                            <Table.Cell>
                              {file.uploadDate && getFormattedDate(file.uploadDate)}
                            </Table.Cell>
                            <Table.Cell width="1">
                              <DeleteIcon
                                onClick={() => {
                                  onDeleteHandler(index);
                                }}
                                className="trash link alternate icon"
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </React.Fragment>
                ) : (
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width="16">No file uploaded</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                )}
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="5">
                      <Modal
                        trigger={
                          <Button
                            style={{ backgroundColor: "rgb(0, 130, 60)", color: "white" }}
                            floated="right"
                            onClick={() => setState({ ...state, showModal: true })}
                          >
                            Add File
                          </Button>
                        }
                        open={state.showModal}
                        onClose={() =>
                          setState({
                            showModal: false,
                            fileId: "",
                            scopeOfDocument: "",
                            fileType: "",
                            fileName: "",
                            files: []
                          })
                        }
                      >
                        <Modal.Content style={{ overflow: "visible" }}>
                          <Form>
                            <GridContainer columns="2">
                              <GridRow padding="25px">
                                <GridColumn width="5">
                                  <Select
                                    required
                                    placeholder="Scope of Document"
                                    name="scopeOfDocument"
                                    value={scopeOfDocument}
                                    options={fileScopeOptions}
                                    onChange={(e, { value }) => {
                                      setState({ ...state, scopeOfDocument: value });
                                    }}
                                  />
                                </GridColumn>
                              </GridRow>
                              <GridRow padding="0 25px 25px">
                                <GridColumn width="16">
                                  <FilePond
                                    files={files}
                                    imageTransformOutputQuality={50}
                                    imageResizeTargetWidth={2048}
                                    imageResizeUpscale={false}
                                    onprocessfile={(error, file) => {
                                      const newFiles = [];
                                      newFiles[0] = file.file;
                                      setState({
                                        ...state,
                                        files: newFiles,
                                        fileId: file.serverId,
                                        fileType: file.file.type,
                                        fileName: file.filename
                                      });
                                    }}
                                    onremovefile={() => {
                                      setState({
                                        ...state,
                                        fileId: "",
                                        fileName: "",
                                        fileType: "",
                                        files: []
                                      });
                                    }}
                                    maxFiles={1}
                                    server={{
                                      url: config.getBasePublicUrl() + "api/",
                                      process: {
                                        url: "upload/documentfile",
                                        headers: {
                                          Authorization: loadState().user.sessionToken
                                        }
                                      },
                                      load: {
                                        url: "documentfile/"
                                      }
                                    }}
                                  />
                                </GridColumn>
                              </GridRow>
                            </GridContainer>
                          </Form>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            disabled={scopeOfDocument.length === 0 || fileId.length === 0}
                            style={{
                              color: "white",
                              backgroundColor:
                                scopeOfDocument && files.length ? "rgb(0, 130, 60)" : "grey"
                            }}
                            content="Save"
                            onClick={() => onSaveHandler()}
                          />
                        </Modal.Actions>
                      </Modal>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </TabContainer>
          </GridColumn>
        </GridRow>
      </GridContainer>
    );
  });
};

const DeleteIcon = styled.i`
  color: ${props => props.theme.MH_RED};
  font-size: 20px !important;
`;

export default DocumentsTab;
