import { action, observable } from "mobx";

export default class Alert {
  @observable title = "Error";
  @observable showAlert = false;
  @observable body = "";
  @observable actionFunc = false;
  @observable actionFunc2 = false;
  @observable actionTitle = "";
  @observable actionTitle2 = "";

  @action
  setAlert = keys => {
    Object.keys(keys).forEach(key => {
      this[key] = keys[key];
    });
    this.showAlert = true;
  };

  @action
  close = () => {
    this.title = "Error";
    this.showAlert = false;
    this.body = "";
    this.actionFunc = false;
    this.actionFunc2 = false;
    this.actionTitle = "";
    this.actionTitle2 = "";
  };
}
