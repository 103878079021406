import { useObserver } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, Menu, Responsive } from "semantic-ui-react";
import { ThemeContext } from "styled-components";
import vivus from "vivus";

import { useStore } from "../../_core/appContext.js";
import { ButtonGreen } from "../../components/DefaultComponents/Button";
import svg from "../../images/mh_logo_green.svg";

const Header = () => {
  const { colors } = useContext(ThemeContext);
  const { Auth } = useStore();
  const history = useHistory();
  const path = window.location.pathname;
  const [hideMobileMenu, setHideMobileMenu] = useState(true);
  const [svgReady, setSvgReady] = useState(false);
  const [renderSvg, setRenderSvg] = useState(false);

  function startSvgAnimation() {
    new vivus(
      "svg-logo",
      {
        file: require("../../images/mh_logo.svg"),
        duration: 200,
        onReady: myVivus => {
          myVivus.play();
          setTimeout(() => {
            setRenderSvg(true);
          }, 2500);
        }
      },
      () => {
        setSvgReady(true);
      }
    );
  }

  function navPressed(newPath) {
    setHideMobileMenu(true);
    history.push(newPath);
  }

  function logout() {
    Auth.logout().finally(() => {
      history.push("/login");
    });
  }

  useEffect(() => {
    if (Auth.user.loggedIn) {
      setHideMobileMenu(true);
    }
    startSvgAnimation();
    const animationTimeout = setTimeout(() => {
      setRenderSvg(true);
    }, 2500);
    return () => clearTimeout(animationTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classSvg = svgReady ? "fadeout" : "";
  const classSvg2 = renderSvg ? "header-image fadein" : "header-image svg-hidden";

  return useObserver(() => {
    return (
      <div className="custom-header">
        <Menu secondary stackable>
          <Menu.Item header onClick={() => navPressed("/")}>
            <div style={{ position: "relative", height: 50 }}>
              <div
                style={{
                  position: "absolute",
                  height: 50,
                  width: 138.5,
                  zIndex: 10
                }}
                id="svg-logo"
                className={classSvg}
              />
              <img
                style={{
                  position: "absolute",
                  height: 50,
                  width: 138.5
                }}
                src={svg}
                className={classSvg2}
                alt="class-svg2"
              />
            </div>
          </Menu.Item>
          {Auth.user.loggedIn && (
            <Responsive {...Responsive.onlyMobile}>
              <Menu.Item
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "13px",
                  justifyContent: "flex-end"
                }}
              >
                <Button
                  basic
                  onClick={() => {
                    setHideMobileMenu(!hideMobileMenu);
                  }}
                >
                  <span className="menuTogglespan" />
                  <span className="menuTogglespan" />
                  <span style={{ marginBottom: 0 }} className="menuTogglespan" />
                </Button>
              </Menu.Item>
            </Responsive>
          )}
          {Auth.user.loggedIn && (
            <Container
              fluid
              style={hideMobileMenu ? { display: "none" } : { alignItems: "center" }}
              className="bigScreenMenu"
            >
              <Menu.Menu position="right">
                <Menu.Item
                  id="header-nav-map"
                  style={{
                    color: path === "/" ? colors.MH_GREEN : "rgba(0,0,0,0.87)",
                    justifyContent: !hideMobileMenu ? "center" : "flex-start"
                  }}
                  onClick={() => navPressed("/")}
                >
                  Map
                </Menu.Item>
                <Menu.Item
                  id="header-nav-country-detail"
                  style={{
                    color:
                      path && path.split("/")[1] === "countryDetail"
                        ? colors.MH_GREEN
                        : "rgba(0,0,0,0.87)",
                    justifyContent: !hideMobileMenu ? "center" : "flex-start"
                  }}
                  onClick={() => {
                    if (path && path.split("/")[1] === "countryDetail") return;
                    navPressed("/countryDetail/Ghana");
                  }}
                >
                  Country Detail
                </Menu.Item>
                <Menu.Item
                  id="header-nav-users"
                  style={{
                    color: path === "/users" ? colors.MH_GREEN : "rgba(0,0,0,0.87)",
                    justifyContent: !hideMobileMenu ? "center" : "flex-start"
                  }}
                  onClick={() => navPressed("/users")}
                >
                  Users
                </Menu.Item>
                <Menu.Item>
                  <ButtonGreen name="logout-button" onClick={() => logout()}>
                    Logout
                  </ButtonGreen>
                </Menu.Item>
              </Menu.Menu>
            </Container>
          )}
        </Menu>
      </div>
    );
  });
};

export default Header;
