import { Button } from "semantic-ui-react";
import styled from "styled-components";

export const ButtonGreen = styled(Button)`
  background-color: rgb(0, 130, 60) !important;
  color: white !important;
`;

export const ButtonGray = styled(Button)`
  background-color: rgba(74, 74, 74, 0.2) !important;
  color: rgba(74, 74, 74) !important;
`;

export const ButtonOrange = styled(Button)`
  background-color: rgb(255, 115, 0) !important;
  color: white !important;
`;

export const ButtonAccentBlue = styled(Button)`
  background-color: rgb(0, 158, 224) !important;
  color: white !important;
`;

export const ButtonBasicGreen = styled(Button)`
  color: rgb(0, 130, 60) !important;
  box-shadow: 0 0 0 1px rgb(0, 130, 60) inset !important;
  -moz-box-shadow: 0 0 0 1px rgb(0, 130, 60) inset !important;
  -webkit-box-shadow: 0 0 0 1px rgb(0, 130, 60) inset !important;
  -ms-box-shadow: 0 0 0 1px rgb(0, 130, 60) inset !important;
  -o-box-shadow: 0 0 0 1px rgb(0, 130, 60) inset !important;
  background-color: white !important;
`;

export const ButtonBasicRed = styled(Button)`
  color: rgb(200, 15, 45) !important;
  box-shadow: 0 0 0 1px rgb(200, 15, 45) inset !important;
  -moz-box-shadow: 0 0 0 1px rgb(200, 15, 45) inset !important;
  -webkit-box-shadow: 0 0 0 1px rgb(200, 15, 45) inset !important;
  -ms-box-shadow: 0 0 0 1px rgb(200, 15, 45) inset !important;
  -o-box-shadow: 0 0 0 1px rgb(200, 15, 45) inset !important;
  background-color: white !important;
`;

export const ButtonVisitAgain = styled(Button)`
  color: ${props => (props.visitAgain ? "white !important" : "rgba(74, 74, 74) !important")};
  background-color: ${props =>
    props.visitAgain ? "rgb(255, 115, 0) !important" : "rgba(74, 74, 74, 0.2) !important"};
`;

export const ButtonSeller = styled(Button)`
  color: ${props => (props.seller ? "white !important" : "rgba(74, 74, 74) !important")};
  background-color: ${props =>
    props.seller ? "rgb(0, 158, 224) !important" : "rgba(74, 74, 74, 0.2) !important"};
`;

export const ButtonLoading = styled(Button)`
  color: ${props =>
    !props.loading && (props.isBasic ? "rgb(0, 130, 60) !important" : "white !important")};
  box-shadow: ${props => !props.loading && "0 0 0 1px rgb(0, 130, 60) inset !important"};
  -moz-box-shadow: ${props => !props.loading && "0 0 0 1px rgb(0, 130, 60) inset !important"};
  -webkit-box-shadow: ${props => !props.loading && "0 0 0 1px rgb(0, 130, 60) inset !important"};
  -ms-box-shadow: ${props => !props.loading && "0 0 0 1px rgb(0, 130, 60) inset !important"};
  -o-box-shadow: ${props => !props.loading && "0 0 0 1px rgb(0, 130, 60) inset !important"};
  background-color: ${props =>
    props.loading || !props.isBasic ? "rgb(0, 130, 60) !important" : "white !important"};
`;

export const ButtonModify = styled(Button)`
  color: ${props => (props.modifying ? "white !important" : "rgb(0, 130, 60) !important")};
  background-color: ${props =>
    props.modifying ? "rgb(0, 130, 60) !important" : "white !important"};
  box-shadow: ${props => !props.modifying && "0 0 0 1px rgb(0, 130, 60) inset !important"};
  -moz-box-shadow: ${props => !props.modifying && "0 0 0 1px rgb(0, 130, 60) inset !important"};
  -webkit-box-shadow: ${props => !props.modifying && "0 0 0 1px rgb(0, 130, 60) inset !important"};
  -ms-box-shadow: ${props => !props.modifying && "0 0 0 1px rgb(0, 130, 60) inset !important"};
  -o-box-shadow: ${props => !props.modifying && "0 0 0 1px rgb(0, 130, 60) inset !important"};
`;

export const ButtonLobby = styled.div`
  height: 220px;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 30px;
  cursor: pointer;
  background-color: ${props => props.color};
  &:hover {
    background: rgba(58, 132, 73, 0.98);
  }
`;

export const ButtonAllMix = styled(Button)`
  background-color: rgb(0, 158, 224) !important;
  color: white !important;
  font-weight: bold !important;
  margin-top: 23px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 11px !important;
  width: 100%;
  height: 38px !important;
  @media only screen and (max-width: 632px) {
    margin-top: 0px !important;
  }
`;
