import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from "draftjs-to-html";
import { useObserver } from "mobx-react";
import React, { useState } from "react";
import { Icon, Table } from "semantic-ui-react";
import styled from "styled-components";

import { useStore } from "../../_core/appContext";
import ExplanationPopup from "../DefaultComponents/ExplanationPopup";
import TextEditor from "../DefaultComponents/TextEditor";
import Charts from "./Charts";
import {
  Description,
  GridColumn,
  GridContainer,
  GridRow,
  InputField,
  TabContainer,
  Title
} from "./CountryDetailStyles";

const fastFactsData = {
  subRegion: { name: "Sub Region" },
  managedBy: { name: "Managed By" },
  governance: { name: "Governance" },
  capitalCity: { name: "Capital City" },
  currency: { name: "Currency" },
  usdExc: { name: "Usd EXC (Jan 2020)" },
  language: { name: "Official Language" },
  population: { name: "Population (2019) (mil)" },
  medianAge: { name: "Median Age" },
  lifeExpectancy: { name: "Life Expectancy" },
  urbanPopulationRate: { name: "Urban Population Rate" },
  antiCorruptionIndex: { name: "Anti-Corruption Index" },
  religion: { name: "Religion (Main)" },
  internetPenetration: { name: "Internet Penetration (2019)" },
  facebookSubscribers: { name: "Facebook Subscribers (mil)" }
};
const economyData = {
  gdp: { name: "GDP (2018) (Bil. USD)" },
  gdpPerCapita: { name: "GDP Per Capita (2018) (USD)" },
  unemploymentRate: { name: "Unemployment Rate (2018)", icon: "%" },
  inflationRate: { name: "Inflation Rate (2018)", icon: "%" },
  principalExportsTo: { name: "Principal Exports To (2017)" },
  principalImportsFrom: { name: "Principal Imports From (2017)" },
  partOfGdp: { name: "Part Of GDP" }
};

const FastFactsTab = ({ isAdmin }) => {
  const { Country } = useStore();
  const [state, setState] = useState({
    showAdd: false,
    exportItemNew: "",
    exportPercentageNew: "",
    fieldChecked: {},
    exportBreakdownChartView: true
  });

  const onChangeHandler = event => {
    event.preventDefault();
    const { id, value } = event.target;
    setState({ ...state, [id]: value });
  };

  const onSendHandler = (event, index, key) => {
    const newCountry = { ...Country.currentCountry };
    const { id, value } = event.target;

    const maxLength = id === "notesForCountry" || id === "macroEconomicPerformance" ? null : 50;
    if (maxLength && value && value.length > maxLength) {
      return;
    }
    let update = true;

    if (key) {
      if (newCountry.exportBreakdown && newCountry.exportBreakdown.items[index]) {
        update =
          newCountry.exportBreakdown.items[index][key] !== (key === "percentage" ? +value : value);
        newCountry.exportBreakdown.items[index][key] = key === "percentage" ? +value : value;
        setState({
          ...state,
          [`exportItem${index}`]: "",
          [`exportPercentage${index}`]: ""
        });
      } else {
        if (!newCountry.exportBreakdown) {
          newCountry.exportBreakdown = { items: [] };
        }
        newCountry.exportBreakdown.items.push({ [key]: value });
        setState({
          ...state,
          showAdd: false,
          exportItemNew: "",
          exportPercentageNew: ""
        });
      }
      newCountry.exportBreakdown.items = newCountry.exportBreakdown.items.filter(
        data => data.item || data.percentage
      );
    } else {
      update = newCountry[id] !== value;
      newCountry[id] = value;
      setState({ ...state, [id]: false });
    }
    if (update) {
      Country.updateCountry(newCountry);
    }
  };

  const getSuffix = value => {
    if (value < 11) return "catastrophic";
    if (value < 31) return "very bad";
    if (value < 51) return "bad";
    if (value < 71) return "moderate";
    if (value < 91) return "good";
    return "very good";
  };

  const { showAdd, exportBreakdownChartView } = state;
  const exportBreakdownLength = Country.currentCountry.exportBreakdown
    ? Country.currentCountry.exportBreakdown.items.length
    : 0;

  return useObserver(() => {
    return (
      <React.Fragment>
        <GridContainer padding="0 20px">
          <GridColumn width="5" padding="0 10px 0 0">
            <TabContainer minheight="728px">
              <Title>Fast Facts</Title>
              <Table celled size="small" fixed>
                <Table.Body>
                  {Object.keys(fastFactsData).map(data => {
                    let suffix = "";
                    if (data === "urbanPopulationRate") suffix = "%";
                    if (data === "antiCorruptionIndex")
                      suffix = getSuffix(Country.currentCountry.antiCorruptionIndex);
                    if (data === "antiCorruptionIndex" && !Country.editMode) {
                      return (
                        <Table.Row key={data}>
                          <Table.Cell className="bold">
                            Anti-Corruption Index
                            <ExplanationPopup field="internetPenetration"></ExplanationPopup>
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <GradientBox>
                              <IndexBox
                                width={100 - parseInt(Country.currentCountry.antiCorruptionIndex)}
                              />
                            </GradientBox>
                            <span
                              style={{ fontSize: 11 }}
                            >{`${Country.currentCountry.antiCorruptionIndex} (${suffix})`}</span>
                          </Table.Cell>
                        </Table.Row>
                      );
                    }
                    return (
                      <Table.Row key={data}>
                        <Table.Cell className="bold">
                          {fastFactsData[data].name}
                          {data === "population" ? (
                            <ExplanationPopup field={data}></ExplanationPopup>
                          ) : null}
                        </Table.Cell>
                        <Table.Cell style={{ overflow: "visible" }}>
                          <InputField
                            id={data}
                            disabled={!isAdmin || !Country.editMode}
                            clicked={state[data]}
                            onBlur={event => {
                              onSendHandler(event);
                            }}
                            content={
                              !!state[data] || suffix === ""
                                ? ""
                                : `${Country.currentCountry[data]}(${suffix})`
                            }
                            onClick={() => {
                              setState({ ...state, [data]: true });
                            }}
                            type={
                              data === "antiCorruptionIndex" || data === "urbanPopulationRate"
                                ? "number"
                                : "text"
                            }
                            defaultValue={Country.currentCountry[data]}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </TabContainer>
          </GridColumn>
          <GridColumn
            width="6"
            padding="0 10px"
            style={{
              borderRight: "1px solid rgb(110,208,77)",
              borderLeft: "1px solid rgb(110,208,77)"
            }}
          >
            <GridRow>
              <TabContainer minheight="347px">
                <Title>Economy</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    {Object.keys(economyData).map(data => (
                      <Table.Row key={data}>
                        <Table.Cell className="bold">
                          {economyData[data].name}{" "}
                          {data === "partOfGdp" ? (
                            <ExplanationPopup field="exportBreakdown"></ExplanationPopup>
                          ) : null}
                        </Table.Cell>
                        <Table.Cell style={{ overflow: "visible" }}>
                          <InputField
                            id={data}
                            disabled={!isAdmin || !Country.editMode}
                            clicked={state[data]}
                            onBlur={event => onSendHandler(event)}
                            onClick={() => {
                              setState({
                                ...state,
                                [data]: true
                              });
                            }}
                            defaultValue={Country.currentCountry[data]}
                            type={
                              data === "unemploymentRate" || data === "inflationRate"
                                ? "number"
                                : "text"
                            }
                            content={
                              !!state[data] || !economyData[data].icon
                                ? ""
                                : `${Country.currentCountry[data]}${economyData[data].icon}`
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridRow>
            <GridRow>
              <TabContainer minheight="361px">
                <Title>Export Breakdown 2017 (Dutch Disease?)</Title>
                {Country.currentCountry && Country.currentCountry.exportBreakdown && (
                  <div>
                    <Icon
                      onClick={() => {
                        setState({
                          ...state,
                          exportBreakdownChartView: !exportBreakdownChartView
                        });
                      }}
                      style={{
                        marginTop: 5,
                        marginLeft: "90%",
                        cursor: "pointer",
                        color: "#3a8449"
                      }}
                      name={exportBreakdownChartView ? "list" : "chart pie"}
                    ></Icon>
                  </div>
                )}
                {exportBreakdownChartView ? (
                  <Charts
                    height="270px"
                    type="pie"
                    graphData={Country.get(Country.currentCountry.exportBreakdown.items)}
                  />
                ) : (
                  <Table celled size="small" fixed>
                    <Table.Body>
                      {Country.currentCountry.exportBreakdown &&
                        Country.currentCountry.exportBreakdown.items.map((data, index) => {
                          return (
                            <Table.Row key={`_${data.item}`}>
                              <Table.Cell width="8">
                                <InputField
                                  disabled={!isAdmin || !Country.editMode}
                                  clicked={state[`exportItem${index}`]}
                                  onBlur={event => onSendHandler(event, index, "item")}
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      [`exportItem${index}`]: true,
                                      showAdd: true
                                    });
                                  }}
                                  className="bold"
                                  content={state[`exportItem${index}`] ? "" : data.item}
                                  defaultValue={data.item}
                                />
                              </Table.Cell>
                              <Table.Cell width="8">
                                <InputField
                                  disabled={!isAdmin || !Country.editMode}
                                  clicked={state[`exportPercentage${index}`]}
                                  onBlur={event => onSendHandler(event, index, "percentage")}
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      [`exportPercentage${index}`]: true,
                                      showAdd: true
                                    });
                                  }}
                                  type="number"
                                  content={
                                    state[`exportPercentage${index}`] || !data.percentage
                                      ? ""
                                      : data.percentage + "%"
                                  }
                                  defaultValue={data.percentage}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      <AddExport display={showAdd || !exportBreakdownLength ? "table-row" : "none"}>
                        <Table.Cell>
                          <InputField
                            id="exportItemNew"
                            disabled={!isAdmin || !Country.editMode}
                            clicked={Country.editMode}
                            onBlur={event => onSendHandler(event, exportBreakdownLength, "item")}
                            onChange={onChangeHandler}
                            value={state.exportItemNew}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <InputField
                            id="exportPercentageNew"
                            disabled={!isAdmin || !Country.editMode}
                            clicked={Country.editMode}
                            onBlur={event =>
                              onSendHandler(event, exportBreakdownLength, "percentage")
                            }
                            onChange={onChangeHandler}
                            value={state.exportPercentageNew}
                            type="number"
                          />
                        </Table.Cell>
                      </AddExport>
                    </Table.Body>
                  </Table>
                )}
              </TabContainer>
            </GridRow>
          </GridColumn>
          <GridColumn width="5" padding="0 0 0 10px">
            <GridRow>
              <TabContainer minheight="347px">
                <Title size={15}>Macroeconomic Perf. Till 2019 & Expectations</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell style={{ overflow: "visible" }}>
                        {Country.macroEconomicEditor && Country.editMode && isAdmin ? (
                          <TextEditor
                            id="macroEconomicPerformance"
                            textObject={Country.get(
                              Country.currentCountry.macroEconomicPerformance
                            )}
                            send={event => {
                              Country.setNote("macroEconomicEditor", false);
                              onSendHandler(event);
                            }}
                          ></TextEditor>
                        ) : (
                          <Description
                            height={270}
                            onClick={() => {
                              Country.setNote("macroEconomicEditor", true);
                            }}
                            dangerouslySetInnerHTML={{
                              __html: Country.currentCountry.macroEconomicPerformance.richText
                                ? draftToHtml(
                                    Country.currentCountry.macroEconomicPerformance.richText
                                  )
                                : Country.currentCountry.macroEconomicPerformance.plainText
                            }}
                          ></Description>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridRow>
            <GridRow>
              <TabContainer minheight="360px">
                <Title size={15}>Notes for Country Industry and Demographics - M+H Team</Title>
                <Table celled size="small" fixed>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell style={{ overflow: "visible" }}>
                        {Country.noteEditor && Country.editMode ? (
                          <TextEditor
                            id="notesForCountry"
                            textObject={Country.get(Country.currentCountry.notesForCountry)}
                            send={event => {
                              Country.setNote("noteEditor", false);
                              onSendHandler(event);
                            }}
                          ></TextEditor>
                        ) : (
                          <Description
                            height={280}
                            onClick={() => {
                              Country.setNote("noteEditor", true);
                            }}
                            dangerouslySetInnerHTML={{
                              __html: Country.currentCountry.notesForCountry.richText
                                ? draftToHtml(Country.currentCountry.notesForCountry.richText)
                                : Country.currentCountry.notesForCountry.plainText
                            }}
                          ></Description>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </TabContainer>
            </GridRow>
          </GridColumn>
        </GridContainer>
      </React.Fragment>
    );
  });
};

const AddExport = styled(Table.Row)`
  display: ${props => props.display};
`;

const GradientBox = styled.div`
  width: 96%;
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    rgb(160, 0, 42) 0%,
    rgb(255, 55, 15) 23%,
    rgb(255, 141, 0) 50%,
    rgb(255, 201, 0) 77%,
    rgb(253, 248, 0) 100%
  );
  height: 7px;
  border: 1px solid #d4d4d5;
  box-sizing: content-box;
`;
const IndexBox = styled.div`
  text-align: right;
  background: white;
  height: 7px;
  float: right;
  width: ${props => props.width}%;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
`;

export default FastFactsTab;
