const africaCountryList = [
  { value: "Benin", text: "Benin" },
  { value: "Burkina Faso", text: "Burkina Faso" },
  { value: "Ghana", text: "Ghana" },
  { value: "South Africa", text: "South Africa" },
  { value: "Ivory Coast", text: "Ivory Coast" },
  { value: "Gambia", text: "Gambia" },
  { value: "Cameroon", text: "Cameroon" },
  { value: "Guinea", text: "Guinea" },
  { value: "Guinea-Bissau", text: "Guinea-Bissau" },
  { value: "Liberia", text: "Liberia" },
  { value: "Mali", text: "Mali" },
  { value: "Mauritania", text: "Mauritania" },
  { value: "Niger", text: "Niger" },
  { value: "Senegal", text: "Senegal" },
  { value: "Sierra Leone", text: "Sierra Leone" },
  { value: "Togo", text: "Togo" },
  { value: "Algeria", text: "Algeria" },
  { value: "Libya", text: "Libya" },
  { value: "Morocco", text: "Morocco" },
  { value: "Equatorial Guinea", text: "Equatorial Guinea" },
  { value: "Uganda", text: "Uganda" },
  { value: "Egypt", text: "Egypt" },
  { value: "Nigeria", text: "Nigeria" },
  { value: "Sudan", text: "Sudan" },
  { value: "South Sudan", text: "South Sudan" },
  { value: "Ethiopia", text: "Ethiopia" },
  { value: "Somalia", text: "Somalia" },
  { value: "Western Sahara", text: "Western Sahara" },
  { value: "Democratic Republic of the Congo", text: "Democratic Republic of the Congo" },
  { value: "Gabon", text: "Gabon" },
  { value: "Zambia", text: "Zambia" },
  { value: "Zimbabwe", text: "Zimbabwe" },
  { value: "Angola", text: "Angola" },
  { value: "Namibia", text: "Namibia" },
  { value: "Botswana", text: "Botswana" },
  { value: "Mozambique", text: "Mozambique" },
  { value: "Malawi", text: "Malawi" },
  { value: "Madagascar", text: "Madagascar" },
  { value: "Tanzania", text: "Tanzania" },
  { value: "Republic of the Congo", text: "Republic of the Congo" },
  { value: "Chad", text: "Chad" },
  { value: "Central African Republic", text: "Central African Republic" },
  { value: "Burundi", text: "Burundi" },
  { value: "Eritrea", text: "Eritrea" },
  { value: "Kenya", text: "Kenya" },
  { value: "Rwanda", text: "Rwanda" },
  { value: "Tunisia", text: "Tunisia" }
];

export default africaCountryList;
