import { action, flow, observable, reaction } from "mobx";

import baseRequest from "../_core/baseRequest";

export default class User {
  @observable usersLoading = null;
  @observable users = [];
  @observable userCount = 0;
  @observable page = 1;
  @observable shouldUpdateUsers = false;

  @action
  handlePagination = data => {
    this.page = data.activePage;
    this.shouldUpdateUsers = true;
  };

  getUsers = flow(function*(params) {
    this.usersLoading = true;
    try {
      const result = yield baseRequest.post("/user/getAll", params);
      const { data } = result;
      if (data) {
        const [userData, userCount] = data;
        this.usersLoading = false;
        this.users = userData.userList;
        this.userCount = userCount;
      }
    } catch (error) {
      console.error(error);
    }
  });

  addUser = flow(function*(params) {
    this.usersLoading = true;
    try {
      const result = yield baseRequest.post("/user/new", params);
      this.usersLoading = false;
      this.shouldUpdateUsers = true;
      return result;
    } catch (error) {
      console.error(error);
    }
  });

  updateUser = flow(function*(params) {
    try {
      yield baseRequest.post("/user/update", params);
      this.shouldUpdateUsers = true;
    } catch (error) {
      console.error(error);
    }
  });

  deleteUser = flow(function*(params) {
    try {
      yield baseRequest.post("/user/delete", params);
      this.shouldUpdateUsers = true;
    } catch (error) {
      console.error(error);
    }
  });

  refetchUsers = reaction(
    () => this.shouldUpdateUsers,
    () => {
      this.getUsers({ page: this.page });
      this.shouldUpdateUsers = false;
    }
  );
}
