import React from "react";
import { useHistory } from "react-router-dom";

import { useStore } from "../_core/appContext.js";

const AuthenticationHandler = props => {
  const history = useHistory();
  const { Auth } = useStore();
  if (Auth.isUserLoggedIn()) {
    history.push("/");
    return null;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export { AuthenticationHandler };
