const { detect } = require("detect-browser");
const browser = detect();

const browserDetect = {
  checkIfInternetExplorer: () => {
    return browser.name === "ie";
  }
};

export default browserDetect;
