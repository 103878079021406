const mhStringMethods = (() => {
  // eslint-disable-next-line no-extend-native
  String.prototype.mhUpperCase = function() {
    return this.toUpperCase();
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.mhLowerCase = function() {
    return this.toLowerCase();
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.mhCapitalize = function() {
    return this.charAt(0).mhUpperCase() + this.slice(1).mhUpperCase();
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.mhCapitalizeFirstLetter = function() {
    return this.charAt(0).mhUpperCase() + this.slice(1);
  };

  // eslint-disable-next-line no-extend-native
  String.prototype.mhCapitalizeAllFirstLetters = function() {
    return this.replace(/(?:^|\s|-)\S/g, function(l) {
      return l.mhCapitalize();
    });
  };

  // eslint-disable-next-line no-extend-native
  String.prototype.mhCapitalizeWords = function() {
    const a = this.split(" ");

    for (let i = 0; i < a.length; i++) {
      a[i] = a[i].mhCapitalize();
    }

    return a.join(" ");
  };
})();

export default mhStringMethods;
