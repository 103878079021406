import { useObserver } from "mobx-react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import vivus from "vivus";

import { useStore } from "../../_core/appContext";
import * as mhUtility from "../../utility";
import browserDetect from "../../utility/browserDetect";
import { VisitTooltip } from "../DefaultComponents/Tooltip";
import MapSvg from "../svg/map.svg";
import RegionMap from "./RegionMap";

const { mhconf, countryAttributes } = mhUtility;
const { svgProps } = mhconf;

function CardTooltip() {
  const { Map } = useStore();
  return useObserver(() => {
    return (
      <Fragment>
        {Map.showToolTip && (
          <VisitTooltip top={Map.toolTipY} left={Map.toolTipX}>
            {Map.text.mhCapitalizeAllFirstLetters() + "  "}
          </VisitTooltip>
        )}
      </Fragment>
    );
  });
}

function MapThing(props) {
  const isIE = useRef(browserDetect.checkIfInternetExplorer());
  const vivusTimeoutRef = useRef(null);
  const [svgReady, setSvgReady] = useState(false);
  const [renderSvg, setRenderSvg] = useState(false);
  const [translate, setTranslate] = useState({ xTranslate: 0, yTranslate: 0 });
  const { xTranslate, yTranslate } = translate;

  function startSvgAnimation() {
    new vivus(
      "svg-turkey",
      {
        file: MapSvg,
        duration: 100,
        onReady: myVivus => {
          myVivus.play();
          vivusTimeoutRef.current = setTimeout(() => {
            setRenderSvg(true);
          }, 1000);
        }
      },
      () => {
        setSvgReady(true);
      }
    );
  }

  function setTranslates(x, y) {
    const newState = {};
    if (x || x === 0) {
      newState.xTranslate = x;
    }
    if (y || y === 0) {
      newState.yTranslate = y;
    }
    setTranslate(newState);
  }

  useEffect(() => {
    startSvgAnimation();
    return () => {
      clearInterval(vivusTimeoutRef.current);
    };
  }, []);

  const {
    reportType,
    mapType,
    data,
    visitType,
    segment,
    isPercentage,
    brand,
    distributor,
    visitTotals,
    selectedCity,
    showInfo,
    onRegionClick,
    onOutletClick,
    vehicleBrandsData,
    selected,
    mapReport
  } = props;
  const classSvg = svgReady ? "svg-map fadeout no-pointer-event" : "svg-map ";
  const classSvg2 = renderSvg ? "svg-map fadein" : "svg-map svg-hidden";
  const svgStyle = !showInfo
    ? { position: "absolute", left: 250, right: 0, zIndex: 10 }
    : { display: "none" };
  const svgStyle2 = { position: "absolute", left: 250, right: 0 };

  return useObserver(() => {
    return (
      <React.Fragment>
        <CardTooltip></CardTooltip>
        <div style={svgStyle} className={classSvg}>
          <div id="svg-turkey" />
        </div>
        <div style={svgStyle2} className={classSvg2}>
          <div>
            <svg
              style={isIE.current ? (showInfo ? { height: "60%" } : {}) : {}}
              version="1.1"
              id="svg-map"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox={svgProps.viewBox}
              preserveAspectRatio="xMidYMid meet"
              xmlSpace="preserve"
              className={!isIE.current ? "svg-shadow" : ""}
            >
              <RegionMap
                mapType={mapType}
                data={data}
                brand={brand}
                segment={segment}
                mapReport={mapReport}
                selected={selected}
                visitType={visitType}
                reportType={reportType}
                visitTotals={visitTotals}
                selectedCity={selectedCity}
                isPercentage={isPercentage}
                onRegionClick={onRegionClick}
                onOutletClick={onOutletClick}
                vehicleBrandsData={vehicleBrandsData}
                distributor={distributor}
                showInfo={showInfo}
                setTranslates={(x, y) => setTranslates(x, y)}
              />
              {[...countryAttributes].map(obj => {
                return (
                  <g key={obj.id}>
                    <use style={{ pointerEvents: "none" }} xlinkHref={"#" + obj.id + "-img"} />
                  </g>
                );
              })}
              {[...countryAttributes].map(obj => {
                return (
                  <g key={obj.id} transform={"translate(" + xTranslate + " " + yTranslate + ")"}>
                    <use style={{ pointerEvents: "none" }} xlinkHref={"#" + obj.id + "-tooltip"} />
                  </g>
                );
              })}
            </svg>
          </div>
        </div>
      </React.Fragment>
    );
  });
}

export default MapThing;
